import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CircularProgress, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  currentProjectGetAnnotationThunk,
  currentProjectGetDetailsThunk,
  currentProjectGetProjectionThunk,
} from '../../store/currentProject/thunks';
import { useAppDispatch } from '../../store';
import { currentProjectSliceActions, selectProject, selectProjectApiPending } from '../../store/currentProject/slice';
import { ProjectDetails } from '../../store/projects/types';
import MatchReport from '../MatchReport/MatchReport';
import MatchDetails from '../MatchDetails/MatchDetails';

function MatchMain() {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const project = useSelector(selectProject) as ProjectDetails;
  const isProjectApiPending = useSelector(selectProjectApiPending);

  const projectStatus = useMemo(() => project?.status, [project]);

  useEffect(() => {
    if (projectId) {
      dispatch(currentProjectGetDetailsThunk(projectId));
    }
  }, [dispatch, projectId]);

  const prepareProject = useCallback(async () => {
    if (!project || !project.resultDataPath) {
      return;
    }
    // setIsPreparingTheProject(true);
    try {
      dispatch(currentProjectGetAnnotationThunk(
        project.annotationDataPath || project.resultDataPath,
      ));
    } catch (e) {
      console.error(e);
    } finally {
      // setIsPreparingTheProject(false);
    }
  }, [dispatch, project]);

  const getProjectCourtData = useCallback(async () => {
    if (!project || !project.courtDataPath) {
      return;
    }
    try {
      dispatch(currentProjectGetProjectionThunk(
        project.courtDataPath,
      ));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, project]);

  useEffect(() => {
    prepareProject();
  }, [prepareProject, project]);

  useEffect(() => {
    getProjectCourtData();
  }, [getProjectCourtData, project]);

  useEffect(
    () => () => {
      dispatch(currentProjectSliceActions.reset());
    },
    [dispatch],
  );

  return (
    <>
      {projectStatus === 'COMPLETED' && <MatchReport />}
      {projectStatus && projectStatus !== 'COMPLETED' && <MatchDetails />}
      <Dialog open={isProjectApiPending}>
        {isProjectApiPending && <DialogTitle>Fetching the project</DialogTitle>}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MatchMain;
