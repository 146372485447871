import { createContext } from 'react';
import {
  MatchReportFiltersCategories,
  MatchReportServingFiltersFormFields, MatchReportServingInPlayFormFields,
  MatchReportServingReturningFormFields,
} from './types';

export interface MatchReportContextType {
  filtersMode: MatchReportFiltersCategories;
  setFiltersMode: (x: MatchReportFiltersCategories) => void;
  filtersServing: MatchReportServingFiltersFormFields;
  setFiltersServing: (x: MatchReportServingFiltersFormFields) => void;
  filtersReturning: MatchReportServingReturningFormFields;
  setFiltersReturning: (x: MatchReportServingReturningFormFields) => void;
  filtersInPlay: MatchReportServingInPlayFormFields;
  setFiltersInPlay: (x: MatchReportServingInPlayFormFields) => void;
}

const MatchReportContext = createContext<MatchReportContextType>({
  filtersMode: MatchReportFiltersCategories.SERVING,
  setFiltersMode: (value: MatchReportFiltersCategories) => console.log(value),
  filtersServing: {},
  setFiltersServing: (value: MatchReportServingFiltersFormFields) => console.log(value),
  filtersReturning: {},
  setFiltersReturning: (value: MatchReportServingFiltersFormFields) => console.log(value),
  filtersInPlay: {},
  setFiltersInPlay: (value: MatchReportServingFiltersFormFields) => console.log(value),
});

export default MatchReportContext;
