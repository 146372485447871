import classnames from 'classnames';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { CircularProgress } from '@mui/material';
import styles from '../ProjectsLists.module.css';
import { ProjectStatus } from '../../../store/projects/types';

function ProjectsListStatusChip({
  projectStatus,
  processingProgress,
}: {
  projectStatus: ProjectStatus;
  processingProgress?: number;
}) {
  return (
    <>
      {projectStatus === 'READY_FOR_REVIEW' && (
      <div className={classnames(styles.statusChip, styles.statusChipWarning)}>
        <ErrorOutlinedIcon style={{ marginRight: 8 }} fontSize="inherit" />
        Ready for review
      </div>
      )}
      {projectStatus === 'COMPLETED' && (
      <div className={classnames(styles.statusChip, styles.statusChipSuccess)}>
        <CheckCircleOutlineIcon style={{ marginRight: 8 }} fontSize="inherit" />
        Completed
      </div>
      )}
      {projectStatus === 'FAILED' && (
      <div className={classnames(styles.statusChip, styles.statusChipFailed)}>
        <AutorenewIcon style={{ marginRight: 8 }} fontSize="inherit" />
        Failed Processing
      </div>
      )}
      {(projectStatus === 'PROCESSING' || projectStatus === 'IDLE') && (
      <div className={styles.statusChip}>
        <CircularProgress size={14} style={{ marginRight: 8 }} />
        {processingProgress ? Math.round(processingProgress * 100) : 0}
        % Processing
      </div>
      )}
    </>
  );
}

export default ProjectsListStatusChip;
