import React from 'react';

type ZoomOutProps = React.SVGProps<SVGSVGElement>;

function ZoomOutIcon(props: ZoomOutProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.88466 10.2501C7.67217 10.2501 7.49406 10.1782 7.35031 10.0344C7.20656 9.89057 7.13468 9.71237 7.13468 9.49979C7.13468 9.28719 7.20656 9.1091 7.35031 8.96552C7.49406 8.82193 7.67217 8.75014 7.88466 8.75014H11.1539C11.3664 8.75014 11.5445 8.82204 11.6883 8.96584C11.832 9.10966 11.9039 9.28786 11.9039 9.50044C11.9039 9.71304 11.832 9.89113 11.6883 10.0347C11.5445 10.1783 11.3664 10.2501 11.1539 10.2501H7.88466ZM9.51928 15.6155C7.81011 15.6155 6.3636 15.0237 5.17973 13.8401C3.99586 12.6565 3.40393 11.2104 3.40393 9.50169C3.40393 7.79296 3.99571 6.34628 5.17928 5.16167C6.36285 3.97707 7.809 3.38477 9.51773 3.38477C11.2264 3.38477 12.6731 3.9767 13.8577 5.16057C15.0423 6.34443 15.6347 7.79095 15.6347 9.50012C15.6347 10.2142 15.5148 10.8963 15.275 11.5463C15.0353 12.1963 14.7154 12.7616 14.3154 13.2424L20.0693 18.9963C20.2077 19.1347 20.2786 19.3088 20.2818 19.5184C20.285 19.728 20.2141 19.9052 20.0693 20.0501C19.9244 20.195 19.7488 20.2674 19.5424 20.2674C19.336 20.2674 19.1603 20.195 19.0155 20.0501L13.2616 14.2962C12.7616 14.7091 12.1866 15.0321 11.5366 15.2655C10.8866 15.4988 10.2141 15.6155 9.51928 15.6155ZM9.51928 14.1155C10.8077 14.1155 11.8991 13.6684 12.7933 12.7742C13.6876 11.8799 14.1347 10.7886 14.1347 9.50012C14.1347 8.21165 13.6876 7.1203 12.7933 6.22607C11.8991 5.33183 10.8077 4.88472 9.51928 4.88472C8.23081 4.88472 7.13946 5.33183 6.24523 6.22607C5.35101 7.1203 4.90391 8.21165 4.90391 9.50012C4.90391 10.7886 5.35101 11.8799 6.24523 12.7742C7.13946 13.6684 8.23081 14.1155 9.51928 14.1155Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ZoomOutIcon;
