import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import classnames from 'classnames';
import styles from './ProjectsLists.module.css';
import { MATCH_ROUTE } from '../../router/routesConstants';
import exampleImgUrl from './assets/example.png';
import { selectProjects } from '../../store/projects/slice';
import { ProjectDetails } from '../../store/projects/types';
import ProjectsListStatusChip from './components/ProjectsListStatusChip';
import ProjectMenu from './components/ProjectMenu';

function ProjectsListGallery() {
  const navigate = useNavigate();
  const projects = useSelector(selectProjects);
  // const projectsPending = useSelector(selectProjectsApiPending);

  const onItemClick = useCallback((projectId: string) => {
    navigate(`${MATCH_ROUTE}/${projectId}`, { state: { from: 'list' } });
  }, [navigate]);

  const newProjects: ProjectDetails[] = useMemo(
    () => projects.filter(
      (item) => !!(item as ProjectDetails).player1,
    ) as ProjectDetails[],
    [projects],
  );

  return (
    <div className={styles.gallery}>
      {newProjects.map((project) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          key={project.projectId}
          className={classnames(styles.galleryItem, {
            [styles.galleryItemInProcessing]: project.status === 'PROCESSING' || project.status === 'IDLE',
          })}
          tabIndex={0}
          role="button"
          onClick={() => onItemClick(project.projectId)}
        >
          {(project.status !== 'PROCESSING' && project.status !== 'IDLE') && (
          <div className={styles.galleryItemStatusChip}>
            <ProjectsListStatusChip projectStatus={project.status} />
          </div>
          )}
          {(project.status === 'PROCESSING' || project.status === 'IDLE') && <CircularProgress className={styles.galleryItemImgProgress} />}
          <img className={styles.galleryItemImg} src={exampleImgUrl} alt="" />
          <div className={styles.galleryItemSubwrap}>
            <div className={styles.galleryItemInfo}>
              <h4>
                {project.player1.firstName}
                {' '}
                {project.player1.lastName}
                {' '}
                •
                {' '}
                {project.player2.firstName}
                {' '}
                {project.player2.lastName}
              </h4>
              <p>{project.tournamentName}</p>
              {(project.status !== 'PROCESSING' && project.status !== 'IDLE') && (
              <small>
                <span>
                  Match date:
                  {' '}
                  {new Date(project.tournamentDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </span>
                {' '}
                •
                {' '}
                <span>
                  Created:
                  {' '}
                  {new Date(project.createdAt).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </span>
              </small>
              )}
              {(project.status === 'PROCESSING' || project.status === 'IDLE') && (
              <p style={{ color: 'black' }}>
                {project.processingProgress ? Math.round(project.processingProgress * 100) : 0}
                % Processing...
              </p>
              )}
            </div>
            <ProjectMenu verticalIcon projectId={project.projectId} project={project} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectsListGallery;
