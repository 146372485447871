import { useSelector } from 'react-redux';
import { selectProjectionData } from '../../../store/currentProject/slice';
import { ProjectionJson } from '../../../store/projects/types';
import AnnotationService from '../../../utils/services/AnnotationService';

export const useProjectionDataToSave = () => {
  const projectionData = useSelector(selectProjectionData);

  const formattedData: ProjectionJson = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(projectionData)) {

    if (!AnnotationService.current.getAnnotationById(value?.ball?.annotation_id)) {
      continue
    }

    formattedData[value.timestamp] = {
      ball: value.ball,
      player_bot: value.player_bot,
      player_top: value.player_top,
      filterMetadata: value.filterMetadata,
    };
  }

  return { ...formattedData };
};
