import { get } from 'aws-amplify/api';

export interface ProjectHistoryItem {
  userAvatarPath: string,
  projectId: string,
  eventName: string,
  userFullName: string,
  userId: string,
  createdAt: string,
  eventType: 0 | 1 | 2 | 3 | 4
}

export interface UserActivityItem {
  createdAt: string
  eventName: string
  eventType: 0 | 1 | 2 | 3 | 4
  playerName1: string
  playerName2: string
  projectId: string
  projectPreviewURL: string
  projectSubtitle: string
  userId: string
}

export const getProjectHistory = async (projectId: string) => {
  const operation = get({
    apiName: 'gtProjects',
    path: `/history/${projectId}`,
  });

  const response = await operation.response;

  return (await response.body.json()) as unknown as ProjectHistoryItem[];
};

export const getUserActivityHistory = async (userId: string) => {
  const operation = get({
    apiName: 'gtProjects',
    path: `/activity/${userId}`,
  });

  const response = await operation.response;

  return (await response.body.json()) as unknown as UserActivityItem[];
};
