import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import GTButton from '../../components/GTButton/GTButton';
import GTModal from '../../components/GTModal/GTModal';
import { useAppDispatch } from '../../store';
import GTInput from '../../components/form/GTInput/GTInput';
import GTSelect, { GTSelectOption } from '../../components/form/GTSelect/GTSelect';
import { User, UserRoles } from '../../store/users/types';
import { updateUser } from '../../api/user';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import { currentProjectSliceActions } from '../../store/users/slice';

type SettingsUserEditModalProps = {
  user: User
  open: boolean
  onCancel: () => void
  onSuccess: () => void
};

type UserEditInputs = {
  firstName: string,
  lastName: string,
  role: UserRoles
};

const roleOptions: GTSelectOption<string, UserRoles>[] = [
  { title: 'Admin', value: 'Admin' },
  { title: 'Editor', value: 'Analyst' },
  { title: 'Viewer', value: 'User' },
];

function SettingsUserEditModal({
  user, open, onSuccess, onCancel,
}: SettingsUserEditModalProps) {
  const {
    register,
    formState: { isDirty },
    handleSubmit,
    control,
    reset,
  } = useForm<UserEditInputs>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastNAme,
      role: user.role,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const isReadyToSubmit = isDirty;

  const onSubmit: SubmitHandler<UserEditInputs> = useCallback(async (data) => {
    setIsSubmitting(true);

    try {
      await updateUser(user.id, data);
      dispatch(currentProjectSliceActions.updateUser({ id: user.id, data }));
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Changes saved successfully',
          type: 'success',
        },
      }));
      onSuccess();
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, onSuccess, user.id]);

  useEffect(() => {
    if (open) {
      reset({
        firstName: user.firstName,
        lastName: user.lastNAme,
        role: user.role,
      });
    }
  }, [open, reset, user]);

  return (
    <GTModal
      open={open}
      title="Edit user"
      footer={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <GTButton disabled={isSubmitting} onClick={onCancel} color="secondary">Cancel</GTButton>
          <GTButton disabled={isSubmitting || !isReadyToSubmit} onClick={handleSubmit(onSubmit)} style={{ marginLeft: 8 }} color="primary">Save</GTButton>
        </div>
    )}
    >
      <>
        <div style={{
          display: 'flex', alignItems: 'center', gap: 16, width: '100%',
        }}
        >
          <GTInput
            id="firstName"
            label="First Name"
            placeholder="Enter first name"
            style={{ flex: 1 }}
            {...register('firstName')}
          />
          <GTInput
            id="lastName"
            label="Last Name"
            placeholder="Enter last name"
            style={{ flex: 1 }}
            {...register('lastName')}
          />
        </div>
        <Controller
          control={control}
          render={({ field }) => (
            <GTSelect
              id="role"
              label="Role"
              options={roleOptions}
              name={field.name}
              value={field.value}
              style={{ flex: 1, marginTop: 24 }}
              onChange={field.onChange}
            />
          )}
          name="role"
        />
      </>
    </GTModal>
  );
}

export default SettingsUserEditModal;
