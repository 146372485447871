import {
  ChangeEvent, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { debounce } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { projectsGetThunk } from '../../store/projects/thunks';
import { useAppDispatch } from '../../store';
import styles from './ProjectsLists.module.css';
import { OrderBy, ProjectDetails, SortBy } from '../../store/projects/types';
import MatchCreator from '../MatchCreator/MatchCreator';
import GTInput from '../../components/form/GTInput/GTInput';
import SearchIcon from '../../components/MaterialSymbolsIcons/SearchIcon';
import GTSelect from '../../components/GTSelect/GTSelect';
import ProjectsListTable from './ProjectsListTable';
import ProjectsListGallery from './ProjectsListGallery';
import GalleryIcon from '../../components/customIcons/GalleryIcon';
import ListIcon from '../../components/customIcons/ListIcon';
import GTButton from '../../components/GTButton/GTButton';
import ProjectsListContext, { ProjectsListContextType } from './ProjectsListContext';
import ProjectHistory from './ProjectHistory/ProjectHistory';

const sections = [
  {
    title: 'Sort by',
    items: [
      { title: 'Last edited', value: SortBy.LAST_EDITED },
      { title: 'Date created', value: SortBy.DATE_CREATED },
      { title: 'Match date', value: SortBy.MATCH_DATE },
      { title: 'Name', value: SortBy.NAME },
      { title: 'Status', value: SortBy.STATUS },
    ],
  },
  {
    title: 'Order by',
    items: [
      { title: 'Ascendant', value: OrderBy.ASC },
      { title: 'Descendant', value: OrderBy.DESC },
    ],
  },
];

function getTitleBySort(sort: SortBy) {
  const item = sections[0].items.find((searchItem) => searchItem.value === sort);
  return item?.title;
}

const searchDebounceCall = debounce((callback) => {
  callback();
}, 300);

type ProjectsListViewMode = 'GALLERY' | 'TABLE';

const ProjectsListViewModeSessionStorageKey = 'projectListViewMode';

function ProjectFilterBar({
  viewMode,
  setViewMode,
}: { viewMode: ProjectsListViewMode, setViewMode: (mode: ProjectsListViewMode) => void }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = useMemo(() => searchParams.get('sortBy') as SortBy || SortBy.DATE_CREATED, [searchParams]);
  const order = useMemo(() => searchParams.get('orderBy') as OrderBy || OrderBy.DESC, [searchParams]);
  const [textSearch, setTextSearch] = useState<string>(searchParams.get('textSearch') || '');
  const context = useContext(ProjectsListContext);

  const filter = [sort, order];

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setTextSearch(value);
    searchDebounceCall(() => {
      setSearchParams((prev) => {
        prev.set('textSearch', value);
        return prev;
      }, { preventScrollReset: true, replace: true });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (event: any) => {
    const {
      target: { value },
    } = event;

    if (value.length > 2) {
      const newValue = value[2];
      if (newValue.startsWith('SORT')) {
        setSearchParams((prev) => {
          prev.set('sortBy', newValue);
          return prev;
        }, { preventScrollReset: true, replace: true });
      } else {
        setSearchParams((prev) => {
          prev.set('orderBy', newValue);
          return prev;
        }, { preventScrollReset: true, replace: true });
      }
    }
  };

  return (
    <div className={styles.barContainer}>
      <div>
        <GTInput
          className={styles.searchInput}
          placeholder="By tournament name, players"
          onChange={handleSearchChange}
          value={textSearch}
          startAdornment={<SearchIcon className={styles.searchIcon} />}
        />
      </div>
      <div className={styles.barRightContainer}>
        <GTSelect
          sections={sections}
          value={filter}
          onChange={handleFilterChange}
          renderValue={() => <div>{getTitleBySort(sort)}</div>}
        />
        <GTButton style={{ marginLeft: 'auto' }} color="primary" onClick={() => context.onMatchCreatorOpenChange?.(true)}>Add new match</GTButton>
        <div className={styles.btnGroupWrap}>
          <button aria-label="gallery view" type="button" onClick={() => setViewMode('GALLERY')} aria-pressed={viewMode === 'GALLERY'}>
            <GalleryIcon />
          </button>
          <button aria-label="list view" type="button" onClick={() => setViewMode('TABLE')} aria-pressed={viewMode === 'TABLE'}>
            <ListIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

function ProjectsList() {
  const dispatch = useAppDispatch();
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [targetProject, setTargetProject] = useState<ProjectDetails | undefined>(undefined);
  const [viewMode, setViewMode] = useState<ProjectsListViewMode>(
    sessionStorage.getItem(ProjectsListViewModeSessionStorageKey) as ProjectsListViewMode
    || 'GALLERY',
  );
  const [searchParams] = useSearchParams();

  const getProjects = useCallback(async () => {
    const textSearch = searchParams.get('textSearch') || '';
    const sortBy = searchParams.get('sortBy') as SortBy || SortBy.DATE_CREATED;
    const orderBy = searchParams.get('orderBy') as OrderBy || OrderBy.DESC;
    await dispatch(projectsGetThunk({
      textSearch,
      sortBy,
      orderBy,
    }));
  }, [dispatch, searchParams]);

  useEffect(() => {
    sessionStorage.setItem(ProjectsListViewModeSessionStorageKey, viewMode);
  }, [viewMode]);

  useEffect(() => {
    getProjects();
  }, [dispatch, getProjects, searchParams]);

  const onMatchCreatorOpenChange = useCallback((value: boolean, project?: ProjectDetails) => {
    setTargetProject(project || undefined);
    setIsCreatorOpen(value);
  }, []);

  const onProjectHistoryOpenChange = useCallback((value: boolean, project?: ProjectDetails) => {
    setTargetProject(project || undefined);
    setIsHistoryOpen(value);
  }, []);

  const contextValue = useMemo<ProjectsListContextType>(() => ({
    onMatchCreatorOpenChange,
    onProjectHistoryOpenChange,
    getProjects,
  }), [getProjects, onMatchCreatorOpenChange, onProjectHistoryOpenChange]);

  const onEditDoneCallback = useCallback(async () => {
    await getProjects();
  }, [getProjects]);

  const onCreationDoneCallback = useCallback(async () => {
    await getProjects();
  }, [getProjects]);

  return (
    <ProjectsListContext.Provider value={contextValue}>
      <ProjectFilterBar viewMode={viewMode} setViewMode={setViewMode} />
      <MatchCreator
        open={isCreatorOpen}
        project={targetProject}
        onOpenChange={setIsCreatorOpen}
        onEditDoneCallback={onEditDoneCallback}
        onCreationDoneCallback={onCreationDoneCallback}
      />
      {targetProject && <ProjectHistory open={isHistoryOpen} project={targetProject} onOpenChange={setIsHistoryOpen} />}
      <div className={styles.wrap}>
        {viewMode === 'GALLERY' && <ProjectsListGallery />}
        {viewMode === 'TABLE' && <ProjectsListTable />}
      </div>
    </ProjectsListContext.Provider>
  );
}

export default ProjectsList;
