import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import styles from './MatchCourtProjection.module.css';
import netSrc from '../MatchDetails/assets/net.png';
import MatchCourtProjectionZoomControl from './MatchCourtProjectionZoomControl';
import useResizeObserver from '../../utils/hooks/useResizeObserver';
import MatchCourtProjectionPlayer from './MatchCourtProjectionPlayer';
import MatchCourtProjectionBallEvent from './MatchCourtProjectionBallEvent';
import { selectCurrentTimestamp, selectProjectionData } from '../../store/currentProject/slice';
import AnnotationService from '../../utils/services/AnnotationService';
import GTIconButton from '../../components/GTIconButton/GTIconButton';

const BASE_PADDING = 16;
const BASE_PROJECTION_HEIGHT = 850;

export enum ProjectionItemType {
  BALL,
  P_TOP,
  P_BOT,
}

type MatchCourtProjectionProps = {
  onItemSelected?: (type: ProjectionItemType, id?: string) => void,
};

function MatchCourtProjection({ onItemSelected }: MatchCourtProjectionProps) {
  const [zoom, setZoom] = useState(1);
  const [isShowAllBallEvents, setIsShowAllBallEvents] = useState(false);
  const projectionData = useSelector(selectProjectionData);
  const currentTimestamp = useSelector(selectCurrentTimestamp);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [courtWidth, setCourtWidth] = useState(0);

  const projectionDataToRender = useMemo(
    () => projectionData.find((
      item,
    ) => {
      const step = AnnotationService.current.currentVideoFrameStep;
      return Math.abs(item.timestamp - currentTimestamp) < step;
    }),

    [projectionData, currentTimestamp],
  );

  const allBallEvents = useMemo(() => projectionData.map((item) => item.ball), [projectionData]);

  const zoomedWidth = useMemo(() => Math.floor(containerWidth * zoom), [containerWidth, zoom]);
  const zoomedHeight = useMemo(() => Math.floor(Math.max(containerHeight, BASE_PROJECTION_HEIGHT) * zoom), [containerHeight, zoom]);

  const updateContainerWidth = useCallback((target: HTMLDivElement) => {
    setContainerWidth(target.offsetWidth);
    setContainerHeight(target.offsetHeight);
  }, []);

  const updateCourtWidth = useCallback((target: HTMLDivElement) => {
    setCourtWidth(target.offsetWidth);
  }, []);

  const containerRef = useResizeObserver<HTMLDivElement>(updateContainerWidth);
  const courtRef = useResizeObserver<HTMLDivElement>(updateCourtWidth);

  const onOverSubContainerHeightChange = useCallback(() => {
    if (!containerRef.current) return;
    if (containerRef.current.scrollHeight > containerRef.current.offsetHeight) {
      const scrollHeightDiff = containerRef.current.scrollHeight - containerRef.current.offsetHeight;
      const scrollWidthDiff = containerRef.current.scrollWidth - containerRef.current.offsetWidth;

      containerRef.current.scrollTo({
        top: scrollHeightDiff / 2,
        left: scrollWidthDiff / 2,
      });
    }
  }, [containerRef]);
  const overSubContainerRef = useResizeObserver<HTMLDivElement>(onOverSubContainerHeightChange);

  const zoomOffset = useMemo(() => {
    const addedSpace = (zoomedWidth - containerWidth) / 1.15;
    const distanceToCourt = (zoomedWidth - courtWidth) / 2;
    const minimaOffset = distanceToCourt - BASE_PADDING * 2;
    return Math.min(addedSpace + BASE_PADDING, minimaOffset);
  }, [zoomedWidth, containerWidth, courtWidth]);

  const onBallClick = () => {
    const id = projectionDataToRender?.ball?.annotation_id;
    if (onItemSelected) {
      onItemSelected(ProjectionItemType.BALL, id);
    }
  };

  const onPlayerClick = (top: boolean) => {
    const id = top ? projectionDataToRender?.player_top?.annotation_id : projectionDataToRender?.player_bot?.annotation_id;
    if (onItemSelected) {
      onItemSelected(top ? ProjectionItemType.P_TOP : ProjectionItemType.P_BOT, id);
    }
  };

  return (
    <div className={styles.projectionOuterWrap}>
      <div className={styles.projectionControls}>
        <MatchCourtProjectionZoomControl zoom={zoom} setZoom={setZoom} />
        <div className={classnames(
          styles.overlayControl,
          { [styles.open]: isShowAllBallEvents },
        )}
        >
          <GTIconButton onClick={() => setIsShowAllBallEvents(!isShowAllBallEvents)}>
            <FiberSmartRecordIcon style={{ fontSize: 24 }} />
          </GTIconButton>
        </div>
      </div>
      <div ref={containerRef} className={classnames(styles.container, 'hideScrollbar')}>
        <div ref={overSubContainerRef} className={styles.overSubContainer} style={{ width: zoomedWidth, height: zoomedHeight }}>
          <div className={styles.subContainer} style={{ transform: `scale(${zoom})` }}>
            <div className={styles.zones}>
              <div className={styles.zone5} />
              <div className={styles.zone4} />
              <div className={styles.zone3} />
              <div className={styles.zone2} />
              <div className={styles.zone1} />
              <div className={classnames(styles.zone1, styles.numberedZone)}>
                <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                  <span>1</span>
                </div>
              </div>
              <div className={classnames(styles.zone2, styles.numberedZone)}>
                <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                  <span>2</span>
                </div>
              </div>
              <div className={classnames(styles.zone3, styles.numberedZone)}>
                <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                  <span>3</span>
                </div>
              </div>
              <div className={classnames(styles.zone4, styles.numberedZone)}>
                <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                  <span>4</span>
                </div>
              </div>
              <div className={classnames(styles.zone5, styles.numberedZone)}>
                <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div ref={courtRef} className={styles.court}>
              <div className={styles.courtInner}>
                <div className={styles.courtSideA}>
                  <div className={styles.courtAlleyDeuce} />
                  <div className={styles.courtBackcourt} />
                  <div className={styles.courtForecourtDeuce} />
                  <div className={styles.courtForecourtAd} />
                  <div className={styles.courtAlleyAd} />
                </div>
                <div className={styles.courtSideB}>
                  <div className={styles.courtAlleyDeuce} />
                  <div className={styles.courtForecourtDeuce} />
                  <div className={styles.courtForecourtAd} />
                  <div className={styles.courtBackcourt} />
                  <div className={styles.courtAlleyAd} />
                </div>
                {projectionDataToRender && (
                  <>
                    {!isShowAllBallEvents && projectionDataToRender?.ball?.position && (
                    <MatchCourtProjectionBallEvent
                      onClick={onBallClick}
                      left={projectionDataToRender.ball.position.x}
                      top={projectionDataToRender.ball.position.y}
                      annotationId={projectionDataToRender.ball.annotation_id}
                    />
                    )}
                    {projectionDataToRender?.player_top?.position && (
                    <MatchCourtProjectionPlayer
                      isTopPlayer
                      onClick={() => { onPlayerClick(true); }}
                      left={projectionDataToRender.player_top.position?.x}
                      top={projectionDataToRender.player_top.position?.y}
                    />
                    )}
                    {projectionDataToRender?.player_bot?.position && (
                    <MatchCourtProjectionPlayer
                      onClick={() => { onPlayerClick(false); }}
                      left={projectionDataToRender.player_bot.position?.x}
                      top={projectionDataToRender.player_bot.position?.y}
                    />
                    )}
                  </>
                )}
                {isShowAllBallEvents && allBallEvents.map((ball, index) => (
                  ball && (
                  <MatchCourtProjectionBallEvent
                    key={ball.annotation_id || `ball_${index}`}
                    left={ball.position?.x}
                    top={ball.position?.y}
                    annotationId={ball.annotation_id}
                    isSecondary={projectionDataToRender && (!projectionDataToRender.ball.annotation_id
                      || ball.annotation_id !== projectionDataToRender.ball.annotation_id)}
                  />
                  )
                ))}
              </div>
            </div>
            <img src={netSrc} alt="" className={styles.net} />
          </div>
        </div>
      </div>
    </div>

  );
}

export default MatchCourtProjection;
