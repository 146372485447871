import {
  del, get, post, put,
} from 'aws-amplify/api';
import { TransferProgressEvent, uploadData } from 'aws-amplify/storage';
import {
  CourtProjectionPayloadRequest,
  MatchCoverType,
  MatchElevationType,
  MatchMeasurementSystemType, MatchPlayerAgeGroup, MatchPlayerDominantHand, MatchPlayerGender, MatchPlayerSkillLevel,
  MatchRoundOfPlay,
  MatchScoringSystem,
  MatchTemperatureType,
  MatchTimeOfDayType,
  MatchType,
  MatchVenueType,
  MatchWindType,
  OrderBy,
  ProjectDetails,
  ProjectDetailsOld,
  ProjectExtractedFrameData,
  SortBy,
} from '../store/projects/types';

export interface ProjectsFilter {
  sortBy?: SortBy;
  orderBy?: OrderBy;
  textSearch?: string;
}

export const getProjects = async (filter?: ProjectsFilter) => {
  const operation = get({
    apiName: 'gtProjects',
    path: '/projects',
    options: {
      queryParams: {
        ...filter,
      },
    },
  });

  const response = await operation.response;
  return (await response.body.json()) as ProjectDetailsOld[];
};

export const getProject = async (projectId: string) => {
  const operation = get({
    apiName: 'gtProjects',
    path: `/projects/${projectId}`,
  });

  const response = await operation.response;

  return (await response.body.json()) as ProjectDetails;
};

export const getProjectAnnotations = async (resultDataPath: string) => {
  const response = await fetch(
    `https://d3kn021kxuyfai.cloudfront.net${resultDataPath}`,
    {
      method: 'GET',
    },
  );

  return response.json() as Record<never, never>;
};

export const getProjectProjection = async (courtDataPath: string) => {
  const response = await fetch(
    `https://d3kn021kxuyfai.cloudfront.net${courtDataPath}`,
    {
      method: 'GET',
    },
  );

  return response.json() as Record<never, never>;
};

export const requestCourtProjection = async (payload: CourtProjectionPayloadRequest) => {
  const operation = post({
    apiName: 'gtProjects',
    path: '/projects/court-projection',
    options: {
      body: {
        ...payload,
      },
    },
  });

  const response = await operation.response;

  const bodyObj = await response.body.json();
  return JSON.parse((bodyObj as any).vimData);
};

export const createProject = async (projectName: string) => {
  const operation = post({
    apiName: 'gtProjects',
    path: '/projects',
    options: {
      body: {
        projectName,
      },
    },
  });

  const response = await operation.response;

  const bodyObj = await response.body.json();
  return bodyObj as { data: { projectId: string } };
};

export type EditProjectPayload = {
  tournamentName: string,
  tournamentDate: string,
  location?: string,
  measurementSystem?: MatchMeasurementSystemType,
  roundOfPlay?: MatchRoundOfPlay,
  matchType?: MatchType,
  scoringSystem?: MatchScoringSystem,
  timeOfDay?: MatchTimeOfDayType,
  elevation?: MatchElevationType,
  venue?: MatchVenueType,
  coverType?: MatchCoverType,
  wind?: MatchWindType,
  temperature?: MatchTemperatureType,
  player1: {
    firstName: string,
    lastName: string,
    gender?: MatchPlayerGender,
    dominantHand?: MatchPlayerDominantHand,
    ageGroup?: MatchPlayerAgeGroup,
    skillLevel?: MatchPlayerSkillLevel,
  },
  player2: {
    firstName: string,
    lastName: string,
    gender?: MatchPlayerGender,
    dominantHand?: MatchPlayerDominantHand,
    ageGroup?: MatchPlayerAgeGroup,
    skillLevel?: MatchPlayerSkillLevel,
  },
};

export type CreateProjectV2Payload = EditProjectPayload & {
  sourceVideoPath: string,
};

export const createProjectV2 = async (body: CreateProjectV2Payload) => {
  const operation = post({
    apiName: 'gtProjects',
    path: '/projects/match',
    options: {
      body,
    },
  });

  const response = await operation.response;

  const bodyObj = await response.body.json();
  return bodyObj as { data: { projectId: string, sourceVideoPath: string } };
};

export const editProject = async (projectId: string, body: EditProjectPayload) => {
  const operation = put({
    apiName: 'gtProjects',
    path: `/projects/${projectId}/match`,
    options: {
      body,
    },
  });

  const response = await operation.response;

  const bodyObj = await response.body.json();
  return bodyObj as { data: { projectId: string, sourceVideoPath: string } };
};

export const deleteProject = async (projectId: string) => {
  const operation = del({
    apiName: 'gtProjects',
    path: `/projects/${projectId}`,
  });

  return operation.response;
};

export const startProcessingTheProject = async (
  projectId: string,
  sourceVideoPath: string,
  executeProcessing: boolean,
) => {
  const operation = post({
    apiName: 'gtProjects',
    path: `/projects/${projectId}/submit`,
    options: {
      body: {
        sourceVideoPath,
        executeProcessing,
      },
    },
  });

  return operation.response;
};

export const updateProjectAnnotationsAndProjection = async (
  projectId: string,
  annotationsPath: string,
  projectionPath: string,
) => {
  const getOperation = put({
    apiName: 'gtProjects',
    path: `/projects/${projectId}/annotations`,
    options: {
      body: {
        annotationsPath,
        projectionPath,
      },
    },
  });

  const response = await getOperation.response;

  return (await response.body.json()) as ProjectDetailsOld;
};

export const applyProjectAnnotations = async (projectId: string) => {
  const operation = post({
    apiName: 'gtProjects',
    path: `/projects/${projectId}/annotations`,
  });

  return operation.response;
};

export const uploadVideoForProjectV2 = (
  file: File,
  filePath: string,
  onProgress?: (event: TransferProgressEvent) => void,
) => uploadData({
  key: filePath,
  data: file,
  options: {
    accessLevel: 'guest',
    contentType: 'video/mp4',
    onProgress,
  },
});

export const uploadFile = (
  file: File,
  filePath: string,
  onProgress?: (event: TransferProgressEvent) => void,
) => uploadData({
  key: filePath,
  data: file,
  options: {
    accessLevel: 'guest',
    contentType: file.type,
    onProgress,
  },
});

export const uploadJsonResultsForProject = async (
  projectId: string,
  file: File,
) => uploadData({
  key: `${projectId}/annotations/${file.name}`,
  data: file,
  options: {
    accessLevel: 'guest',
    contentType: 'text/json',
  },
}).result;

export const extractFramesData = async (
  projectId: string,
  sourceVideoPath: string,
  timestamp: number,
) => {
  const operation = post({
    apiName: 'gtProjects',
    path: `/projects/${projectId}/frames/extract`,
    options: {
      body: {
        sourceVideoPath,
        timestamp,
      },
    },
  });

  const response = await operation.response;

  return (await response.body.json()) as ProjectExtractedFrameData;
};
