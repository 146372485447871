import { Button, Modal, ModalOwnProps } from '@mui/material';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './GTModal.module.css';

type GTModalProps = ModalOwnProps & {
  title?: string
  footer?: ReactNode
  onClose?: () => void
};

function GTModal({
  className, children, footer, title, onClose, ...props
}: GTModalProps) {
  return (
    <Modal className={styles.modal} {...props}>
      <div className={styles.modalBody}>
        {!!(title || onClose) && (
        <div className={styles.modalHead}>
          {title && <h4 className={styles.modalTitle}>{title}</h4>}
          {onClose && (
          <Button className={styles.modalCloseBtn} onClick={onClose}>
            <CloseIcon />
          </Button>
          )}
        </div>
        )}
        <div className={styles.modalContent}>
          {children}
        </div>
        {footer && (
        <div className={styles.modalFooter}>
          {footer}
        </div>
        )}
      </div>
    </Modal>
  );
}

export default GTModal;
