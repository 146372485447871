export type ProjectStatus = 'IDLE' | 'PROCESSING' | 'READY_FOR_REVIEW' | 'FAILED' | 'COMPLETED';
export type ProjectAnnotationStatus = 'EDITING' | 'FINALIZED';
export type ProcessingStatuses = 'starting' | 'waiting_video';

export enum SortBy {
  LAST_EDITED = 'SORT_LAST_EDITED',
  DATE_CREATED = 'SORT_DATE_CREATED',
  MATCH_DATE = 'SORT_MATCH_DATE',
  NAME = 'SORT_NAME',
  STATUS = 'SORT_STATUS',
}

export enum OrderBy {
  ASC = 'ORDER_ASC',
  DESC = 'ORDER_DESC',
}

export interface FrameSetupState {
  hasBall: boolean,
  hasCourt: boolean,
  hasPlayers: boolean,
  hasScoreboard: boolean
}

export type CourtProjectionPayloadRequest = {
  court: any[],
  ball: any,
  player_top: any,
  player_bot: any
};

export type ProjectDetailsOld = {
  projectId: string,
  createdAt: string,
  updatedAt?: string,
  tournamentDate?: string,
  projectName: string,
  tournamentName?: string
  sourceVideoPath: string,
  resultDataPath?: string,
  courtDataPath?: string,
  annotationDataPath?: string,
  processingProcess?: ProcessingStatuses,
  processingProgress?: 0,
  status: ProjectStatus,
  annotationStatus?: ProjectAnnotationStatus,
  location: string
};

export enum MatchMeasurementSystemType {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum MatchType {
  TOURNAMENT = 'TOURNAMENT',
  QUALIFIER = 'QUALIFIER',
  LEAGUE = 'LEAGUE',
  EXHIBITION = 'EXHIBITION',
  PRACTICE = 'PRACTICE',
  OTHER = 'OTHER',
}

export enum MatchRoundOfPlay {
  ROUND_1 = 'ROUND_1',
  ROUND_2 = 'ROUND_2',
  ROUND_3 = 'ROUND_3',
  ROUND_4 = 'ROUND_4',
  ROUND_5 = 'ROUND_5',
  QUARTERFINAL = 'QUARTERFINAL',
  SEMIFINAL = 'SEMIFINAL',
  FINAL = 'FINAL',
}

export enum MatchPlayerGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum MatchPlayerDominantHand {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum MatchPlayerAgeGroup {
  GROUP_3_7 = '3-7',
  GROUP_8_13 = '8-13',
  GROUP_14_19 = '14-19',
  GROUP_20_35 = '20-35',
  GROUP_36_55 = '36-55',
  GROUP_55Plus = '55+',
}

export enum MatchPlayerSkillLevel {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  ELITE = 'ELITE',
  PRO = 'PRO',
}

export type MatchScoringSystem =
  '8-Game Pro Set: no tiebreak' |
  '8-Game Pro Set: 7-point tiebreak' |
  '8-Game Pro Set: 10-point tiebreak' |
  '3 Sets: tiebreak for all' |
  '3 Sets: tiebreak for all but last' |
  '3 Sets: match tiebreaker in lieu of 3rd set' |
  '3 Sets: no Ad scoring and tiebreak for all' |
  '5 Sets: tiebreak for all' |
  '5 Sets: tiebreak for all but last' |
  '5 Sets: super tie breaker at 6-6 in 5th set' |
  '5 Sets: 5th set Wimbledon scoring' |
  '3 Sets: 3rd set Wimbledon super tiebreaker';
export enum MatchCoverType {
  HARD = 'HARD',
  RED_CLAY = 'RED_CLAY',
  HAR_TRU = 'HAR_TRU',
  GRASS = 'GRASS',
  SYTHETIC = 'SYTHETIC',
  CARPET = 'CARPET',
  OTHER = 'OTHER',
}
export enum MatchTimeOfDayType {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}

export enum MatchElevationType {
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export enum MatchVenueType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
  MIXED = 'MIXED',
}

export enum MatchWindType {
  NONE = 'NONE',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
  EXTREME = 'EXTREME',
}

export enum MatchTemperatureType {
  COLD = 'COLD',
  COOL = 'COOL',
  WARM = 'WARM',
  HOT = 'HOT',
  EXTREME = 'EXTREME',
}

export type Position = {
  x: number,
  y: number
};

export interface EventItemRecord {
  position: Position,
  annotation_id?: string
  eventType?: string
  timestamp: number
}

export type ProjectionItem = {
  timestamp: number,
  ball: {
    position: Position,
    annotation_id?: string
  },
  player_top: {
    position: Position,
    annotation_id?: string
  },
  player_bot: {
    position: Position,
    annotation_id?: string
  }
  filterMetadata?: any
};

export type ProjectionJson = Record<string, Omit<ProjectionItem, 'timestamp'>>;

export type ProjectDetails = {
  projectId: string,
  createdAt: string,
  updatedAt?: string,
  resultDataPath?: string,
  courtDataPath?: string,
  annotationDataPath?: string,
  processingProcess?: ProcessingStatuses,
  processingProgress?: 0,
  status: ProjectStatus,
  annotationStatus?: ProjectAnnotationStatus,

  reportP1Path?: string,
  reportP2Path?: string,

  'tournamentName': string,
  'tournamentDate': string,
  'location': string,
  'measurementSystem': MatchMeasurementSystemType,
  'roundOfPlay': MatchRoundOfPlay,
  'matchType': MatchType,
  'sourceVideoPath': string,
  'player1': {
    'firstName': string,
    'lastName': string,
    'gender': MatchPlayerGender,
    'dominantHand': MatchPlayerDominantHand,
    'ageGroup': MatchPlayerAgeGroup,
    'skillLevel': MatchPlayerSkillLevel
  },
  'player2': {
    'firstName': string,
    'lastName': string,
    'gender': MatchPlayerGender,
    'dominantHand': MatchPlayerDominantHand,
    'ageGroup': MatchPlayerAgeGroup,
    'skillLevel': MatchPlayerSkillLevel
  },
  'scoringSystem': MatchScoringSystem,
  timeOfDay?: MatchTimeOfDayType,
  elevation?: MatchElevationType,
  venue?: MatchVenueType,
  coverType?: MatchCoverType,
  wind?: MatchWindType,
  temperature?: MatchTemperatureType,
};

export type ProjectExtractedFrameData = {
  vimData: string
};
