import { useForm } from 'react-hook-form';
import {
  forwardRef, useContext, useEffect, useImperativeHandle,
} from 'react';
import GTButton from '../../../components/GTButton/GTButton';
import unreturnableBallSrc from '../../MatchDetails/assets/unreturnable_ball_event.svg';
import forcingBallSrc from '../../MatchDetails/assets/forcing_ball_event.svg';
import forcedServeBallSrc from '../../MatchDetails/assets/forced_serve_ball_event.svg';
import unforcedBallSrc from '../../MatchDetails/assets/unforced_ball_event.svg';
import otherBallSrc from '../../MatchDetails/assets/other_ball_event.svg';
import GTRadioSelect, { GTRadioSelectOption } from '../../../components/form/GTRadioSelect/GTRadioSelect';
import { MatchReportFiltersShotKind, MatchReportServingReturningFormFields } from './types';
import {
  MatchDetailsBallFiltersModeValues,
  MatchDetailsBallFiltersShotTypeValues, MatchDetailsBallFiltersSideValues, MatchDetailsBallFiltersSituationValues,
} from '../../MatchDetails/Filters/types/BallFiltersTypes';
import MatchReportContext from './context';

const serveOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: '1st serve', value: '1st serve' },
  { title: '2nd serve', value: '2nd serve' },
];

const courtOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Deuce Court', value: MatchDetailsBallFiltersSituationValues.deuceCourt },
  { title: 'Ad Court', value: MatchDetailsBallFiltersSituationValues.adCourt },
];

const handOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Forehand', value: MatchDetailsBallFiltersSideValues.forehand },
  { title: 'Backhand', value: MatchDetailsBallFiltersSideValues.backhand },
];

const situationOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Baseline', value: MatchDetailsBallFiltersSituationValues.baseline },
  { title: 'Approaching', value: MatchDetailsBallFiltersSituationValues.approaching },
  { title: 'Passing', value: MatchDetailsBallFiltersSituationValues.passing },
];

const shotTypeOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  {
    title: 'Drive',
    value: MatchDetailsBallFiltersShotTypeValues.rs_drive,
  },
  {
    title: 'Arc',
    value: MatchDetailsBallFiltersShotTypeValues.rs_arc,
  },
  {
    title: 'Loop',
    value: MatchDetailsBallFiltersShotTypeValues.rs_loop,
  },
  {
    title: 'Lob',
    value: MatchDetailsBallFiltersShotTypeValues.rs_lob,
  },
  {
    title: 'Angle',
    value: MatchDetailsBallFiltersShotTypeValues.rs_angle,
  },
  {
    title: 'Up&Down',
    value: MatchDetailsBallFiltersShotTypeValues.rs_updown,
  },
  {
    title: 'DipDrive',
    value: MatchDetailsBallFiltersShotTypeValues.rs_dipdrive,
  },
  {
    title: 'Bender',
    value: MatchDetailsBallFiltersShotTypeValues.rs_bender,
  },
  {
    title: 'Dropshot',
    value: MatchDetailsBallFiltersShotTypeValues.rs_dropshot,
  },
  {
    title: 'Dipper',
    value: MatchDetailsBallFiltersShotTypeValues.rs_dipper,
  },
];

const modeOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  {
    title: 'Defend',
    value: MatchDetailsBallFiltersModeValues.defend,
  },
  {
    title: 'Counterattack',
    value: MatchDetailsBallFiltersModeValues.counterattack,
  },
  {
    title: 'Rally',
    value: MatchDetailsBallFiltersModeValues.rally,
  },
  {
    title: 'Challenge',
    value: MatchDetailsBallFiltersModeValues.challenge,
  },
  {
    title: 'Attack',
    value: MatchDetailsBallFiltersModeValues.attack,
  },
];

const zoneOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Zone 1', value: 'zone_1' },
  { title: 'Zone 2', value: 'zone_2' },
  { title: 'Zone 3', value: 'zone_3' },
  { title: 'Zone 4', value: 'zone_4' },
  { title: 'Zone 5', value: 'zone_5' },
];

const zoneSideOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'C', value: 'zone_C_left' },
  { title: 'B', value: 'zone_B_left' },
  { title: 'A', value: 'zone_A_left' },
  { title: 'A', value: 'zone_A_right' },
  { title: 'B', value: 'zone_B_right' },
  { title: 'C', value: 'zone_C_right' },
];

const gamePointOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Player\'s Break Point', value: 'players_break_point' },
];

const winnerOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Opponnent\'s Winner', value: 'opponent_winner' },
  { title: 'Opponnent\'s Unforced Error', value: 'opponent_unforced_error' },
];

const lineOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Down the Line', value: 'down_the_line' },
  { title: 'Cross Court', value: 'cross_court' },
];

const scoreOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Score 30:30', value: 'score_30_30' },
  { title: 'Score 40:40', value: 'score_40_40' },
];

const pointOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Point Won', value: 'point_won' },
  { title: 'Point Lost', value: 'point_lost' },
];

const MatchReportFiltersReturning = forwardRef((_, ref) => {
  const context = useContext(MatchReportContext);
  const methods = useForm<MatchReportServingReturningFormFields>({
    defaultValues: context.filtersReturning,
  });

  useImperativeHandle(ref, () => ({
    reset: () => methods.reset(),
  }));

  useEffect(() => {
    methods.watch(() => context.setFiltersReturning(methods.getValues()));
  }, [context, methods]);

  const shotKindValue = methods.watch('shotKind');

  return (
    <>
      <div style={{ display: 'flex', gap: 8 }}>
        <GTButton
          color={`${!shotKindValue ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', '')}
        >
          All
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.UNRETURNABLE ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.UNRETURNABLE)}
        >
          <img src={unreturnableBallSrc} alt="" style={{ marginRight: 8 }} />
          Winner/Unreturnable
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.FORCING ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.FORCING)}
        >
          <img src={forcingBallSrc} alt="" style={{ marginRight: 8 }} />
          Forcing
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.FORCED_ERROR ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.FORCED_ERROR)}
        >
          <img src={forcedServeBallSrc} alt="" style={{ marginRight: 8 }} />
          Forced Error
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.UNFORCED_ERROR ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.UNFORCED_ERROR)}
        >
          <img src={unforcedBallSrc} alt="" style={{ marginRight: 8 }} />
          Unforced Error
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.OTHER ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.OTHER)}
        >
          <img src={otherBallSrc} alt="" style={{ marginRight: 8 }} />
          Other
        </GTButton>
      </div>
      <GTRadioSelect condense registerProps={methods.register('serveType')} options={serveOptions} />
      <GTRadioSelect condense registerProps={methods.register('courtType')} options={courtOptions} />
      <GTRadioSelect condense registerProps={methods.register('hand')} options={handOptions} />
      <GTRadioSelect condense registerProps={methods.register('situation')} options={situationOptions} />
      <GTRadioSelect condense registerProps={methods.register('shotType')} options={shotTypeOptions} />
      <GTRadioSelect condense registerProps={methods.register('mode')} options={modeOptions} />
      <GTRadioSelect condense registerProps={methods.register('zone')} options={zoneOptions} />
      <GTRadioSelect condense registerProps={methods.register('zoneSide')} options={zoneSideOptions} />
      <GTRadioSelect condense registerProps={methods.register('gamePoint')} options={gamePointOptions} />
      <GTRadioSelect condense registerProps={methods.register('line')} options={lineOptions} />
      <GTRadioSelect condense registerProps={methods.register('winner')} options={winnerOptions} />
      <GTRadioSelect condense registerProps={methods.register('score')} options={scoreOptions} />
      <GTRadioSelect condense registerProps={methods.register('point')} options={pointOptions} />
    </>
  );
});

export default MatchReportFiltersReturning;
