import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import AnnotationService from '../../utils/services/AnnotationService';
import { FrameSetupState, ProjectDetails, ProjectionItem } from '../projects/types';
import {
  currentProjectGetAnnotationThunk,
  currentProjectGetDetailsThunk,
  currentProjectGetProjectionThunk,
} from './thunks';

const initialState: {
  project: ProjectDetails | null,
  annotations: Record<string, Record<string, unknown>> | null,
  prevAnnotations?: Record<string, Record<string, unknown>>,
  projectionData: ProjectionItem[],
  prevProjectionData?: ProjectionItem[],
  currentTimestamp: number,
  selectedFrameState: FrameSetupState,
  extractFramesActive: boolean,
  selectedTimelineId: number,
  pending: boolean
} = {
  project: null,
  annotations: null,
  prevAnnotations: undefined,
  projectionData: [],
  prevProjectionData: undefined,
  currentTimestamp: 0,
  selectedFrameState: {
    hasBall: false,
    hasCourt: false,
    hasPlayers: false,
    hasScoreboard: false
  },
  extractFramesActive: true,
  selectedTimelineId: -1,
  pending: false,
};

const currentProjectSlice = createSlice({
  name: 'currentProject',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    setCurrentTime: (state, action: PayloadAction<{ time: number }>) => ({
      ...state,
      currentTimestamp: action.payload.time,
    }),
    setSelectedTimeline: (state, action: PayloadAction<{ selectedTimelineId: number }>) => ({
      ...state,
      selectedTimelineId: action.payload.selectedTimelineId,
    }),
    setSelectedFrameState: (state, action: PayloadAction<{ value: FrameSetupState }>) => ({
      ...state,
      selectedFrameState: action.payload.value,
    }),
    setExtractFramesActive: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      extractFramesActive: action.payload.value,
    }),
    appendProjectionData: (state, action: PayloadAction<{ data: ProjectionItem }>) => {
      const timestampToSeek = action.payload.data.timestamp;
      const step = AnnotationService.current.currentVideoFrameStep;
      const existingIndex = state.projectionData.findIndex((item) => Math.abs(item.timestamp - timestampToSeek) < step);
      // const timestampToSeek = parseFloat(action.payload.data.timestamp.toFixed(4));
      // const existingIndex = state.projectionData.findIndex((item) => parseFloat(item.timestamp.toFixed(4)) === timestampToSeek);
      const projectionData = [...state.projectionData];
      const prevProjectionData = JSON.parse(JSON.stringify(projectionData));

      if (existingIndex >= 0) {
        const ballAnnotationId = state.projectionData[existingIndex].ball?.annotation_id || undefined;
        const playerTopAnnotationId = state.projectionData[existingIndex].player_top?.annotation_id || undefined;
        const playerBotAnnotationId = state.projectionData[existingIndex].player_bot?.annotation_id || undefined;

        projectionData.splice(existingIndex, 1, {
          ...action.payload.data,
          ball: {
            ...action.payload.data.ball,
            annotation_id: action.payload.data.ball?.annotation_id ?? ballAnnotationId,
          },
          player_top: {
            ...action.payload.data.player_top,
            annotation_id: action.payload.data.player_top?.annotation_id ?? playerTopAnnotationId,
          },
          player_bot: {
            ...action.payload.data.player_bot,
            annotation_id: action.payload.data.player_bot?.annotation_id ?? playerBotAnnotationId,
          },
        });
      } else {
        projectionData.push(action.payload.data);
      }
      return { ...state, projectionData, prevProjectionData };
    },
    updateAnnotationData: (state, action: PayloadAction<{ annotationId: string, data: Record<string, unknown> }>) => {
      if (!state.annotations || !state.annotations.metadata[action.payload.annotationId]) return { ...state };
      const newMetadata = {
        ...state.annotations.metadata,
        [action.payload.annotationId]: action.payload.data,
      };

      const newAnnotations = {
        ...state.annotations,
        metadata: { ...newMetadata },
      };

      return { ...state, prevAnnotations: { ...state.annotations }, annotations: { ...newAnnotations } };
    },
    undoLastUpdates: (state) => {
      if (!state.prevAnnotations || !state.prevProjectionData) return state;
      return {
        ...state,
        annotations: state.prevAnnotations,
        prevAnnotations: undefined,
        projectionData: state.prevProjectionData,
        prevProjectionData: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(currentProjectGetDetailsThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(currentProjectGetDetailsThunk.rejected, (state) => ({
      ...state,
      pending: false,
    }));
    builder.addCase(currentProjectGetDetailsThunk.fulfilled, (state, payload) => ({
      ...state,
      pending: false,
      project: { ...payload.payload },
    }));
    builder.addCase(currentProjectGetAnnotationThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(currentProjectGetAnnotationThunk.rejected, (state) => ({
      ...state,
      pending: false,
    }));
    builder.addCase(currentProjectGetAnnotationThunk.fulfilled, (state, payload) => ({
      ...state,
      pending: false,
      annotations: { ...payload.payload },
    }));
    builder.addCase(currentProjectGetProjectionThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(currentProjectGetProjectionThunk.rejected, (state) => ({
      ...state,
      pending: false,
    }));
    builder.addCase(currentProjectGetProjectionThunk.fulfilled, (state, payload) => {
      const dataToStore: ProjectionItem[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(payload.payload)) {
        dataToStore.push({
          timestamp: parseFloat(key),
          ...value,
        });
      }
      return {
        ...state,
        projectionData: dataToStore,
        prevProjectionData: undefined,
      };
    });
  },
  selectors: {
    selectProject: (state) => state.project,
    selectAnnotations: (state) => state.annotations,
    selectProjectionData: (state) => state.projectionData,
    selectCurrentTimestamp: (state) => state.currentTimestamp,
    selectSelectedTimelineId: (state) => state.selectedTimelineId,
    selectProjectApiPending: (state) => state.pending,
    selectSelectedFrameState: (state) => state.selectedFrameState,
    selectedExtractFramesActive: (state) => state.extractFramesActive,
  },
});

export const {
  selectProject,
  selectAnnotations,
  selectProjectionData,
  selectCurrentTimestamp,
  selectSelectedTimelineId,
  selectProjectApiPending,
  selectSelectedFrameState,
  selectedExtractFramesActive,
} = currentProjectSlice.selectors;

export const currentProjectSliceActions = currentProjectSlice.actions;

export default currentProjectSlice.reducer;
