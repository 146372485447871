import React from 'react';

type ZoomInProps = React.SVGProps<SVGSVGElement>;

function ZoomInIcon(props: ZoomInProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.76931 10.2501H7.57698C7.36448 10.2501 7.18636 10.1782 7.04263 10.0344C6.89888 9.89057 6.82701 9.71237 6.82701 9.49979C6.82701 9.28719 6.89888 9.1091 7.04263 8.96552C7.18636 8.82193 7.36448 8.75014 7.57698 8.75014H8.76931V7.55782C8.76931 7.34532 8.84121 7.16719 8.98503 7.02344C9.12883 6.87971 9.30703 6.80784 9.51963 6.80784C9.73221 6.80784 9.9103 6.87971 10.0539 7.02344C10.1975 7.16719 10.2693 7.34532 10.2693 7.55782V8.75014H11.4616C11.6741 8.75014 11.8522 8.82204 11.996 8.96584C12.1397 9.10966 12.2116 9.28786 12.2116 9.50044C12.2116 9.71304 12.1397 9.89113 11.996 10.0347C11.8522 10.1783 11.6741 10.2501 11.4616 10.2501H10.2693V11.4424C10.2693 11.6549 10.1974 11.833 10.0536 11.9768C9.90974 12.1205 9.73154 12.1924 9.51896 12.1924C9.30636 12.1924 9.12826 12.1205 8.98468 11.9768C8.8411 11.833 8.76931 11.6549 8.76931 11.4424V10.2501ZM9.51928 15.6155C7.81011 15.6155 6.3636 15.0237 5.17973 13.8401C3.99586 12.6565 3.40393 11.2104 3.40393 9.50169C3.40393 7.79296 3.99571 6.34628 5.17928 5.16167C6.36285 3.97707 7.809 3.38477 9.51773 3.38477C11.2264 3.38477 12.6731 3.9767 13.8577 5.16057C15.0423 6.34443 15.6347 7.79095 15.6347 9.50012C15.6347 10.2142 15.5148 10.8963 15.275 11.5463C15.0353 12.1963 14.7154 12.7616 14.3154 13.2424L20.0693 18.9963C20.2077 19.1347 20.2786 19.3088 20.2818 19.5184C20.285 19.728 20.2141 19.9052 20.0693 20.0501C19.9244 20.195 19.7488 20.2674 19.5424 20.2674C19.336 20.2674 19.1603 20.195 19.0155 20.0501L13.2616 14.2962C12.7616 14.7091 12.1866 15.0321 11.5366 15.2655C10.8866 15.4988 10.2141 15.6155 9.51928 15.6155ZM9.51928 14.1155C10.8077 14.1155 11.8991 13.6684 12.7933 12.7742C13.6876 11.8799 14.1347 10.7886 14.1347 9.50012C14.1347 8.21165 13.6876 7.1203 12.7933 6.22607C11.8991 5.33183 10.8077 4.88472 9.51928 4.88472C8.23081 4.88472 7.13946 5.33183 6.24523 6.22607C5.35101 7.1203 4.90391 8.21165 4.90391 9.50012C4.90391 10.7886 5.35101 11.8799 6.24523 12.7742C7.13946 13.6684 8.23081 14.1155 9.51928 14.1155Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ZoomInIcon;
