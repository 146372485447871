import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Menu, MenuItem,
} from '@mui/material';
import LayersIcon from '../../components/MaterialSymbolsIcons/LayersIcon';
import GTIconButton from '../../components/GTIconButton/GTIconButton';
import GTSwitch from '../../components/form/GTSwitch/GTSwitch';
import CheckIcon from '../../components/MaterialSymbolsIcons/CheckIcon';
import ProjectionReportContext from './context';
import MatchReportContext from '../MatchReport/MatchReportFilters/context';
import { MatchReportFiltersCategories } from '../MatchReport/MatchReportFilters/types';

function ProjectionLayersMenu() {
  const context = useContext(ProjectionReportContext);
  const reportContext = useContext(MatchReportContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  const handleBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback((event: React.MouseEvent) => {
    event?.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const isOverlayEnabled = useMemo(() => context.layers.diamondOverlay
    || context.layers.serveOverlay, [context.layers]);

  return (
    <>
      <GTIconButton onClick={handleBtnClick}>
        <LayersIcon style={{ fontSize: 24 }} />
      </GTIconButton>
      <Menu
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {/* {reportContext.filtersMode !== MatchReportFiltersCategories.SERVING && ( */}
        {/*  <MenuItem */}
        {/*    sx={{ */}
        {/*      fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between', */}
        {/*    }} */}
        {/*    onClick={() => context.setLayers({ directions: !context.layers.directions })} */}
        {/*  > */}
        {/*    Directions */}
        {/*    <GTSwitch checked={context.layers.directions} /> */}
        {/*  </MenuItem> */}
        {/* )} */}
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
          }}
          onClick={() => context.setLayers({ runawaysOverlay: !context.layers.runawaysOverlay })}
        >
          Runaways Overlay
          <GTSwitch checked={context.layers.runawaysOverlay} />
        </MenuItem>
        {reportContext.filtersMode !== MatchReportFiltersCategories.SERVING && (
          <MenuItem
            sx={{
              fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
            }}
            onClick={() => context.setLayers({ diamondOverlay: !context.layers.diamondOverlay })}
          >
            Diamond Overlay
            <GTSwitch checked={context.layers.diamondOverlay} />
          </MenuItem>
        )}
        {reportContext.filtersMode === MatchReportFiltersCategories.SERVING && (
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
          }}
          onClick={() => context.setLayers({ servePlacement: !context.layers.servePlacement })}
        >
          Serve Placement
          <GTSwitch checked={context.layers.servePlacement} />
        </MenuItem>
        )}
        {reportContext.filtersMode === MatchReportFiltersCategories.SERVING && (
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
          }}
          onClick={() => context.setLayers({ serveOverlay: !context.layers.serveOverlay })}
        >
          Serve Overlay
          <GTSwitch checked={context.layers.serveOverlay} />
        </MenuItem>
        )}
        {isOverlayEnabled && (
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between', borderTop: '1px solid #E3E3E8',
          }}
          onClick={() => context.setLayers({
            pro: true,
            adv: false,
            int: false,
          })}
        >
          Pro
          {context.layers.pro && <CheckIcon />}
        </MenuItem>
        )}
        {isOverlayEnabled && (
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
          }}
          onClick={() => context.setLayers({
            pro: false,
            adv: true,
            int: false,
          })}
        >
          Adv
          {context.layers.adv && <CheckIcon />}
        </MenuItem>
        )}
        {isOverlayEnabled && (
        <MenuItem
          sx={{
            fontWeight: 500, width: 220, height: 44, justifyContent: 'space-between',
          }}
          onClick={() => context.setLayers({
            pro: false,
            adv: false,
            int: true,
          })}
        >
          Int
          {context.layers.int && <CheckIcon />}
        </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default ProjectionLayersMenu;
