import { Controller, useFormContext } from 'react-hook-form';
import { MatchFormInputs } from './types';
import GTSelect, { GTSelectOption } from '../../components/form/GTSelect/GTSelect';

const scoringSystemOptions: GTSelectOption[] = [
  { title: '8-Game Pro Set: no tiebreak', value: '8-Game Pro Set: no tiebreak' },
  { title: '8-Game Pro Set: 7-point tiebreak', value: '8-Game Pro Set: 7-point tiebreak' },
  { title: '8-Game Pro Set: 10-point tiebreak', value: '8-Game Pro Set: 10-point tiebreak' },
  { title: '3 Sets: tiebreak for all', value: '3 Sets: tiebreak for all' },
  { title: '3 Sets: tiebreak for all but last', value: '3 Sets: tiebreak for all but last' },
  { title: '3 Sets: match tiebreaker in lieu of 3rd set', value: '3 Sets: match tiebreaker in lieu of 3rd set' },
  { title: '3 Sets: no Ad scoring and tiebreak for all', value: '3 Sets: no Ad scoring and tiebreak for all' },
  { title: '5 Sets: tiebreak for all', value: '5 Sets: tiebreak for all' },
  { title: '5 Sets: tiebreak for all but last', value: '5 Sets: tiebreak for all but last' },
  { title: '5 Sets: super tie breaker at 6-6 in 5th set', value: '5 Sets: super tie breaker at 6-6 in 5th set' },
  { title: '5 Sets: 5th set Wimbledon scoring', value: '5 Sets: 5th set Wimbledon scoring' },
  { title: '3 Sets: 3rd set Wimbledon super tiebreaker', value: '3 Sets: 3rd set Wimbledon super tiebreaker' },
];

interface MatchCreatorStepScoringProps {
  editMode: boolean
}

function MatchCreatorStepScoring({ editMode }: MatchCreatorStepScoringProps) {
  const { control } = useFormContext<MatchFormInputs>();

  return (
    <Controller
      control={control}
      rules={{
        required: 'Required',
      }}
      render={({ field, fieldState: { error } }) => (
        <GTSelect
          disabled={editMode}
          id="matchScoringSystem"
          options={scoringSystemOptions}
          placeholder="Select Scoring System"
          label="Scoring System"
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!error}
          errorMessage={error?.message}
        />
      )}
      name="scoringSystem"
    />
  );
}

export default MatchCreatorStepScoring;
