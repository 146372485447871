import classnames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import BallIcon from '@mui/icons-material/SportsBaseballOutlined';
import { useCallback } from 'react';
import styles from './MatchCreator.module.css';
import PrizeIcon from '../../components/MaterialSymbolsIcons/PrizeIcon';
import SunCloudsIcon from '../../components/MaterialSymbolsIcons/SunCloudsIcon';
import { MatchCreatorStep } from './types';

type MatchCreatorStepperProps = {
  isMatchReady: boolean,
  isPlayersReady: boolean,
  isScoringReady: boolean,
  currentStep: MatchCreatorStep,
  onPointClick: (step: MatchCreatorStep) => unknown
};

function MatchCreatorStepper({
  currentStep,
  onPointClick,
  isMatchReady,
  isPlayersReady,
  isScoringReady,
}: MatchCreatorStepperProps) {
  const onStepperPointClick = useCallback((step: MatchCreatorStep, isCurrentReady: boolean) => {
    if (isCurrentReady) {
      onPointClick(step);
    }
  }, [onPointClick]);

  return (
    <div className={styles.stepper}>
      <button
        aria-label="Match"
        type="button"
        onClick={() => onStepperPointClick('MATCH', isMatchReady)}
        className={styles.stepperPoint}
      >
        <div className={classnames(styles.stepperPointIcon, {
          [styles.active]: currentStep === 'MATCH',
          [styles.done]: isMatchReady,
        })}
        >
          {isMatchReady ? <CheckIcon /> : <BallIcon />}
        </div>
        Match
      </button>
      <hr />
      <button
        aria-label="Players"
        type="button"
        onClick={() => onStepperPointClick('PLAYERS', isPlayersReady)}
        className={styles.stepperPoint}
      >
        <div className={classnames(styles.stepperPointIcon, {
          [styles.active]: currentStep === 'PLAYERS',
          [styles.done]: isPlayersReady,
        })}
        >
          {isPlayersReady ? <CheckIcon /> : <PersonIcon />}
        </div>
        Players
      </button>
      <hr />
      <button
        aria-label="Scoring"
        type="button"
        onClick={() => onStepperPointClick('SCORING', isScoringReady)}
        className={styles.stepperPoint}
      >
        <div className={classnames(styles.stepperPointIcon, {
          [styles.active]: currentStep === 'SCORING',
          [styles.done]: isScoringReady,
        })}
        >
          {isScoringReady ? <CheckIcon /> : <PrizeIcon />}
        </div>
        Scoring
      </button>
      <hr />
      <button
        aria-label="Conditions"
        type="button"
        onClick={() => onStepperPointClick('CONDITIONS', isScoringReady)}
        className={styles.stepperPoint}
      >
        <div className={classnames(styles.stepperPointIcon, {
          [styles.active]: currentStep === 'CONDITIONS',
        })}
        >
          <SunCloudsIcon />
        </div>
        Conditions
      </button>
    </div>
  );
}

export default MatchCreatorStepper;
