import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';
import styles from './GTLink.module.css';

type GTLinkProps = LinkProps;

function GTLink({ children, className, ...props }: GTLinkProps) {
  return <Link className={classnames(styles.link, { [className as string]: !!className })} {...props}>{children}</Link>;
}

export default GTLink;
