export type UserRoles = 'Admin' | 'Analyst' | 'User';

export enum UserRolesLabels {
  Admin = 'Admin',
  Analyst = 'Editor',
  User = 'Viewer',
}

export type UserStatuses = 'UNCONFIRMED' | 'CONFIRMED' | 'ARCHIVED' | 'COMPROMISED' | 'UNKNOWN' | 'RESET_REQUIRED' | 'FORCE_CHANGE_PASSWORD';

export interface User {
  id: string
  firstName: string
  lastNAme:string
  email:string
  email_verified:string
  avatarPath:string
  active:boolean
  status: UserStatuses
  role: UserRoles
  createdAt:string
  updatedAt:string
}
