import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import styles from './AuthLayout.module.css';
import imgUrl from './assets/auth-side-img.jpg';
import logoUrl from './assets/gt-logo.svg';
import { authAlertsSliceActions, selectAlerts } from '../../store/authAlerts/slice';
import GTAlert from '../../components/GTAlert/GTAlert';

function AuthLayoutAlerts() {
  const alerts = useSelector(selectAlerts);
  const dispatch = useDispatch();

  const closeAlert = useCallback((alertId: string) => {
    dispatch(authAlertsSliceActions.removeAlert({ alertId }));
  }, [dispatch]);

  return (
    <div className={styles.alertsWrap}>
      {alerts.map((item) => (
        <GTAlert
          key={item.id}
          id={item.id}
          type={item.type}
          closeAlert={closeAlert}
          text={item.text}
        />
      ))}

    </div>
  );
}

function AuthLayout() {
  return (
    <div className={styles.wrap}>
      <div className={styles.sideImage} style={{ backgroundImage: `url(${imgUrl})` }} />
      <div className={styles.content}>
        <AuthLayoutAlerts />
        <img className={styles.logo} src={logoUrl} alt="Gotta Tennis" />
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
