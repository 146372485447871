import classnames from 'classnames';
import {
  useCallback, useContext, useMemo, useRef,
} from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import styles from './MatchReportFilters.module.css';
import MatchReportFiltersServing from './MatchReportFiltersServing';
import MatchReportFiltersReturning from './MatchReportFiltersReturning';
import MatchReportFiltersInPlay from './MatchReportFiltersInPlay';
import { MatchReportFiltersCategories } from './types';
import MatchReportContext from './context';
import GTIconButton from '../../../components/GTIconButton/GTIconButton';

function MatchReportFilters() {
  const context = useContext(MatchReportContext);
  const filtersFormRef = useRef<{ reset:() => void }>();
  const activeFilters = useMemo(() => {
    switch (context.filtersMode) {
      case MatchReportFiltersCategories.RETURNING: return context.filtersReturning;
      case MatchReportFiltersCategories.IN_PLAY: return context.filtersInPlay;
      default: return context.filtersServing;
    }
  }, [context.filtersInPlay, context.filtersMode, context.filtersReturning, context.filtersServing]);

  const isActiveFiltersEmpty = useMemo(() => {
    const values = Object.values(activeFilters);
    return !values.find((item) => !!item);
  }, [activeFilters]);

  const onReset = useCallback(() => {
    filtersFormRef.current?.reset();
  }, [filtersFormRef]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <button
          type="button"
          className={classnames(styles.modeBtn, {
            [styles.active]: context.filtersMode === MatchReportFiltersCategories.SERVING,
          })}
          onClick={() => context.setFiltersMode(MatchReportFiltersCategories.SERVING)}
        >
          Serving
        </button>
        <button
          type="button"
          className={classnames(styles.modeBtn, {
            [styles.active]: context.filtersMode === MatchReportFiltersCategories.RETURNING,
          })}
          onClick={() => context.setFiltersMode(MatchReportFiltersCategories.RETURNING)}
        >
          Returning
        </button>
        <button
          type="button"
          className={classnames(styles.modeBtn, {
            [styles.active]: context.filtersMode === MatchReportFiltersCategories.IN_PLAY,
          })}
          onClick={() => context.setFiltersMode(MatchReportFiltersCategories.IN_PLAY)}
        >
          In play
        </button>
        <GTIconButton onClick={onReset} disabled={isActiveFiltersEmpty} style={{ width: 48 }}>
          <RestartAltIcon />
        </GTIconButton>
      </div>
      <div className={classnames(styles.body, 'customScrollbar')}>
        {context.filtersMode === MatchReportFiltersCategories.SERVING && <MatchReportFiltersServing ref={filtersFormRef} />}
        {context.filtersMode === MatchReportFiltersCategories.RETURNING && <MatchReportFiltersReturning ref={filtersFormRef} />}
        {context.filtersMode === MatchReportFiltersCategories.IN_PLAY && <MatchReportFiltersInPlay ref={filtersFormRef} />}
      </div>
    </div>
  );
}

export default MatchReportFilters;
