import classnames from 'classnames';
import {
  Skeleton,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './Settings.module.css';
import { selectCurrentUser } from '../../store/currentUser/slice';
import GTButton from '../../components/GTButton/GTButton';
import UserPlaceholderImg from './assets/users_placeholder.png';
import SettingsUserInviteModal from './SettingsUserInviteModal';
import { useAppDispatch } from '../../store';
import { usersGetThunk } from '../../store/users/thunks';
import { selectUsers, selectUsersApiPending } from '../../store/users/slice';
import UsersTableMenu from './UsersTableMenu';
import { User, UserRolesLabels } from '../../store/users/types';
import UserActivity from './UserActivity';
import SettingsUserContext, { SettingsUserContextType } from './context';

function SettingsUsers() {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const users = useSelector(selectUsers);
  const isUsersApiPending = useSelector(selectUsersApiPending);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isInviteModalOpened, setIsInviteModalOpened] = useState(false);
  const [isActivityLogOpened, setIsActivityLogOpened] = useState(false);

  const filteredUsers = useMemo(() => users.filter((item) => item.email !== currentUser?.email), [currentUser?.email, users]);

  useEffect(() => {
    dispatch(usersGetThunk());
  }, [dispatch]);

  const onOpenActivityLog = useCallback((user: User) => {
    setSelectedUser(user);
    setIsActivityLogOpened(true);
  }, []);

  const contextValues = useMemo<SettingsUserContextType>(() => ({
    onOpenActivityLog,
  }), [onOpenActivityLog]);

  if (isUsersApiPending) {
    return <Skeleton variant="rounded" width="100%" height={400} />;
  }

  return (
    <SettingsUserContext.Provider value={contextValues}>
      <div className={classnames(styles.box)}>
        <div className={styles.boxHeader}>
          Users
          <GTButton onClick={() => setIsInviteModalOpened(true)} style={{ marginLeft: 'auto' }} color="primary">
            Invite
          </GTButton>
        </div>
        <div className={classnames(styles.tableWrap, 'customScrollbar')}>
          <Table className={styles.table}>
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell sx={{ width: '25%' }}>Email</TableCell>
                <TableCell sx={{ width: '25%' }}>
                  Role
                </TableCell>
                <TableCell sx={{ width: '15%' }} align="right">
                  Date Invited
                </TableCell>
                <TableCell sx={{ width: '60px' }} />
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody}>
              <TableRow className={classnames(styles.ownRow)}>
                <TableCell>
                  {`${currentUser?.firstName || ''} ${currentUser?.lastNAme || ''} (you)`}
                </TableCell>
                <TableCell>{currentUser?.email}</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell align="center">01.01.24</TableCell>
              </TableRow>
              {filteredUsers.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {`${item.firstName || ''} ${item.lastNAme || ''}`}
                  </TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{UserRolesLabels[item.role]}</TableCell>
                  <TableCell align="center">
                    {item.updatedAt
                      ? new Date(item.updatedAt).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      })
                      : ''}
                  </TableCell>
                  <TableCell>
                    <UsersTableMenu user={item} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!filteredUsers.length && (
          <div className={styles.usersPlaceholder}>
            <img src={UserPlaceholderImg} alt="" />
            <h4>Invite members</h4>
            <p>
              Click the "Invite" button above to
              <br />
              invite new member to the platform.
            </p>
          </div>
          )}
        </div>

        <SettingsUserInviteModal
          open={isInviteModalOpened}
          onSuccess={() => setIsInviteModalOpened(false)}
          onCancel={() => setIsInviteModalOpened(false)}
        />

        {selectedUser && <UserActivity user={selectedUser} open={isActivityLogOpened} onOpenChange={setIsActivityLogOpened} />}
      </div>
    </SettingsUserContext.Provider>
  );
}

export default SettingsUsers;
