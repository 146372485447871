import { createContext } from 'react';

export type ForgotPasswordFormContextType = {
  username: string
};

const ForgotPasswordContext = createContext<ForgotPasswordFormContextType>({
  username: '',
});

export default ForgotPasswordContext;
