import { updateUserAttributes } from 'aws-amplify/auth';
import {
  del, get, post, put,
} from 'aws-amplify/api';
import { User, UserRoles } from '../store/users/types';

interface UpdateUserProfileInput {
  name: string,
  family_name: string,

  // role: 'Admin|Analyst|User',
  avatarPath?: string
}
export const updateUserProfile = async (input: UpdateUserProfileInput) => {
  await updateUserAttributes({
    userAttributes: {
      name: input.name,
      family_name: input.family_name,
      'custom:avatarPath': input.avatarPath,
    },
  });
};

export const getUsers = async () => {
  const operation = get({
    apiName: 'gtProjects',
    path: '/user',
  });

  const response = await operation.response;
  return await response.body.json() as unknown as User[];
};

export const getUser = async (userId: string) => {
  const operation = get({
    apiName: 'gtProjects',
    path: `/user/${userId}`,
  });

  const response = await operation.response;
  return await response.body.json() as unknown as User;
};

export const deactivateUser = async (userId: string) => {
  const operation = del({
    apiName: 'gtProjects',
    path: `/user/${userId}`,
  });

  return operation.response;
};

interface UpdateUserPayload {
  firstName: string,
  lastName: string,
  role: UserRoles
  avatarPath?: string
}

export const updateUser = async (userId: string, data: UpdateUserPayload) => {
  const operation = put({
    apiName: 'gtProjects',
    path: `/user/${userId}`,
    options: {
      body: {
        ...data,
      },
    },
  });

  return operation.response;
};

interface InviteUserPayload {
  firstName?: string,
  lastName?: string,
  role: UserRoles,
  email: string,
}

export const inviteUser = async (data: InviteUserPayload) => {
  const operation = post({
    apiName: 'gtProjects',
    path: '/user',
    options: {
      body: {
        ...data,
      },
    },
  });

  return operation.response;
};
