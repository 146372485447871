import { CircularProgress } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth';
import styles from './AuthForm.module.css';
import { AUTH_BASE_ROUTE, AUTH_WELCOME_ROUTE, BASE_ROUTE } from '../../router/routesConstants';
import { currentUserGetDetailsThunk } from '../../store/currentUser/thunks';
import { useAppDispatch } from '../../store';

function InvitationSignUp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const handleSignIn = useCallback(async () => {
    const code = searchParams.get('code');
    if (!code) {
      navigate(AUTH_BASE_ROUTE, { replace: true });
      return;
    }

    const [username] = atob(code).split('|');

    try {
      const { isSignedIn, nextStep } = await signIn({ username, password: code });
      await dispatch(currentUserGetDetailsThunk());

      if (!isSignedIn) {
        navigate(AUTH_BASE_ROUTE, { replace: true });
      }

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate(AUTH_WELCOME_ROUTE, { replace: true, state: { email: username } });
      } else {
        navigate(BASE_ROUTE, { replace: true });
      }
    } catch (e) {
      console.error(e);
      navigate(AUTH_BASE_ROUTE, { replace: true });
    }
  }, [dispatch, navigate, searchParams]);

  useEffect(() => {
    handleSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrap} style={{ alignItems: 'center' }}>
      <CircularProgress />
    </div>
  );
}

export default InvitationSignUp;
