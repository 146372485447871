/* eslint-disable no-underscore-dangle */
import { debounce } from '@mui/material';
import { requestCourtProjection } from '../../api/projects';
import { store as appStore } from '../../store';
import { currentProjectSliceActions } from '../../store/currentProject/slice';
import { FrameSetupState } from '../../store/projects/types';

export enum BallActionType {
  Hit = 0,
  Bounce = 1,
}

export enum ViaStrutureParameter {
  annotationType = '2',
  ballAction = '3',
  filterMetadata = '10',
}

export interface AnnotaitonItemDef {
  annotation_id?: string
}

export interface AnnotationMetadata {
  court: any[]
  ball?: AnnotaitonItemDef
  player_top?: AnnotaitonItemDef
  player_bot?: AnnotaitonItemDef
  scoreboard?: {}
  filterMetadata?: {}
  empty: boolean
}

export enum AnnotationType {
  PlayerUp = 0,
  PlayerDown = 1,
  Ball = 2,
  Court = 4,
  Scoreboard = 5,
  Event,
}

export interface TimelineEventItem {
  isGameEvent: boolean
}

interface ProjectionMultipleAddCommand {
  count: number
}

const onMetadataCallback = debounce((callback) => {
  callback();
}, 300);

interface SelectedAnnotationItem {
  id: string
  metadata: any
  type: AnnotationType
}

interface SelectedAnnotationItem {
  id: string
  metadata: any
  type: AnnotationType
}

export default class AnnotationService {
  static current = new AnnotationService();

  static initialize(via: _via) {
    this.current.setViaAnnotator(via);
  }

  protected currentItem?: SelectedAnnotationItem;

  protected annotator?: _via;

  protected currentTimestamp: number = 0;

  protected currentMetadata?: AnnotationMetadata;

  previousMetadata?: Record<string, unknown>;

  public onTimeUpdated?: (timestamp: number) => void;

  public onBeforeMetadataChanged?: (metadata: any) => void;

  public onMetadataChanged?: () => void;

  public onItemAddAction?: () => void;

  public onTimelineSelected?: (id: number, mindex: number) => void;

  public onAnnotationItemSelected?: (item?: SelectedAnnotationItem) => void;

  public addTimelineEvent?: TimelineEventItem;

  protected _onTimeUpdated(time: number) {
    const timestamp = this.annotator?.va?.temporal_segmenter?.m?.currentTime ?? time;
    this.currentTimestamp = timestamp;
    this.currentMetadata = this.getMetadataByTimestamp();
    this.onTimeUpdated && this.onTimeUpdated(timestamp);
  }

  public resetViaAnnotator() {
    this.annotator = undefined;
    this.onTimeUpdated = undefined;
    this.onMetadataChanged = undefined;
    // this.onItemAddAction = undefined
    // this.onTimelineSelected = undefined
    // this.onAnnotationItemSelected = undefined
  }

  protected requestMetadataUpdate() {
    onMetadataCallback(() => {
      this.currentMetadata = this.getMetadataByTimestamp();
      this.onMetadataChanged && this.onMetadataChanged();
    });
  }

  private setViaAnnotator(via: _via) {
    this.annotator = via;

    const { va, d } = via;
    va.onTimeUpdated = (t: number) => {
      setTimeout(() => {
        this._onTimeUpdated(t);
      }, 400);
    };

    va.onMetadataChanged = () => {
      if (this.isAddProjectionInProgress) return;
      this.requestMetadataUpdate();
    };

    va.onEventsTimelineClick = (time) => {
      if (this.addTimelineEvent) {
        this.addTimelineEvent = undefined;
        this.annotator?.va?.temporal_segmenter?._tmetadata_mid_add_at_time(time);
      }
    };

    va.onItemAddAction = (mid?: string) => {
      console.log(`onItemAddAction: ${mid}`);

      if (this.onPlayerItemAdded() && this.courtProjectionAddCommand === undefined) {
        this.onItemAddAction && this.onItemAddAction();
      }

      if (this.onCourtItemAdded() && this.playerAddCommand === undefined) {
        this.onItemAddAction && this.onItemAddAction();
      }
    };

    va.onTimelineSelected = (timelineId) => {
      const selected_mindex = this.annotator?.va?.temporal_segmenter?.selected_mindex;
      this.onTimelineSelected && this.onTimelineSelected(parseInt(timelineId), selected_mindex);
    };

    va.onItemSelctionAction = (mid?: string) => {
      if (!mid || !d.store.metadata[mid]) {
        const selected_mindex = this.annotator?.va?.temporal_segmenter?.selected_mindex;
        const selected_mid = this.annotator?.va?.temporal_segmenter?.selected_mid;
        const selected_gindex = this.annotator?.va?.temporal_segmenter?.selected_gindex;

        const metadata = d.store.metadata[selected_mid];

        this.currentItem = selected_mindex === -1 ? undefined : {
          id: selected_mid,
          metadata: {
            ...metadata,
            metadataIndex: selected_mindex,
            timelineIndex: selected_gindex,
          },
          type: AnnotationType.Event,
        };

        this.notifyWithSelectedItem();
        return;
      }

      if (this.annotator?.va?.temporal_segmenter?.selected_mindex !== undefined) {
        this.annotator.va.temporal_segmenter.selected_mindex = -1;
      }

      const metadata = d.store.metadata[mid];

      this.currentItem = {
        id: mid,
        metadata,
        type: parseInt(metadata.av['2']) as AnnotationType,
      };

      this.notifyWithSelectedItem();
    };

    d.onBeforeMetadataChanged = () => {
      this.onBeforeMetadataChanged && this.onBeforeMetadataChanged(this.metadataSnapshot);
      this.previousMetadata = JSON.parse(JSON.stringify(this.metadataSnapshot));
    };
  }

  protected applyMetadataSnapshotSafe(metadata: any) {
    if (!this.annotator?.d) return;
    this.annotator.d._applyMetadata(metadata);

    this.requestMetadataUpdate();
  }

  get metadataSnapshot() {
    return this.data?.store?.metadata;
  }

  protected notifyWithSelectedItem() {
    this.onAnnotationItemSelected && this.onAnnotationItemSelected(this.currentItem);
  }

  public selectItem(id?: string) {
    if (!id || !this.data) return;
    const metadata = this.data.store.metadata[id];

    this.currentItem = {
      id,
      metadata,
      type: parseInt(metadata.av['2']) as AnnotationType,
    };

    this.onAnnotationItemSelected && this.onAnnotationItemSelected(this.currentItem);
  }

  get currentFrameProjectionState(): FrameSetupState {
    return {
      hasPlayers: this.currentMetadata?.player_bot !== undefined && this.currentMetadata.player_top !== undefined,
      hasScoreboard: this.currentMetadata?.scoreboard !== undefined,
      hasCourt: (this.currentMetadata?.court.length ?? 0) >= 14,
      hasBall: this.currentMetadata?.ball !== undefined,
    };
  }

  get data() {
    return this.annotator?.d;
  }

  get selectedItem() {
    return this.currentItem;
  }

  get selectedMetadata() {
    return this.currentMetadata;
  }

  get selectedTimestamp() {
    return this.currentTimestamp;
  }

  protected get controlPanel(): any {
    return this.annotator?.cp;
  }

  public async fetchMetadataForCurrentTime(timestamp: number, metadata: AnnotationMetadata) {
    if (metadata.court?.length >= 14) {
      try {
        const data = await requestCourtProjection(metadata as any) as any;

        if (data.ball) {
          data.ball.annotation_id = metadata?.ball?.annotation_id;
        }

        if (data.player_bot) {
          data.player_bot.annotation_id = metadata?.player_bot?.annotation_id;
        }

        if (data.player_top) {
          data.player_top.annotation_id = metadata?.player_top?.annotation_id;
        }

        return {
          timestamp,
          ...data,
          filterMetadata: metadata.filterMetadata,
        };
      } catch (e) {
      }
    }
    return {
      timestamp,
    };
  }

  get currentVideoFPS() {
    const data: any = this.annotator?.d;
    return data?.store?.endpoint_results?.fps ?? 24.0;
  }

  get currentVideoFrameStep() {
    return 1.0 / this.currentVideoFPS;
  }

  public getAnnotationById(annotationId?: string) {
    return annotationId ? this.data?.store.metadata[annotationId] : undefined;
  }

  public getMetadataByTimestamp(): AnnotationMetadata | undefined {
    const resultData: AnnotationMetadata = { court: [], empty: true };
    const currentRegion = this.annotator?.va.file_annotator[0][0]?.creg;
    if (!currentRegion) return resultData;

    const data: any = this.annotator?.d;
    const keys = Object.keys(currentRegion);

    keys.forEach((key) => {
      const value = data.store.metadata[key];
      const type = parseInt(value.av[ViaStrutureParameter.annotationType]);
      if (type === AnnotationType.PlayerUp) {
        resultData.player_top = { ...value, annotation_id: key };
        resultData.empty = false;
      }
      if (type === AnnotationType.PlayerDown) {
        resultData.player_bot = { ...value, annotation_id: key };
        resultData.empty = false;
      }
      if (type === AnnotationType.Ball) {
        resultData.ball = { ...value, annotation_id: key };
        resultData.empty = false;
      }
      if (type === AnnotationType.Court) {
        resultData.court.push({ ...value, annotation_id: key });
        resultData.empty = false;
      }
      if (type === AnnotationType.Scoreboard) {
        resultData.scoreboard = { ...value, annotation_id: key };
        resultData.empty = false;
      }
    });
    return resultData;
  }

  // Ball Controlls

  public async addBall() {
    this.controlPanel?.emit_event('region_shape', { shape: 'POINT', type: AnnotationType.Ball });
  }

  get selectedBallFilterData() {
    if (this.selectedItem?.type !== AnnotationType.Ball) return {};
    const data = {
      ...this.selectedItem.metadata.av[ViaStrutureParameter.filterMetadata],
    };
    const action = this.selectedItem.metadata.av[ViaStrutureParameter.ballAction];
    if (action !== undefined) {
      data.ballAction = parseInt(action) === BallActionType.Hit ? 'hit' : 'bounce';
    }
    return data;
  }

  get selectedPlayerFilterData() {
    if (this.selectedItem?.type !== AnnotationType.PlayerUp && this.selectedItem?.type !== AnnotationType.PlayerDown) return {};
    const data = {
      ...this.selectedItem.metadata.av[ViaStrutureParameter.filterMetadata],
    };

    if (this.selectedItem.type === AnnotationType.PlayerUp) {
      data.playerType = 'player_top';
    }

    if (this.selectedItem.type === AnnotationType.PlayerDown) {
      data.playerType = 'player_bot';
    }

    return data;
  }

  get selectedGameSetEventMetadata() {
    if (this.selectedItem?.type !== AnnotationType.Event) return {};
    const data = {
      ...this.selectedItem.metadata.av[ViaStrutureParameter.filterMetadata],
    };
    return data;
  }

  public restorePreviousMetadata() {
    if (!this.previousMetadata || !this.annotator) return;
    this.applyMetadataSnapshotSafe(this.previousMetadata);
    this.previousMetadata = undefined;
  }

  // Player Controls

  protected playerAddCommand?: ProjectionMultipleAddCommand;

  protected onPlayerItemAdded(): boolean {
    if (!this.playerAddCommand) return true;
    this.playerAddCommand.count += 1;
    const isFinished = this.playerAddCommand.count >= 2;
    if (!isFinished) this.addPlayer();
    if (isFinished) {
      this.playerAddCommand = undefined;
      this.requestMetadataUpdate();
    }
    return isFinished;
  }

  public addPlayer() {
    // let count = this.currentMetadata?.player_bot ? 1 : 0
    // count += this.currentMetadata?.player_top ? 1 : 0

    const count = this.playerAddCommand?.count ?? 0;

    const isContains = count >= 2;
    if (isContains) return false;

    if (!this.playerAddCommand) {
      this.playerAddCommand = { count };
    }

    let type = count >= 1 ? AnnotationType.PlayerUp : AnnotationType.PlayerDown;

    if (this.currentMetadata?.player_bot !== undefined) type = AnnotationType.PlayerUp;
    if (this.currentMetadata?.player_top !== undefined) type = AnnotationType.PlayerDown;

    this.controlPanel?.emit_event('region_shape', { shape: 'RECTANGLE', type, autoselect: false });
    return true;
  }

  // Court Controlls

  protected courtProjectionAddCommand?: ProjectionMultipleAddCommand;

  protected onCourtItemAdded(): boolean {
    if (!this.courtProjectionAddCommand) return true;
    this.courtProjectionAddCommand.count += 1;
    const isFinished = this.courtProjectionAddCommand.count >= 14;
    if (!isFinished) this.addCourt();
    if (isFinished) {
      this.courtProjectionAddCommand = undefined;
      this.requestMetadataUpdate();
    }
    return isFinished;
  }

  public addCourt(): boolean {
    const courtPointsCount = this.currentMetadata?.court.length ?? 0;
    const isContainsCourt = courtPointsCount >= 14;
    if (isContainsCourt) return false;

    if (!this.courtProjectionAddCommand) {
      this.courtProjectionAddCommand = { count: courtPointsCount };
    }
    this.controlPanel?.emit_event('region_shape', { shape: 'POINT', type: AnnotationType.Court, autoselect: false });
    return true;
  }

  get isAddProjectionInProgress() {
    return this.courtProjectionAddCommand !== undefined || this.playerAddCommand !== undefined;
  }

  public cancelAddCourt() {
    this.courtProjectionAddCommand = undefined;
  }

  // Scoreboard Controlls

  public async addScoreboard() {
    this.controlPanel?.emit_event('region_shape', { shape: 'RECTANGLE', type: AnnotationType.Scoreboard });
  }

  //

  public async addGameEvent() {
    this.addTimelineEvent = {
      isGameEvent: true,
    };
  }

  public async addSetEvent() {
    this.addTimelineEvent = {
      isGameEvent: false,
    };
  }

  // Metadata Controlls

  public removeSelectedItem() {
    if (this.currentItem?.type === AnnotationType.Event) {
      this.removeSelectedTimelineEvent();
      this.currentItem = undefined;
      return;
    }
    this.annotator?.va.file_annotator[0][0]?._creg_del_sel_regions();
  }

  public clearCurrentFrame() {
    this.annotator?.va.file_annotator[0][0]?._creg_select_all();
    this.annotator?.va.file_annotator[0][0]?._creg_del_sel_regions();
  }

  public removeSelectedTimelineEvent() {
    this.annotator?.va?.temporal_segmenter?._tmetadata_mid_del_sel();
  }

  public updateTimelineItemMetadata() {
  }

  public updatePlayerNames(p1Name: string, p2Name: string) {
    if (this.annotator) {
      this.annotator.va.playerTopName = p1Name;
      this.annotator.va.playerBottomName = p2Name;
      this.annotator.d._updateView();
    }
  }

  public updateSelectedItemMetadata(filterMetadata: any) {
    if (!this.currentItem) return;

    console.log(filterMetadata)

    const { metadata } = this.currentItem;

    const av = {
      ...metadata.av,
      [ViaStrutureParameter.filterMetadata]: filterMetadata,
    };

    if (this.currentItem.type === AnnotationType.Ball) {
      const action = filterMetadata.ballAction === 'hit' ? BallActionType.Hit : BallActionType.Bounce;
      av[ViaStrutureParameter.ballAction] = `${action}`;
    }

    if (this.currentItem.type === AnnotationType.PlayerDown || this.currentItem.type === AnnotationType.PlayerUp) {
      const { playerType } = filterMetadata;
      av[ViaStrutureParameter.annotationType] = playerType === 'player_top' ? AnnotationType.PlayerUp : AnnotationType.PlayerDown;
    }

    this.data?.metadata_update(
      '1',
      this.currentItem.id,
      metadata.z,
      metadata.xy,
      av,
    );

    // timeout is needed to avoid strange behavior
    setTimeout(() => {
      if (!this.currentItem) return;
      appStore.dispatch(currentProjectSliceActions.updateAnnotationData({
        annotationId: this.currentItem.id,
        data: { ...this.currentItem.metadata, av },
      }));
    }, 0);
  }
}
