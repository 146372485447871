import { Switch } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';
import { SwitchProps } from '@mui/material/Switch/Switch';
import classnames from 'classnames';
import styles from './GTSwitch.module.css';

type GTSwitchProps = SwitchProps & {
  style?: CSSProperties,
};

const GTSwitch = forwardRef<HTMLButtonElement, GTSwitchProps>(({
  className, style, ...props
}, ref) => (
  <div
    style={{ ...style }}
    className={classnames(styles.wrap, { [className as string]: !!className })}
  >
    <Switch
      ref={ref}
      disableRipple
      classes={{
        root: styles.switch,
        switchBase: styles.switchBase,
      }}
      {...props}
    />
  </div>
));

export default GTSwitch;
