import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import VideoAnnotatorSVGDefinitions from './VideoAnnotatorSVGDefinitions';
import {
  currentProjectSliceActions,
  selectProject,
  selectProjectApiPending,
} from '../../store/currentProject/slice';
import { currentProjectGetDetailsThunk } from '../../store/currentProject/thunks';
import { useAppDispatch } from '../../store';
import VideoAnnotatorDefaultViaMarkup from './VideoAnnotatorDefaultViaMarkup';
import {
  applyProjectAnnotations,
  uploadJsonResultsForProject,
  extractFramesData, updateProjectAnnotationsAndProjection,
} from '../../api/projects';
import { useProjectionDataToSave } from '../MatchDetails/hooks/useProjectionDataToSave';

function VideoAnnotator() {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const projectionData = useProjectionDataToSave();
  const isProjectApiPending = useSelector(selectProjectApiPending);
  const ref = useRef(null);
  const via = useRef<_via | null>(null);
  const [isPreparingTheProject, setIsPreparingTheProject] = useState(false);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);
  const [savingInterval, setSavingInterval] = useState<
  ReturnType<typeof setInterval> | undefined
  >(undefined);
  const [isSavingManually, setIsSavingManually] = useState(false);
  const [isApplyingAnnotations, setIsApplyingAnnotations] = useState(false);

  useEffect(
    () => () => {
      dispatch(currentProjectSliceActions.reset());
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      clearInterval(savingInterval);
    },
    [savingInterval, dispatch],
  );

  useEffect(() => {
    if (projectId) {
      dispatch(currentProjectGetDetailsThunk(projectId));
    }
  }, [dispatch, projectId]);

  const fetchJson = useCallback(
    async (resultDataPath: string): Promise<object> => {
      const response = await fetch(
        `https://d3kn021kxuyfai.cloudfront.net${resultDataPath}`,
        {
          method: 'GET',
        },
      );
      return response.json();
    },
    [],
  );

  const isProjectReady = useMemo(
    () => project && !['IDLE', 'PROCESSING', 'FAILED'].includes(project.status),
    [project],
  );

  const extractFrameData = useCallback(async () => {
    const videoContainer = document.getElementById(
      'file_content',
    ) as HTMLVideoElement;
    const timestamp = videoContainer?.currentTime ?? 0;

    if (!project || !via.current || !projectId) return;

    const viaEditor = via.current as any;

    setIsLoadingFrame(true);

    const videoPath = project.sourceVideoPath.startsWith('http')
      ? project.sourceVideoPath
      : `https://d3kn021kxuyfai.cloudfront.net${project.sourceVideoPath}`;

    const result = await extractFramesData(projectId, videoPath, timestamp);

    if (result.vimData) {
      const vimData = JSON.parse(result.vimData);
      const keys = Object.keys(vimData);
      keys.forEach((key) => {
        const data = vimData[key];
        viaEditor.d.metadata_add(data.vid, data.z, data.xy, data.av);
      });
    }

    setIsLoadingFrame(false);
  }, [projectId, project]);

  const saveProject = useCallback(async () => {
    if (!via.current || !projectId) return;
    // eslint-disable-next-line no-underscore-dangle
    const result = await via.current.d._GT_get_project_json();
    const projectionBlob = new Blob([JSON.stringify(projectionData)], { type: 'text/json' });
    const annotationsPromise = uploadJsonResultsForProject(
      projectId,
      new File([result.data], result.filename, { type: 'text/json' }),
    );
    const projectionPromise = uploadJsonResultsForProject(
      projectId,
      new File([projectionBlob], 'court_projection', { type: 'text/json' }),
    );

    const annotationsItem = await annotationsPromise;
    const projectionItem = await projectionPromise;

    await updateProjectAnnotationsAndProjection(
      projectId,
      annotationsItem.key,
      projectionItem.key,
    );
  }, [projectId, projectionData]);

  const confirmProject = useCallback(async () => {
    if (!projectId) return;

    setIsApplyingAnnotations(true);
    clearInterval(savingInterval);
    await applyProjectAnnotations(projectId);
    setIsApplyingAnnotations(false);
  }, [projectId, savingInterval]);

  const startSavingInterval = useCallback(async () => {
    if (!via.current || !projectId || savingInterval) return;

    setSavingInterval(setInterval(saveProject, 30000));
  }, [projectId, saveProject, savingInterval]);

  const saveProjectManually = useCallback(async () => {
    setIsSavingManually(true);

    clearInterval(savingInterval);
    await saveProject();

    setIsSavingManually(false);
    startSavingInterval();
  }, [saveProject, savingInterval, startSavingInterval]);

  const prepareProject = useCallback(async () => {
    if (!project || !project.resultDataPath) {
      return;
    }

    setIsPreparingTheProject(true);

    try {
      const projectResult = await fetchJson(
        project.annotationDataPath || project.resultDataPath,
      );

      // eslint-disable-next-line no-underscore-dangle
      via.current?.cp._project_load_on_local_file_read(
        JSON.stringify(projectResult),
      );

      // eslint-disable-next-line no-underscore-dangle
      via.current?.va.file_annotator[0][0]._GT_file_on_attribute_update(
        project.sourceVideoPath.startsWith('http')
          ? project.sourceVideoPath
          : `https://d3kn021kxuyfai.cloudfront.net${project.sourceVideoPath}`,
      );

      startSavingInterval();
    } catch (e) {
      console.error(e);
    } finally {
      setIsPreparingTheProject(false);
    }
  }, [fetchJson, project, startSavingInterval]);

  useEffect(() => {
    const controlPanel = document.getElementById('via_control_panel_container');
    if (!controlPanel && project && isProjectReady && !via.current) {
      const viaContainer = document.getElementById('via_container');
      via.current = new _via(viaContainer, true);
      prepareProject();
    }
  }, [project, prepareProject, isProjectReady]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <VideoAnnotatorSVGDefinitions />

      <button type="button" onClick={() => navigate(-1)}>Back</button>

      <div>
        {isLoadingFrame ? (
          <div>Extracting....</div>
        ) : (
          <Button onClick={extractFrameData}>Exctract Frame Data</Button>
        )}
        <Button onClick={saveProjectManually}>Save annotations</Button>
        <Button onClick={confirmProject}>Apply annotations</Button>
      </div>

      <VideoAnnotatorDefaultViaMarkup ref={ref} />

      <Dialog
        open={
          isProjectApiPending
          || isPreparingTheProject
          || isSavingManually
          || isApplyingAnnotations
        }
      >
        {isProjectApiPending && <DialogTitle>Fetching the project</DialogTitle>}
        {isPreparingTheProject && (
          <DialogTitle>Preparing the project</DialogTitle>
        )}
        {isSavingManually && <DialogTitle>Saving the project</DialogTitle>}
        {isApplyingAnnotations && (
          <DialogTitle>Applying annotations</DialogTitle>
        )}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog open={!isProjectReady}>
        <DialogTitle>Project is not ready</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link to="/">Go back</Link>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VideoAnnotator;
