import React from 'react';

type MSIconBaseProps = React.SVGProps<SVGSVGElement>;

function MSIconBase({ children, ...rest }: MSIconBaseProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width="1.5em"
      height="1.5em"
      fill="currentColor"
      role="img"
      {...rest}
    >
      {children}
    </svg>
  );
}

export default MSIconBase;
