import { Button, Drawer, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import CloseIcon from '@mui/icons-material/Close';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import HistoryIcon from '@mui/icons-material/History';
import classnames from 'classnames';
import { ProjectDetails } from '../../../store/projects/types';
import styles from './ProjectHistory.module.css';
import { getProjectHistory, ProjectHistoryItem } from '../../../api/history';
import AnotherUserAvatar from '../../../components/UserAvatar/AnotherUserAvatar';

type ProjectHistoryProps = {
  open: boolean
  project: ProjectDetails
  onOpenChange: (value: boolean) => void
};

function ProjectHistory({ open, project, onOpenChange }: ProjectHistoryProps) {
  const [historyEvents, setHistoryEvents] = useState<ProjectHistoryItem[]>([]);
  const [isPending, setIsPending] = useState(false);

  const creationEvent = useMemo(() => historyEvents.find((item) => item.eventType === 0), [historyEvents]);

  const getHistory = useCallback(async () => {
    setIsPending(true);
    try {
      const result = await getProjectHistory(project.projectId);
      setHistoryEvents(result);
    } finally {
      setIsPending(false);
    }
  }, [project.projectId]);

  const getDateString = useCallback((date: string) => {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    const dateObj = dayjs(date);
    const dateDiff = dateObj.diff(new Date(), 'days');
    if (dateDiff === 0) {
      return dateObj.fromNow();
    }

    return `${dateObj.format('MMM D')} at ${dateObj.format('LT')}`;
  }, []);

  useEffect(() => {
    if (open) {
      getHistory();
    } else {
      setHistoryEvents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={() => onOpenChange(false)}>
      <div className={styles.content}>
        <div className={styles.head}>
          Version history
          <Button className={styles.closeBtn} onClick={() => onOpenChange(false)}>
            <CloseIcon />
          </Button>
        </div>
        <div className={styles.info}>
          <div className={styles.projectInfo}>
            <h4>
              {project.player1.firstName}
              {' '}
              {project.player1.lastName}
              {' '}
              •
              {' '}
              {project.player2.firstName}
              {' '}
              {project.player2.lastName}
            </h4>
            <p>{project.tournamentName}</p>
            {(project.status !== 'PROCESSING' && project.status !== 'IDLE') && (
              <small>
                <span>
                  Match date:
                  {' '}
                  {new Date(project.tournamentDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </span>
                {' '}
                •
                {' '}
                <span>
                  Created:
                  {' '}
                  {new Date(project.createdAt).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </span>
              </small>
            )}
            {(project.status === 'PROCESSING' || project.status === 'IDLE') && (
              <p style={{ color: 'black' }}>
                {project.processingProgress ? Math.round(project.processingProgress * 100) : 0}
                % Processing...
              </p>
            )}
          </div>
          {creationEvent && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
            <HistoryIcon style={{
              width: 24, height: 24, marginRight: 8, fontSize: 16,
            }}
            />
            Created by
            <div className={styles.userInfo} style={{ marginLeft: 'auto' }}>
              <AnotherUserAvatar
                style={{
                  height: 24, width: 24, fontSize: 10, marginRight: 8,
                }}
                avatarSrc={creationEvent.userAvatarPath}
                name={creationEvent.userFullName}
              />
              {creationEvent.userFullName}
            </div>
          </div>
          )}
        </div>
        <div className={classnames(styles.historyTimeline, 'customScrollbar')}>
          {isPending && <Skeleton variant="rounded" width="100%" height={400} />}
          {!isPending && historyEvents.map((item) => (
            <div className={styles.historyTimelineItem} key={item.createdAt}>
              <h5>{getDateString(item.createdAt)}</h5>
              <h5>{item.eventName}</h5>
              <div className={styles.userInfo}>
                <AnotherUserAvatar
                  style={{
                    height: 24, width: 24, fontSize: 10, marginRight: 8,
                  }}
                  avatarSrc={item.userAvatarPath}
                  name={item.userFullName}
                />
                {item.userFullName}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
}

export default ProjectHistory;
