import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import GTButton from '../../components/GTButton/GTButton';
import GTModal from '../../components/GTModal/GTModal';
import { useAppDispatch } from '../../store';
import GTInput from '../../components/form/GTInput/GTInput';
import GTSelect, { GTSelectOption } from '../../components/form/GTSelect/GTSelect';
import { UserRoles } from '../../store/users/types';
import { emailPattern } from '../../utils/regExtPatterns';
import { inviteUser } from '../../api/user';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';

type SettingsUserEditModal = {
  open: boolean,
  onCancel: () => void
  onSuccess: () => void
};

type UserInviteInputs = {
  firstName: string,
  lastName: string,
  email: string,
  role: UserRoles
};

const roleOptions: GTSelectOption<string, UserRoles>[] = [
  { title: 'Admin', value: 'Admin' },
  { title: 'Editor', value: 'Analyst' },
  { title: 'Viewer', value: 'User' },
];

function SettingsUserInviteModal({ open, onSuccess, onCancel }: SettingsUserEditModal) {
  const {
    register,
    watch,
    formState: { errors },
    getValues,
    handleSubmit,
    control,
    reset,
  } = useForm<UserInviteInputs>({
    defaultValues: {
      role: 'Analyst',
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const isReadyToSubmit = !!watch('email');

  useEffect(() => {
    if (open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleRequestError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      case 'UsernameExistsException':
      case 'User does not exist.': {
        dispatch(appAlertsSliceActions.addAlert({
          alert: {
            id: Date.now().toString(36),
            text: `Invite was already sent to ${getValues('email')}`,
            type: 'error',
          },
        }));
        break;
      }
      default: {
        dispatch(appAlertsSliceActions.addAlert({
          alert: {
            id: Date.now().toString(36),
            text: `The error ${errorMessage} has occured.`,
            type: 'error',
          },
        }));
      }
    }
  }, [dispatch, getValues]);

  const onSubmit: SubmitHandler<UserInviteInputs> = useCallback(async (data) => {
    setIsSubmitting(true);
    try {
      await inviteUser(data);
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: `Invite was sent to ${data.email}`,
          type: 'success',
        },
      }));
      onSuccess();
    } catch (e) {
      handleRequestError((e as { name: string }).name);
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, handleRequestError, onSuccess]);

  return (
    <GTModal
      open={open}
      title="Invite user"
      footer={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <GTButton disabled={isSubmitting} onClick={onCancel} color="secondary">Cancel</GTButton>
          <GTButton disabled={isSubmitting || !isReadyToSubmit} onClick={handleSubmit(onSubmit)} style={{ marginLeft: 8 }} color="primary">Send invite</GTButton>
        </div>
    )}
    >
      <>
        <div style={{
          display: 'flex', alignItems: 'center', gap: 16, width: '100%',
        }}
        >
          <GTInput
            id="firstName"
            label="First Name"
            placeholder="Enter first name"
            style={{ flex: 1 }}
            {...register('firstName')}
          />
          <GTInput
            id="lastName"
            label="Last Name"
            placeholder="Enter last name"
            style={{ flex: 1 }}
            {...register('lastName')}
          />
        </div>
        <GTInput
          id="Email"
          label="Email"
          placeholder="Enter email and select role"
          error={!!errors.email}
          errorMessage={errors.email?.message}
          style={{ flex: 1, marginTop: 24 }}
          {...register('email', {
            required: 'This field is required',
            pattern: { value: emailPattern, message: 'Incorrect email format' },
          })}
          endAdornment={(
            <Controller
              control={control}
              render={({ field }) => (
                <GTSelect
                  id="role"
                  options={roleOptions}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  minimal
                />
              )}
              name="role"
            />
          )}
        />
      </>
    </GTModal>
  );
}

export default SettingsUserInviteModal;
