import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import GTAutocomplete, { GTAutocompleteOption } from '../../components/form/GTAutocomplete/GTAutocomplete';
import GTInput from '../../components/form/GTInput/GTInput';
import GTRadioSelect, { GTRadioSelectOption } from '../../components/form/GTRadioSelect/GTRadioSelect';
import GTSelect, { GTSelectOption } from '../../components/form/GTSelect/GTSelect';
import GTDatePicker from '../../components/form/GTDatePicker/GTDatePicker';
import GTVideoInput from '../../components/form/GTVideoInput/GTVideoInput';
import { MatchFormInputs } from './types';
import { MatchMeasurementSystemType, MatchRoundOfPlay, MatchType } from '../../store/projects/types';

const tournamentOptions: GTAutocompleteOption[] = [
  { title: 'Australian Open', value: 'Australian Open', group: 'Grand Slams' },
  { title: 'Roland Garros', value: 'Roland Garros', group: 'Grand Slams' },
  { title: 'Wimbledon', value: 'Wimbledon', group: 'Grand Slams' },
  { title: 'US Open', value: 'US Open', group: 'Grand Slams' },
  { title: 'Canada Masters', value: 'Canada Masters', group: 'Masters' },
  { title: 'Cincinnati Masters', value: 'Cincinnati Masters', group: 'Masters' },
  { title: 'Indian Wells Masters', value: 'Indian Wells Masters', group: 'Masters' },
  { title: 'Madrid Masters', value: 'Madrid Masters', group: 'Masters' },
  { title: 'Miami Masters', value: 'Miami Masters', group: 'Masters' },
  { title: 'Monte-Carlo Masters', value: 'Monte-Carlo Masters', group: 'Masters' },
  { title: 'Paris Masters', value: 'Paris Masters', group: 'Masters' },
  { title: 'Rome Masters', value: 'Rome Masters', group: 'Masters' },
  { title: 'Shanghai Masters', value: 'Shanghai Masters', group: 'Masters' },
];

const measurementOptions: GTSelectOption[] = [
  { title: 'Metric', value: MatchMeasurementSystemType.METRIC },
  { title: 'Imperial', value: MatchMeasurementSystemType.IMPERIAL },
];

const roundOptions: GTSelectOption[] = [
  { title: '1', value: MatchRoundOfPlay.ROUND_1 },
  { title: '2', value: MatchRoundOfPlay.ROUND_2 },
  { title: '3', value: MatchRoundOfPlay.ROUND_3 },
  { title: '4', value: MatchRoundOfPlay.ROUND_4 },
  { title: '5', value: MatchRoundOfPlay.ROUND_5 },
  { title: 'Quarterfinal', value: MatchRoundOfPlay.QUARTERFINAL },
  { title: 'Semifinal', value: MatchRoundOfPlay.SEMIFINAL },
  { title: 'Final', value: MatchRoundOfPlay.FINAL },
];

const matchTypeOptions: GTRadioSelectOption[] = [
  { title: 'Tournament', value: MatchType.TOURNAMENT },
  { title: 'Qualifier', value: MatchType.QUALIFIER },
  { title: 'League', value: MatchType.LEAGUE },
  { title: 'Exhibition', value: MatchType.EXHIBITION },
  { title: 'Practice', value: MatchType.PRACTICE },
  { title: 'Other', value: MatchType.OTHER },
];

const videoSourceOptions: GTRadioSelectOption[] = [
  { title: 'Link', value: 'Link' },
  { title: 'Video', value: 'Video' },
];

interface MatchCreatorStepMatchProps {
  editMode: boolean
}

function MatchCreatorStepMatch({ editMode }: MatchCreatorStepMatchProps) {
  const { register, control, formState: { errors } } = useFormContext<MatchFormInputs>();

  const videoSourceField = useWatch({
    control,
    name: 'videoSource',
  });

  const isVideoFileSourceModule = useMemo(() => videoSourceField === 'Video', [videoSourceField]);

  return (
    <>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <Controller
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field, fieldState: { error } }) => (
            <GTAutocomplete
              placeholder="Select tournament name"
              options={tournamentOptions}
              id="tournamentName"
              label="Tournament name"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!error}
              errorMessage={error?.message}
            />
          )}
          name="tournamentName"
        />
        <Controller
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field, fieldState: { error } }) => (
            <GTDatePicker
              id="tournamentDate"
              label="Tournament date"
              selected={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!error}
              errorMessage={error?.message}
            />
          )}
          name="tournamentDate"
        />
      </div>
      <GTInput id="matchLocation" label="Location" placeholder="Enter city, country, state" {...register('location')} />
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <Controller
          control={control}
          render={({ field }) => (
            <GTSelect
              id="matchMeasurementSystem"
              options={measurementOptions}
              placeholder="Select system"
              label="Measurement system"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
            />
          )}
          name="measurementSystem"
        />
        <Controller
          control={control}
          render={({ field }) => (
            <GTSelect
              id="matchRoundOfPlay"
              options={roundOptions}
              placeholder="Select Round of Play"
              label="Round of play"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
            />
          )}
          name="roundOfPlay"
        />
      </div>
      <GTRadioSelect registerProps={register('matchType')} options={matchTypeOptions} label="Match type" />
      {!editMode && (
      <>
        <GTRadioSelect registerProps={register('videoSource')} options={videoSourceOptions} label="Video of the match" />
        {!isVideoFileSourceModule && (
        <GTInput
          placeholder="Enter view URL (S3 video link)"
          error={!!errors.videoLink}
          errorMessage={errors.videoLink?.message}
          {...register('videoLink', {
            required: {
              value: videoSourceField === 'Link',
              message: 'Required',
            },
            pattern: {
              value: /^s3:\/\/.*\.mp4$/,
              message: 'Should be a valid S3 video link (s3://....mp4)',
            },
          })}
        />
        )}
        {isVideoFileSourceModule && (
        <GTVideoInput
          registerProps={register(
            'videoFile',
            {
              required: {
                value: videoSourceField === 'Video',
                message: 'Required',
              },
            },
          )}
          error={!!errors.videoFile}
          errorMessage={errors.videoFile?.message}
        />
        )}
      </>
      )}
    </>
  );
}

export default MatchCreatorStepMatch;
