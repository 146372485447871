import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppAlert } from './types';

const initialState: { alerts: AppAlert[] } = {
  alerts: [],
};

const appAlertsSlice = createSlice({
  name: 'appAlerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<{ alert: AppAlert }>) => ({ ...state, alerts: [...state.alerts, action.payload.alert] }),
    removeAlert: (state, action: PayloadAction<{ alertId: AppAlert['id'] }>) => {
      const newAlerts = state.alerts.filter((alert) => alert.id !== action.payload.alertId);
      return { ...state, alerts: newAlerts };
    },
    reset: () => ({ ...initialState }),
  },
  selectors: {
    selectAlerts: (state) => state.alerts,
  },
});

export const { selectAlerts } = appAlertsSlice.selectors;

export const appAlertsSliceActions = appAlertsSlice.actions;

export default appAlertsSlice.reducer;
