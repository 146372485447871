import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { getUser } from '../../api/user';
import { User } from '../users/types';
import { RootState } from '../index';

export const currentUserGetDetailsThunk = createAsyncThunk<User>(
  'currentUser/getDetails',
  async (_, { getState }) => {
    const state = getState() as RootState;
    if (state.currentUser.user?.id) {
      return getUser(state.currentUser.user.id as string);
    }
    const cognitoUser = await fetchUserAttributes();
    return getUser(cognitoUser.sub as string);
  },
);
