import { useForm } from 'react-hook-form';
import {
  useContext, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import GTButton from '../../../components/GTButton/GTButton';
import unreturnableBallSrc from '../../MatchDetails/assets/unreturnable_ball_event.svg';
import forcingBallSrc from '../../MatchDetails/assets/forcing_ball_event.svg';
import forcedServeBallSrc from '../../MatchDetails/assets/forced_serve_ball_event.svg';
import unforcedBallSrc from '../../MatchDetails/assets/unforced_ball_event.svg';
import otherBallSrc from '../../MatchDetails/assets/other_ball_event.svg';
import GTRadioSelect, { GTRadioSelectOption } from '../../../components/form/GTRadioSelect/GTRadioSelect';
import { MatchReportFiltersShotKind, MatchReportServingFiltersFormFields } from './types';
import {
  MatchDetailsBallFiltersShotTypeValues,
  MatchDetailsBallFiltersSituationValues,
} from '../../MatchDetails/Filters/types/BallFiltersTypes';
import MatchReportContext from './context';

const serveOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: '1st serve', value: '1st serve' },
  { title: '2nd serve', value: '2nd serve' },
];

const courtOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Deuce Court', value: MatchDetailsBallFiltersSituationValues.deuceCourt },
  { title: 'Ad Court', value: MatchDetailsBallFiltersSituationValues.adCourt },
];

const shotTypeOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  {
    title: 'Topspin',
    value: MatchDetailsBallFiltersShotTypeValues.s_topspin,
  },
  {
    title: 'Flat',
    value: MatchDetailsBallFiltersShotTypeValues.s_flat,
  },
  {
    title: 'Slice',
    value: MatchDetailsBallFiltersShotTypeValues.s_slice,
  },
  {
    title: 'Kick',
    value: MatchDetailsBallFiltersShotTypeValues.s_kick,
  },
  {
    title: 'Slice Kick',
    value: MatchDetailsBallFiltersShotTypeValues.s_slicekick,
  },
  {
    title: 'Sidespin',
    value: MatchDetailsBallFiltersShotTypeValues.s_sidespin,
  },
];

const gamePointOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Player\'s Game Point', value: 'player_game_point' },
  { title: 'Opponent\'s Break Point', value: 'opponent_game_point' },
];

const winnerOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Opponnent\'s Winner', value: 'opponent_winner' },
  { title: 'Opponnent\'s Unforced Error', value: 'opponent_unforced_error' },
];

const scoreOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Score 30:30', value: 'score_30_30' },
  { title: 'Score 40:40', value: 'score_40_40' },
];

const pointOptions: GTRadioSelectOption[] = [
  { title: 'All', value: '' },
  { title: 'Point Won', value: 'point_won' },
  { title: 'Point Lost', value: 'point_lost' },
];

const MatchReportFiltersServing = forwardRef((_, ref) => {
  const context = useContext(MatchReportContext);
  const methods = useForm<MatchReportServingFiltersFormFields>({
    defaultValues: context.filtersServing,
  });

  useImperativeHandle(ref, () => ({
    reset: () => methods.reset(),
  }));

  useEffect(() => {
    methods.watch(() => context.setFiltersServing(methods.getValues()));
  }, [context, methods]);

  const shotKindValue = methods.watch('shotKind');

  return (
    <>
      <div style={{ display: 'flex', gap: 8 }}>
        <GTButton
          color={`${!shotKindValue ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', '')}
        >
          All
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.UNRETURNABLE ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.UNRETURNABLE)}
        >
          <img src={unreturnableBallSrc} alt="" style={{ marginRight: 8 }} />
          Ace/Unreturnable
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.FORCING ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.FORCING)}
        >
          <img src={forcingBallSrc} alt="" style={{ marginRight: 8 }} />
          Forcing
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.FORCED_ERROR ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.FORCED_ERROR)}
        >
          <img src={forcedServeBallSrc} alt="" style={{ marginRight: 8 }} />
          Fault
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.UNFORCED_ERROR ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.UNFORCED_ERROR)}
        >
          <img src={unforcedBallSrc} alt="" style={{ marginRight: 8 }} />
          Double Fault
        </GTButton>
        <GTButton
          color={`${shotKindValue === MatchReportFiltersShotKind.OTHER ? 'primary' : 'secondary'}`}
          onClick={() => methods.setValue('shotKind', MatchReportFiltersShotKind.OTHER)}
        >
          <img src={otherBallSrc} alt="" style={{ marginRight: 8 }} />
          Other
        </GTButton>
      </div>
      <GTRadioSelect condense registerProps={methods.register('serveType')} options={serveOptions} />
      <GTRadioSelect condense registerProps={methods.register('courtType')} options={courtOptions} />
      <GTRadioSelect condense registerProps={methods.register('shotType')} options={shotTypeOptions} />
      <GTRadioSelect condense registerProps={methods.register('gamePoint')} options={gamePointOptions} />
      <GTRadioSelect condense registerProps={methods.register('winner')} options={winnerOptions} />
      <GTRadioSelect condense registerProps={methods.register('score')} options={scoreOptions} />
      <GTRadioSelect condense registerProps={methods.register('point')} options={pointOptions} />
    </>
  );
});

export default MatchReportFiltersServing;
