import { useContext, useEffect, useRef } from 'react';
import styles from './MatchAnnotation.module.css';

import VideoAnnotatorSVGDefinitions from './Annotator/VideoAnnotatorSVGDefinitions';
import VideoAnnotatorDefaultViaMarkup from './Annotator/VideoAnnotatorDefaultViaMarkup';
import GTProject from './Types/GTProject';
import MatchDetailsContext from '../MatchCreator/MatchDetailsContext';
import { currentProjectSliceActions } from '../../store/currentProject/slice';
import { useAppDispatch } from '../../store';
import AnnotationService from '../../utils/services/AnnotationService';

function MatchAnnotation({ gtProject }: { gtProject: GTProject }) {
  const ref = useRef(null);
  const via = useRef<_via | null>(null);
  const context = useContext(MatchDetailsContext);
  const setVideoElementInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const videoElement = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    // to remove the video from the browser [GT-436]
    setVideoElementInterval.current = setInterval(() => {
      const element = document.querySelector('video');
      if (element && setVideoElementInterval.current) {
        videoElement.current = element;
        clearInterval(setVideoElementInterval.current);
      }
    }, 3000);

    return () => {
      if (videoElement.current) {
        videoElement.current.src = '';
        videoElement.current.load();
      }
    };
  }, []);

  const dispatch = useAppDispatch();

  const isDataOnCourt = () => AnnotationService.current.selectedMetadata?.empty === false;

  const initializeAnnotator = (via: any) => {
    const resetCourtState = () => {
      const state = AnnotationService.current.currentFrameProjectionState;
      dispatch(currentProjectSliceActions.setSelectedFrameState({ value: state }));
      dispatch(currentProjectSliceActions.setExtractFramesActive({ value: !isDataOnCourt() }));
    };

    AnnotationService.initialize(via);
    AnnotationService.current.onTimeUpdated = (timestamp: number) => {
      dispatch(currentProjectSliceActions.setCurrentTime({ time: timestamp }));
      resetCourtState();
    };

    AnnotationService.current.onMetadataChanged = async () => {
      const timestamp = AnnotationService.current.selectedTimestamp;
      const metadata = AnnotationService.current.selectedMetadata;

      const state = AnnotationService.current.currentFrameProjectionState;
      dispatch(currentProjectSliceActions.setSelectedFrameState({ value: state }));

      if (metadata) {
        const data = await AnnotationService.current.fetchMetadataForCurrentTime(timestamp, metadata);
        dispatch(currentProjectSliceActions.appendProjectionData({
          data,
        }));
      }

      resetCourtState();
    };
  };

  useEffect(() => {
    const controlPanel = document.getElementById('via_control_panel_container');

    if (!controlPanel && !via.current) {
      const viaContainer = document.getElementById('via_container');
      via.current = new _via(viaContainer, false);

      // eslint-disable-next-line no-underscore-dangle
      via.current?.cp._project_load_on_local_file_read(
        JSON.stringify(gtProject.annotations),
      );

      // eslint-disable-next-line no-underscore-dangle
      via.current?.va.file_annotator[0][0]._GT_file_on_attribute_update(
        gtProject.project.sourceVideoPath.startsWith('http')
          ? gtProject.project.sourceVideoPath
          : `https://d3kn021kxuyfai.cloudfront.net${gtProject.project.sourceVideoPath}`,
      );

      if (via.current?.va.file_annotator[0][0]) {
        const p1Name = `${gtProject.project.player1.firstName} ${gtProject.project.player1.lastName}`;
        const p2Name = `${gtProject.project.player2.firstName} ${gtProject.project.player2.lastName}`;
        via.current.va.playerTopName = p1Name;
        via.current.va.playerBottomName = p2Name;
      }
    }

    if (via.current) {
      initializeAnnotator(via.current);
    }

    // Deinit
    return () => {
      AnnotationService.current.resetViaAnnotator();
    };
  }, [dispatch, gtProject.annotations, gtProject.project.sourceVideoPath]);

  useEffect(() => {
    if (context.setVia && via.current && !context.via) {
      context.setVia(via.current);
    }
  }, [context.setVia, context.via, context]);

  return (
    <div className={styles.container} id="via-container">
      <VideoAnnotatorSVGDefinitions />
      <VideoAnnotatorDefaultViaMarkup ref={ref} />
    </div>
  );
}

export default MatchAnnotation;
