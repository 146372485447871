import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'aws-amplify/auth';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import styles from './App.module.css';
import LogoUrl from './assets/Logo.svg';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import {
  AUTH_BASE_ROUTE, BASE_ROUTE, SETTINGS_ACCOUNT_ROUTE, SETTINGS_ROUTE,
} from '../../router/routesConstants';
import { selectAlerts, appAlertsSliceActions } from '../../store/appAlerts/slice';
import GTSnackbarAlert from '../../components/GTSnackbarAlert/GTSnackbarAlert';
import GTButton from '../../components/GTButton/GTButton';
import SettingsIcon from '../../components/MaterialSymbolsIcons/SettingsIcon';
import { selectCurrentUser } from '../../store/currentUser/slice';

function AppAlerts() {
  const alerts = useSelector(selectAlerts);
  const dispatch = useDispatch();

  const closeAlert = useCallback((alertId: string) => {
    dispatch(appAlertsSliceActions.removeAlert({ alertId }));
  }, [dispatch]);

  return (
    <>
      {alerts.map((item) => <GTSnackbarAlert key={item.id} closeAlert={closeAlert} id={item.id} text={item.text} type={item.type} />)}
    </>
  );
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  const isBaseLocation = useMemo(() => location.pathname === BASE_ROUTE, [location.pathname]);
  const onBack = useCallback(() => navigate(BASE_ROUTE), [navigate]);

  const handleAvatarClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onLogOut = async () => {
    await signOut({ global: true });
    navigate(AUTH_BASE_ROUTE);
  };

  return (
    <div className={styles.wrap}>
      <AppAlerts />
      <nav className={styles.toolbar}>
        {!isBaseLocation ? (
          <GTButton onClick={onBack} color="secondary" style={{ marginRight: 'auto', padding: '12px 16px' }}>
            <KeyboardArrowLeftIcon style={{ marginRight: 8 }} />
            Back
          </GTButton>
        ) : <img src={LogoUrl} alt="Gottatennis" />}

        <Button onClick={handleAvatarClick} sx={{ padding: 0, minWidth: 40 }}>
          <UserAvatar />
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          style={{ fontWeight: 500 }}
        >
          <MenuItem sx={{ fontWeight: 500 }} onClick={() => navigate(SETTINGS_ACCOUNT_ROUTE)}>
            <UserAvatar style={{
              marginRight: 8, height: 24, width: 24, fontSize: 14,
            }}
            />
            {currentUser?.email || ''}
          </MenuItem>
          <MenuItem sx={{ fontWeight: 500 }} onClick={() => navigate(SETTINGS_ROUTE)}>
            <SettingsIcon style={{ marginRight: 8 }} />
            Settings
          </MenuItem>
          <MenuItem sx={{ fontWeight: 500, width: 220, borderTop: '1px solid #E3E3E8' }} onClick={onLogOut}>
            <LogoutIcon style={{ marginRight: 8 }} />
            Sign Out
          </MenuItem>
        </Menu>
      </nav>
      <main className={styles.content}>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
