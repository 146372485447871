import { createContext } from 'react';
import { User } from '../../store/users/types';

export interface SettingsUserContextType {
  onOpenActivityLog: (user: User) => void
}

const SettingsUserContext = createContext<SettingsUserContextType>({
  onOpenActivityLog: (user) => console.log(user),
});

export default SettingsUserContext;
