import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { resetPassword } from 'aws-amplify/auth';
import { InputLabel } from '@mui/material';
import styles from './Settings.module.css';
import GTInput from '../../components/form/GTInput/GTInput';
import { selectCurrentUser } from '../../store/currentUser/slice';
import GTButton from '../../components/GTButton/GTButton';
import GTModal from '../../components/GTModal/GTModal';
import SettingsPasswordResetModal from './SettingsPasswordResetModal';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { uploadFile } from '../../api/projects';
import { updateUser } from '../../api/user';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import { useAppDispatch } from '../../store';
import { currentUserGetDetailsThunk } from '../../store/currentUser/thunks';

interface SettingsAccountFormInputs {
  photo: FileList | null,
  name: string,
  family_name: string,
  email: string
}

const getBase64 = (file: File): Promise<string | null> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

function SettingsAccount() {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [isPasswordChangeConfirmModalOpen, setIsPasswordChangeConfirmModalOpen] = useState(false);
  const [isInitiatingPasswordReset, setIsInitiatingPasswordReset] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [tempAvatarSrc, setTempAvatarSrc] = useState('');
  const [isProfileSubmitting, setIsProfileSubmitting] = useState(false);
  const methods = useForm<SettingsAccountFormInputs>({
    defaultValues: {
      photo: null,
      name: currentUser?.firstName || '',
      family_name: currentUser?.lastNAme || '',
      email: currentUser?.email || '',
    },
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const photoFileList = methods.watch('photo');

  const handleFileInputClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const getImgSrc = useCallback(async () => {
    if (!photoFileList?.length) {
      setTempAvatarSrc('');
      return;
    }

    const str = await getBase64(photoFileList[0]);
    setTempAvatarSrc(str || '');
  }, [photoFileList]);

  useEffect(() => {
    getImgSrc();
  }, [getImgSrc, photoFileList]);

  const onPasswordChangeInit = useCallback(async () => {
    if (!currentUser?.email) return;
    setIsInitiatingPasswordReset(true);
    await resetPassword({ username: currentUser.email });
    setIsInitiatingPasswordReset(false);
    setIsPasswordChangeConfirmModalOpen(false);
    setIsPasswordChangeModalOpen(true);
  }, [currentUser]);

  const onSubmit: SubmitHandler<SettingsAccountFormInputs> = useCallback(async (data) => {
    if (!currentUser?.id) {
      return;
    }
    setIsProfileSubmitting(true);
    let avatarPath: string = '';
    if (data.photo?.length) {
      const uploadPath = `users/${currentUser.id}/avatar.png`;
      const photoUploadTask = uploadFile(data.photo[0], uploadPath);
      await photoUploadTask.result;
      avatarPath = `/public/${uploadPath}`;
    }
    try {
      await updateUser(
        currentUser.id,
        {
          firstName: data.name,
          lastName: data.family_name,
          avatarPath: avatarPath || currentUser.avatarPath || '',
          role: currentUser.role || 'Admin',
        },
      );
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Profile was updated',
          type: 'success',
        },
      }));
      await dispatch(currentUserGetDetailsThunk());
      methods.reset(methods.watch(), { keepValues: true, keepDirty: false, keepDefaultValues: false });
    } finally {
      methods.setValue('photo', null);
      setIsProfileSubmitting(false);
    }
  }, [currentUser, dispatch, methods]);

  return (
    <div>
      <div className={styles.box}>
        <div className={styles.boxHeader}>
          My account
          <div style={{ opacity: methods.formState.isDirty ? 1 : 0, marginLeft: 'auto' }}>
            <GTButton disabled={!methods.formState.isDirty || isProfileSubmitting} color="secondary" onClick={() => methods.reset()}>
              Cancel
            </GTButton>
            <GTButton disabled={!methods.formState.isDirty || isProfileSubmitting} style={{ marginLeft: 8 }} color="primary" onClick={methods.handleSubmit(onSubmit)}>
              Save changes
            </GTButton>
          </div>
        </div>
        <div className={styles.boxContent}>
          <div className={styles.accountForm}>
            <div style={{
              display: 'flex', alignItems: 'center', gap: 16, width: '100%',
            }}
            >
              <UserAvatar
                onClick={handleFileInputClick}
                avatarSrc={tempAvatarSrc}
                style={{
                  width: 80, height: 80, fontSize: 24, cursor: 'pointer',
                }}
              />
              <div>
                <InputLabel classes={{ root: styles.label }} htmlFor="profile_image_file">Profile image</InputLabel>

                <GTButton color="secondary" onClick={handleFileInputClick}>
                  Upload image
                </GTButton>
                <input
                  id="profile_image_file"
                  type="file"
                  hidden
                  {...methods.register('photo')}
                  ref={(e) => {
                    methods.register('photo').ref(e);
                    fileInputRef.current = e;
                  }}
                />
              </div>
            </div>
            <div style={{
              display: 'flex', alignItems: 'center', gap: 16, width: '100%',
            }}
            >
              <GTInput
                id="firstName"
                label="First Name"
                placeholder="Enter first name"
                style={{ flex: 1 }}
                {...methods.register('name')}
              />
              <GTInput
                id="lastName"
                label="Last Name"
                placeholder="Enter last name"
                style={{ flex: 1 }}
                {...methods.register('family_name')}
              />
            </div>
            <GTInput
              id="email"
              label="Email"
              placeholder="Enter email"
              {...methods.register('email')}
              disabled
            />
          </div>

        </div>
      </div>
      <div className={styles.box} style={{ marginTop: 16 }}>
        <div className={styles.boxHeader}>
          Password
          <GTButton
            onClick={() => setIsPasswordChangeConfirmModalOpen(true)}
            style={{ marginLeft: 'auto' }}
            color="secondary"
          >
            Change
          </GTButton>
        </div>
      </div>
      <GTModal
        open={isPasswordChangeConfirmModalOpen}
        title="Change Password"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton disabled={isInitiatingPasswordReset} onClick={() => setIsPasswordChangeConfirmModalOpen(false)} color="secondary">Cancel</GTButton>
            <GTButton disabled={isInitiatingPasswordReset} onClick={onPasswordChangeInit} style={{ marginLeft: 8 }} color="primary">Continue</GTButton>
          </div>
        )}
      >
        <p>
          Are you sure you want to change password?
          {' '}
          <br />
          Instructions to reset your password will be sent to
          {' '}
          {currentUser?.email || ''}
          .
        </p>
      </GTModal>
      <SettingsPasswordResetModal
        open={isPasswordChangeModalOpen}
        onSuccess={() => setIsPasswordChangeModalOpen(false)}
        onCancel={() => setIsPasswordChangeModalOpen(false)}
      />
    </div>
  );
}

export default SettingsAccount;
