import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './MatchDetailsFilter.module.css';
import AnnotationService from '../../../utils/services/AnnotationService';

import { selectProject } from '../../../store/currentProject/slice';

interface MatchDetailsEventSetFilterProps {
  onLastOption: () => void
}

function MatchDetailsEventSetFilter({ onLastOption }: MatchDetailsEventSetFilterProps) {
  const initialGameSet = AnnotationService.current.selectedGameSetEventMetadata?.set

  const [isValueSetManually, setIsValueSetManually] = useState<boolean>(false);
  const project = useSelector(selectProject);
  const [playerValue, setPlayerValue] = useState<number | null>(initialGameSet ?? null);
  const playerOptions = useMemo(() => {
    const options: { label: string, value: number }[] = [];
    if (project) {

      for (let index = 0; index < 5; index++) {
        const gameSet = index + 1
        options.push({
          label: `Set ${gameSet}`,
          value: gameSet
        })
      }
    }

    return options;
  }, [project]);

  const onValueOptionClick = useCallback((value: number) => {
    setPlayerValue(value);
    setIsValueSetManually(true);
  }, []);

  useEffect(() => {
    if (playerValue && isValueSetManually) {
      AnnotationService.current.updateSelectedItemMetadata({ set: playerValue });
      onLastOption();
      setIsValueSetManually(false);
    }
  }, [isValueSetManually, onLastOption, playerValue]);

  return (
    <div className={styles.filterWrap}>
      <div className={styles.filterOptionsWrap}>
        {playerOptions.map((option) => (
          <button
            type="button"
            className={classnames(styles.filterOptionBtn, {
              [styles.active]: playerValue === option.value,
            })}
            onClick={() => onValueOptionClick(option.value)}
            key={`${option.label}-${String(option.value)}`}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MatchDetailsEventSetFilter;
