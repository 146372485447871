import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import GTButton from '../../components/GTButton/GTButton';
import styles from './MatchCreator.module.css';
import MatchCreatorStepMatch from './MatchCreatorStepMatch';
import { MatchFormInputs, MatchCreatorStep } from './types';
import MatchCreatorStepConditions from './MatchCreatorStepConditions';
import MatchCreatorStepScoring from './MatchCreatorStepScoring';
import MatchCreatorStepPlayers from './MatchCreatorStepPlayers';
import MatchCreatorStepper from './MatchCreatorStepper';
import MatchCreatorProcessing from './MatchCreatorProcessing';
import GTModal from '../../components/GTModal/GTModal';
import { ProjectDetails } from '../../store/projects/types';
import MatchCreatorEditingProcess from './MatchCreatorEditingProcess';

interface MatchCreatorProps {
  open: boolean,
  onOpenChange: (value: boolean) => void
  project?: ProjectDetails
  onEditDoneCallback: () => Promise<void>
  onCreationDoneCallback?: () => Promise<void>
}

function MatchCreator({
  open, onOpenChange, project, onEditDoneCallback, onCreationDoneCallback,
}: MatchCreatorProps) {
  const [currentStep, setCurrentStep] = useState<MatchCreatorStep>('MATCH');
  const [isMatchStepReady, setIsMatchStepReady] = useState(false);
  const [isPlayersStepReady, setIsPlayersStepReady] = useState(false);
  const [isScoringStepReady, setIsScoringStepReady] = useState(false);
  const [isActionModalOpened, setIsActionModalOpened] = useState(false);
  const [isDiscardModalOpened, setIsDiscardModalOpened] = useState(false);

  const methods = useForm<MatchFormInputs>({
    mode: 'onChange',
    defaultValues: {
      tournamentName: '',
      tournamentDate: '',
      location: '',
      measurementSystem: '',
      roundOfPlay: '',
      videoSource: 'Link',
      videoLink: '',
      videoFile: null,
      scoringSystem: '',
      player1FirstName: '',
      player1LastName: '',
      player1Gender: undefined,
      player1DominantHand: undefined,
      player1AgeGroup: undefined,
      player1SkillLevel: undefined,
      player2FirstName: '',
      player2LastName: '',
      player2Gender: undefined,
      player2DominantHand: undefined,
      player2AgeGroup: undefined,
      player2SkillLevel: undefined,
      timeOfDay: undefined,
      elevation: undefined,
      venue: undefined,
      coverType: undefined,
      wind: undefined,
      temperature: undefined,
    },
  });

  const isEditMode = useMemo(() => !!project, [project]);
  const isDirty = useMemo(
    // Need to check array keys bc original isDirty field is not reset after the reset
    () => !!Object.keys(methods.formState.dirtyFields).length,
    [methods.formState],
  );

  useEffect(() => {
    if (open && project) {
      methods.reset({
        tournamentName: project.tournamentName,
        tournamentDate: project.tournamentDate,
        location: project.location,
        measurementSystem: project.measurementSystem,
        roundOfPlay: project.roundOfPlay || '',
        scoringSystem: project.scoringSystem,
        player1FirstName: project.player1.firstName,
        player1LastName: project.player1.lastName,
        player1Gender: project.player1.gender,
        player1DominantHand: project.player1.dominantHand,
        player1AgeGroup: project.player1.ageGroup,
        player1SkillLevel: project.player1.skillLevel,
        player2FirstName: project.player2.firstName,
        player2LastName: project.player2.lastName,
        player2Gender: project.player2.gender,
        player2DominantHand: project.player2.dominantHand,
        player2AgeGroup: project.player2.ageGroup,
        player2SkillLevel: project.player2.skillLevel,
        timeOfDay: project.timeOfDay,
        elevation: project.elevation,
        venue: project.venue,
        coverType: project.coverType,
        wind: project.wind,
        temperature: project.temperature,
      }, { keepDirty: false });
    }
  }, [methods, project, open]);

  const discardModalText = useMemo(() => {
    if (isEditMode) {
      return {
        title: 'Discard match editing',
        text: 'You are going to discard match details. Details will reset. Are sure you want to discard match editing?',
      };
    }

    return {
      title: 'Discard match creation',
      text: 'You are going to discard match details. Details will reset. Are sure you want to discard match creation?',
    };
  }, [isEditMode]);

  const videoSourceField = useWatch({
    control: methods.control,
    name: 'videoSource',
  });

  const tournamentNameState = methods.getFieldState('tournamentName', methods.formState);
  const tournamentDateState = methods.getFieldState('tournamentDate', methods.formState);
  const videoLinkState = methods.getFieldState('videoLink', methods.formState);
  const videoFileState = methods.getFieldState('videoFile', methods.formState);

  useEffect(() => {
    const tournamentNameValid = !tournamentNameState.error && (isEditMode || tournamentNameState.isDirty);
    const tournamentDateValid = !tournamentDateState.error && (isEditMode || tournamentDateState.isDirty);
    let videoNotEmpty = true;

    if (!isEditMode) {
      if (videoSourceField === 'Link') {
        videoNotEmpty = !videoLinkState.error && (isEditMode || videoLinkState.isDirty);
      } else {
        videoNotEmpty = !videoFileState.error && (isEditMode || videoFileState.isDirty);
      }
    }

    setIsMatchStepReady((tournamentNameValid && tournamentDateValid && videoNotEmpty));
  }, [isEditMode, videoSourceField, videoLinkState, videoFileState, tournamentNameState, tournamentDateState]);

  const p1FNState = methods.getFieldState('player1FirstName', methods.formState);
  const p1LNState = methods.getFieldState('player1LastName', methods.formState);
  const p2FNState = methods.getFieldState('player2FirstName', methods.formState);
  const p2LNState = methods.getFieldState('player2LastName', methods.formState);

  useEffect(() => {
    const isP1FNValid = !p1FNState.error && (isEditMode || p1FNState.isDirty);
    const isP1LNValid = !p1LNState.error && (isEditMode || p1LNState.isDirty);
    const isP2FNValid = !p2FNState.error && (isEditMode || p2FNState.isDirty);
    const isP2LNValid = !p2LNState.error && (isEditMode || p2LNState.isDirty);
    setIsPlayersStepReady(isP1FNValid && isP1LNValid && isP2LNValid && isP2FNValid);
  }, [isEditMode, p1FNState, p1LNState, p2FNState, p2LNState]);

  const scoringSystemState = methods.getFieldState('scoringSystem', methods.formState);

  useEffect(() => {
    const isScoringSystemValid = !scoringSystemState.error && (isEditMode || scoringSystemState.isDirty);
    setIsScoringStepReady(isScoringSystemValid);
  }, [isEditMode, scoringSystemState]);

  const isContinueAvailable = useMemo(() => {
    switch (currentStep) {
      case 'MATCH': {
        return isMatchStepReady;
      }
      case 'PLAYERS': {
        return isPlayersStepReady;
      }
      case 'SCORING': {
        return isScoringStepReady;
      }
      default: return false;
    }
  }, [currentStep, isMatchStepReady, isPlayersStepReady, isScoringStepReady]);

  const isSubmitAvailable = useMemo(
    () => isMatchStepReady && isPlayersStepReady && isScoringStepReady && isDirty,
    [isDirty, isMatchStepReady, isPlayersStepReady, isScoringStepReady],
  );

  const onContinueClick = useCallback(() => {
    switch (currentStep) {
      case 'MATCH': {
        if (isMatchStepReady) {
          setCurrentStep('PLAYERS');
        }
        break;
      }
      case 'PLAYERS': {
        if (isPlayersStepReady) {
          setCurrentStep('SCORING');
        }
        break;
      }
      case 'SCORING': {
        if (isScoringStepReady) {
          setCurrentStep('CONDITIONS');
        }
        break;
      }
      default:
    }
  }, [currentStep, isMatchStepReady, isPlayersStepReady, isScoringStepReady]);

  const resetCreator = useCallback(() => {
    methods.reset();
    setCurrentStep('MATCH');
    setIsDiscardModalOpened(false);
    setIsActionModalOpened(false);
  }, [methods]);

  const onClose = useCallback(() => {
    onOpenChange(false);
    resetCreator();
  }, [onOpenChange, resetCreator]);

  const onPreCloseCheck = useCallback(() => {
    if (isDirty) {
      setIsDiscardModalOpened(true);
    } else {
      onClose();
    }
  }, [isDirty, onClose]);

  const onSubmit = useCallback(() => { setIsActionModalOpened(true); }, []);

  const onCreationDone = useCallback(async () => {
    if (onCreationDoneCallback) {
      await onCreationDoneCallback();
    }
    setIsActionModalOpened(false);
    onClose();
  }, [onClose, onCreationDoneCallback]);

  const onEditingDone = useCallback(async () => {
    await onEditDoneCallback();
    setIsActionModalOpened(false);
    onClose();
  }, [onClose, onEditDoneCallback]);

  const onCreationFailed = useCallback(async () => {
    setIsActionModalOpened(false);
  }, []);

  const onCreationDiscarded = useCallback(async () => {
    setIsActionModalOpened(false);
    onClose();
  }, [onClose]);

  return (
    <Drawer anchor="right" open={open} onClose={onPreCloseCheck}>
      <FormProvider {...methods}>
        <div className={styles.content}>
          <div className={styles.head}>
            {isEditMode ? 'Edit match details' : 'Add new match'}
            <Button className={styles.closeBtn} onClick={onPreCloseCheck}>
              <CloseIcon />
            </Button>
          </div>

          <MatchCreatorStepper
            isMatchReady={isMatchStepReady}
            isPlayersReady={isPlayersStepReady}
            isScoringReady={isScoringStepReady}
            currentStep={currentStep}
            onPointClick={setCurrentStep}
          />
          <form className={styles.form} onSubmit={onSubmit}>
            {currentStep === 'MATCH' && <MatchCreatorStepMatch editMode={isEditMode} />}
            {currentStep === 'PLAYERS' && <MatchCreatorStepPlayers />}
            {currentStep === 'SCORING' && <MatchCreatorStepScoring editMode={isEditMode} />}
            {currentStep === 'CONDITIONS' && <MatchCreatorStepConditions />}
          </form>

          <div className={styles.footer}>
            <GTButton onClick={onPreCloseCheck} style={{ marginRight: 8 }} color="secondary">Discard</GTButton>
            {currentStep !== 'CONDITIONS' && <GTButton disabled={!isContinueAvailable} onClick={onContinueClick} color="primary">Continue</GTButton>}
            {currentStep === 'CONDITIONS' && (
            <GTButton type="submit" disabled={!isSubmitAvailable} onClick={onSubmit} color="primary">
              {isEditMode ? 'Save changes' : 'Save and Process'}
            </GTButton>
            )}
          </div>
        </div>

        {!isEditMode && (
        <MatchCreatorProcessing
          open={isActionModalOpened}
          onDone={onCreationDone}
          onDiscard={onCreationDiscarded}
          onError={onCreationFailed}
        />
        )}

        {isEditMode && project && (
        <MatchCreatorEditingProcess
          projectId={project.projectId}
          open={isActionModalOpened}
          onDone={onEditingDone}
          onDiscard={onCreationDiscarded}
          onError={onCreationFailed}
        />
        )}

        <GTModal
          open={isDiscardModalOpened}
          title={discardModalText.title}
          footer={(
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GTButton onClick={() => setIsDiscardModalOpened(false)} color="secondary">Cancel</GTButton>
              <GTButton onClick={onClose} style={{ marginLeft: 8 }} color="warning">Discard</GTButton>
            </div>
            )}
        >
          <p>{discardModalText.text}</p>
        </GTModal>
      </FormProvider>
    </Drawer>
  );
}

export default MatchCreator;
