import { forwardRef } from 'react';

const VideoAnnotatorDefaultViaMarkup = forwardRef<HTMLDivElement, unknown>((_, ref) => (
  <>
    <div ref={ref} id="via_page_container">
      {/* <div data-pageid="page_import_export" className="via_page">
        <div className="toolbar"><span onClick={() => _via_util_page_hide()} className="text_button">&times;</span></div>
        <h2>Export</h2>
        <ul>
          <li>
            Select Export Format:
            <select id="via_page_export_format">
              <option value="via3_csv">VIA3 (CSV)</option>
              <option value="temporal_segments_csv">Only Temporal Segments as CSV</option>
            </select>
          </li>
        </ul>
        <h2>Import</h2>
        <ul>
          <li>
            VIA Shared Project:
            <input
              id="via_page_import_pid"
              type="text"
              placeholder="e.g. 71578187-3cd3-45d0-8198-7c441fbc06af"
              style={{ width: '25em' }}
            />
          </li>
          <li>
            VIA2 project created (json file):
            <input id="via_page_import_via2_project_json" type="file" />
          </li>
        </ul>

        <div className="controls">
          <button id="via_page_button_import" onClick={_via_util_page_process_action}>Import</button>
          <button id="via_page_button_export" onClick={_via_util_page_process_action}>Export</button>
          <button onClick={() => _via_util_page_hide()}>Cancel</button>
        </div>
      </div>

      <div data-pageid="page_fileuri_bulk_add" className="via_page">
        <div className="toolbar"><span onClick={() => _via_util_page_hide()} className="text_button">&times;</span></div>
        <p>
          File Type:&nbsp;
          <select id="via_page_fileuri_filetype">
            <option value="2">Image</option>
            <option value="4" selected>Video</option>
            <option value="8">Audio</option>
            <option value="0">Detect Automatically</option>
          </select>
        </p>
        <h2>Paste File URI (one URI per line)</h2>
        <textarea
          id="via_page_fileuri_urilist"
          placeholder="For example, (1) http://www.robots.ox.ac.uk/~vgg/software/via/images/via_logo.png ; (2) file:///data/images/img001.jpg ; (3) file:///C:/Documents%20and%20Settings/tlm/video001.mp4"
          rows={10}
          cols={80}
        />
        <h2>Import URI from a File</h2>
        <input id="via_page_fileuri_importfile" type="file" />

        <div className="controls">
          <button id="via_page_fileuri_button_bulk_add" onClick={_via_util_page_process_action}>Add</button>
          <button onClick={() => _via_util_page_hide()}>Cancel</button>
        </div>
      </div>

      <div data-pageid="page_keyboard_shortcut" className="via_page">
        <div className="toolbar"><span onClick={() => _via_util_page_hide()} className="text_button">&times;</span></div>
        <h1>Keyboard Shortcuts</h1>
        <h3>General</h3>
        <table>
          <thead>
            <tr>
              <th>Command</th>
              <th>Shortcut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Play/Pause Media</td>
              <td><span className="key">Space</span></td>
            </tr>
            <tr>
              <td>Toggle Media Mute</td>
              <td><span className="key">m</span></td>
            </tr>
            <tr>
              <td>Increase / Decrease Media Playback Speed</td>
              <td>
                <span className="key">+</span>
                {' '}
                /
                {' '}
                <span className="key">-</span>
              </td>
            </tr>
            <tr>
              <td>Move Media Time Backward by 1, ..., 9 sec. (Ctrl to move forward)</td>
              <td>
                <span className="key">Ctrl</span>
                {' '}
                +
                {' '}
                <span className="key">1</span>
                ,
                {' '}
                <span className="key">2</span>
                ,
                ...,
                {' '}
                <span className="key">9</span>
              </td>
            </tr>
            <tr>
              <td>Move Media Time Forward or Backward by 5 frames</td>
              <td>
                <span className="key">N</span>
                {' '}
                /
                {' '}
                <span className="key">P</span>
              </td>
            </tr>
            <tr>
              <td>Add Temporal Segment at Current Time</td>
              <td><span className="key">a</span></td>
            </tr>
            <tr>
              <td>Update the edge (left or right) of last added segment to current time</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                +
                {' '}
                <span className="key">a</span>
              </td>
            </tr>

            <tr>
              <td>Select Previous / Next Temporal Segment Timeline</td>
              <td>
                <span className="key">&uarr;</span>
                {' '}
                /
                {' '}
                <span className="key">&darr;</span>
              </td>
            </tr>
            <tr>
              <td>Reorder timeline list by moving current timeline above/below its current position</td>
              <td>
                <span className="key">Ctrl</span>
                {' '}
                +
                {' '}
                <span className="key">&uarr;</span>
                {' '}
                /
                {' '}
                <span
                  className="key"
                >
                  &darr;
                </span>
              </td>
            </tr>

            <tr>
              <td>Select [Previous] Next Temporal Segment (e.g. 3sec to 5sec)</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                +
                {' '}
                <span className="key">Tab</span>
              </td>
            </tr>
            <tr>
              <td>Select Temporal Segment at Current Time (if any)</td>
              <td><span className="key">Enter</span></td>
            </tr>

            <tr>
              <td>Move to Previous / Next Video Frame</td>
              <td>
                <span className="key">l</span>
                {' '}
                /
                {' '}
                <span className="key">r</span>
              </td>
            </tr>
            <tr>
              <td>Jump to Start/End of Video</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                +
                {' '}
                <span className="key">s</span>
                {' '}
                /
                {' '}
                <span className="key">e</span>
              </td>
            </tr>
            <tr>
              <td>Move to previous/next video frame</td>
              <td>
                <span className="key">&larr;</span>
                {' '}
                /
                {' '}
                <span className="key">&rarr;</span>
              </td>
            </tr>
            <tr>
              <td>Shift Visible Timeline by 60 sec.</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                +
                {' '}
                <span className="key">&larr;</span>
                {' '}
                /
                {' '}
                <span
                  className="key"
                >
                  &rarr;
                </span>
              </td>
            </tr>
            <tr>
              <td>Zoom In/Out the Temporal Segment Timeline</td>
              <td>
                Mouse Wheel
                <br />
              </td>
            </tr>
            <tr>
              <td>Pan the Temporal Segment Timeline Horizontally</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                + Mouse Wheel
              </td>
            </tr>
          </tbody>
        </table>
        <h3>When a Temporal Segment is Selected</h3>
        <table>
          <thead>
            <tr>
              <th>Command</th>
              <th>Shortcut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Play/Pause Video Locked to Segment Boundary</td>
              <td><span className="key">Spc</span></td>
            </tr>
            <tr>
              <td>Delete Selected Temporal Segment</td>
              <td><span className="key">Backspace</span></td>
            </tr>
            <tr>
              <td>Select [Previous] Next Temporal Segment</td>
              <td>
                [
                <span className="key">Shift</span>
                ] +
                <span className="key">Tab</span>
              </td>
            </tr>
            <tr>
              <td>Unselect Temporal Segment</td>
              <td><span className="key">Esc</span></td>
            </tr>
            <tr>
              <td>Increase/Decrease the Extent of Left Edge (Ctrl updates by 1 sec.)</td>
              <td>
                [
                <span className="key">Ctrl</span>
                ] +
                <span className="key">l</span>
                {' '}
                /
                <span className="key">L</span>
              </td>
            </tr>
            <tr>
              <td>Increase/Decrease the Extent of Right edge (Ctrl updates by 1 sec.)</td>
              <td>
                [
                <span className="key">Ctrl</span>
                ] +
                <span className="key">r</span>
                {' '}
                /
                <span className="key">R</span>
              </td>
            </tr>

            <tr>
              <td>Jump to Start/End of Temporal Segment</td>
              <td>
                <span className="key">s</span>
                {' '}
                /
                {' '}
                <span className="key">e</span>
              </td>
            </tr>
            <tr>
              <td>Move Selected Temporal Segment (Ctrl updates by 1 sec.)</td>
              <td>
                [
                <span className="key">Ctrl</span>
                ] +
                <span className="key">&larr;</span>
                {' '}
                /
                <span
                  className="key"
                >
                  &rarr;
                </span>
              </td>
            </tr>
            <tr>
              <td>Merge Selected Temporal Segment with the Segment on Left/Right</td>
              <td>
                <span className="key">Shift</span>
                {' '}
                +
                {' '}
                <span className="key">&larr;</span>
                {' '}
                /
                {' '}
                <span
                  className="key"
                >
                  &rarr;
                </span>
              </td>
            </tr>
            <tr>
              <td>Move Selected Temporal Segment to Previous / Next Temporal Segment Timeline</td>
              <td>
                <span className="key">&uarr;</span>
                {' '}
                /
                {' '}
                <span className="key">&darr;</span>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Spatial Regions in an Image or a Video Frame</h3>
        <table>
          <thead>
            <tr>
              <th>Command</th>
              <th>Shortcut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Select All Regions</td>
              <td>
                <span className="key">Ctrl</span>
                {' '}
                + a
              </td>
            </tr>
            <tr>
              <td>Delete Selected Regions</td>
              <td>
                <span className="key">Backspace</span>
                {' '}
                or
                {' '}
                <span className="key">Delete</span>
              </td>
            </tr>
            <tr>
              <td>Toggle visibility of region shape boundary</td>
              <td><span className="key">b</span></td>
            </tr>
            <tr>
              <td>Toggle visibility of region label</td>
              <td><span className="key">l</span></td>
            </tr>
          </tbody>
        </table>

        <p>&nbsp;</p>
      </div>

      <div data-pageid="page_about" className="via_page">
        <div className="toolbar"><span onClick={() => _via_util_page_hide()} className="text_button">&times;</span></div>
        <h2>VGG Image Annotator (VIA)</h2>
        <p>
          VGG Image Annotator (VIA) is a simple and standalone manual annotation tool
          for image, audio and video. The VIA software is a light weight, standalone
          and offline software package that does not require any installation or setup
          and runs solely in a web browser. The complete VIA software fits in a single
          self-contained HTML page of size less than 500 kilobyte that runs as an
          offline application in most modern web browsers. VIA software is an open
          source project created solely using HTML, Javascript and CSS. More details
          about VIA is available from
          <a
            href="http://www.robots.ox.ac.uk/~vgg/software/via"
          >
            http://www.robots.ox.ac.uk/~vgg/software/via
          </a>
          .
        </p>
        <h4>Open Source Ecosystem</h4>
        <p>
          We have nurtured a large and thriving open source community which not only
          provides feedback but also contributes code to add new features and improve
          existing features in the VIA software. The open source ecosystem of VIA
          thrives around its
          <a href="https://gitlab.com/vgg/via">source code repository</a>
          hosted by the Gitlab platform. Most of our users report issues and request
          new features for future releases using the
          <a href="https://gitlab.com/vgg/via/issues">issue portal</a>
          . Many of our
          users not only submit bug reports but also suggest a potential fix for
          these software issues. Some of our users also contribute code to add new
          features to the VIA software using the
          <a href="https://gitlab.com/vgg/via/merge_requests">merge request</a>
          {' '}
          portal.
          A list of our contributors is available
          <a href="https://gitlab.com/vgg/via/blob/master/Contributors.md">here</a>
          .
        </p>

        <p>
          Thanks to the flexibility provided by our BSD open source software
          <a href="https://gitlab.com/vgg/via/blob/master/LICENSE">license</a>
          , many
          industrial projects have adapted the VIA software for internal or commercial use.
        </p>

        <h4>License</h4>
        <p>
          Copyright &copy; 2019-2021,
          <a href="mailto:adutta-removeme@robots.ox.ac.uk">Abhishek Dutta</a>
          , Visual
          Geometry Group, Oxford University and VIA Contributors.
        </p>
      </div> */}
    </div>

    <div id="via_start_info_content" className="hide" />

    <div className="via_container via_container_extended" id="via_container" tabIndex={0} />
  </>
));

export default VideoAnnotatorDefaultViaMarkup;
