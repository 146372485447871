import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthAlert } from './types';

const initialState: { alerts: AuthAlert[] } = {
  alerts: [],
};

const authAlertsSlice = createSlice({
  name: 'authAlerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<{ alert: AuthAlert }>) => ({ ...state, alerts: [...state.alerts, action.payload.alert] }),
    removeAlert: (state, action: PayloadAction<{ alertId: AuthAlert['id'] }>) => {
      const newAlerts = state.alerts.filter((alert) => alert.id !== action.payload.alertId);
      return { ...state, alerts: newAlerts };
    },
    reset: () => ({ ...initialState }),
  },
  selectors: {
    selectAlerts: (state) => state.alerts,
  },
});

export const { selectAlerts } = authAlertsSlice.selectors;

export const authAlertsSliceActions = authAlertsSlice.actions;

export default authAlertsSlice.reducer;
