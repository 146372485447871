import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './MatchReport.module.css';
import { selectProject, selectProjectionData } from '../../store/currentProject/slice';
import MatchReportHeader from './MatchReportHeader';
import MatchReportFooter from './MatchReportFooter';
import MatchReportFilters from './MatchReportFilters/MatchReportFilters';
import {
  MatchReportFiltersCategories,
  MatchReportServingFiltersFormFields,
  MatchReportServingInPlayFormFields, MatchReportServingReturningFormFields,
} from './MatchReportFilters/types';
import MatchReportContext, { MatchReportContextType } from './MatchReportFilters/context';
import MatchReportCourtProjection from '../CourtProjection/MatchReportCourtProjection';
import GTButton from '../../components/GTButton/GTButton';
import ReplayIcon from '../../components/MaterialSymbolsIcons/ReplayIcon';
import { ReportService } from '../../utils/report/ReportService';
import PlayerController from './PlayerController';
import { ReportShot } from '../../utils/report/ReportStructure';

type ReportMainUIElementType = 'VIDEO' | 'FILTERS';

const STEP_DURATION = 8.0 / 2.0; // 50% before and 50% after set timestamp

const playerController = new PlayerController();

function MatchReport() {
  const project = useSelector(selectProject);
  const [isShowVideo, setIsShowVideo] = useState(true);
  const [isShowFilters, setIsShowFilters] = useState(true);
  const [
    mainUIElementsOrder,
    setMainUIElementsOrder] = useState<ReportMainUIElementType[]>(['VIDEO', 'FILTERS']);
  const [filtersMode, setFiltersMode] = useState<MatchReportFiltersCategories>(MatchReportFiltersCategories.SERVING);
  const [filtersServing, setFiltersServing] = useState<MatchReportServingFiltersFormFields>({
    shotKind: '',
    serveType: '',
    courtType: '',
    shotType: '',
    gamePoint: '',
    winner: '',
    score: '',
    point: '',
  });
  const [filtersReturning, setFiltersReturning] = useState<MatchReportServingReturningFormFields>({
    shotKind: '',
    serveType: '',
    courtType: '',
    hand: '',
    situation: '',
    shotType: '',
    mode: '',
    zone: '',
    zoneSide: '',
    gamePoint: '',
    line: '',
    winner: '',
    score: '',
    point: '',
  });
  const [filtersInPlay, setFiltersInPlay] = useState<MatchReportServingInPlayFormFields>({
    shotKind: '',
    serveType: '',
    courtType: '',
    hand: '',
    shotType: '',
    mode: '',
    zone: '',
    zoneSide: '',
    gamePoint: '',
    line: '',
    winner: '',
    score: '',
    point: '',
  });

  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const [isLoopMoment, setIsLoopMoment] = useState(false);
  const [isPlayAllLoad, setIsPlayAllLoad] = useState(false);

  const [selectedSet, setSelectedSet] = useState(0)

  const [report, setCurrentReport] = useState<ReportService | undefined>(undefined);

  const [currentPlaybackEventId, setCurrentPlaybackEventId] = useState<string | undefined>(undefined);

  playerController.setPlayer(videoPlayerRef);
  playerController.onNextShot = (annotationId?: string) => {
    setCurrentPlaybackEventId(annotationId);
  };

  const projectionData = useSelector(selectProjectionData);
  const allBallEvents = useMemo(() => projectionData.map((item) => ({
    ...item.ball,
    timestamp: item.timestamp,
  })).filter((item) => item.annotation_id !== undefined), [projectionData]);

  useEffect(() => {
    let timeoutId: number | undefined;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        if (window.innerWidth < 1024 && isShowVideo && isShowFilters) {
          setIsShowFilters(false);
        }
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [isShowFilters, isShowVideo]);

  useEffect(() => {
    if (!project || !project.reportP1Path) return undefined;
    const report = new ReportService()
    report.preloadReport(project.reportP1Path).then(() => {
      setCurrentReport(report)
    })
  }, [project])

  const videoLink = useMemo(() => {
    if (!project) return '';

    return project.sourceVideoPath.startsWith('http')
      ? project.sourceVideoPath
      : `https://d3kn021kxuyfai.cloudfront.net${project.sourceVideoPath}`;
  }, [project]);

  const handleDragStart = useCallback((e: React.DragEvent<HTMLDivElement>, type: ReportMainUIElementType) => {
    e.dataTransfer!.setData('text/plain', type);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetIndex: number) => {
    const sourceIndex = mainUIElementsOrder.findIndex((item) => item === e.dataTransfer.getData('text/plain'));

    if (sourceIndex !== -1 && sourceIndex !== targetIndex) {
      const newItems = [...mainUIElementsOrder];
      const [removed] = newItems.splice(sourceIndex, 1);
      newItems.splice(targetIndex, 0, removed);
      setMainUIElementsOrder(newItems);
    }
  };

  const contextValue = useMemo<MatchReportContextType>(() => ({
    filtersMode,
    setFiltersMode,
    filtersServing,
    setFiltersServing,
    filtersReturning,
    setFiltersReturning,
    filtersInPlay,
    setFiltersInPlay,
  }), [filtersInPlay, filtersMode, filtersReturning, filtersServing]);

  const onEventSelected = useCallback((shot: ReportShot, _: number) => {
    const timestamp = shot.VideoTimestamp
    if (!videoPlayerRef.current || !timestamp) return;
    playerController.setPlayer(videoPlayerRef);
    const stepDuration = STEP_DURATION;
    const playStartTime = Math.max(0, timestamp - stepDuration);
    const playEndTime = timestamp + stepDuration;
    playerController.play(playStartTime, playEndTime, isLoopMoment);
  }, [videoPlayerRef, isLoopMoment]);

  const onPlayLoopAction = useCallback(() => {
    setIsLoopMoment(!isLoopMoment);
    playerController.loop = !isLoopMoment;
  }, [videoPlayerRef, isLoopMoment]);

  const onPlayAllEventsAction = useCallback(() => {
    setIsPlayAllLoad(!isPlayAllLoad);

    if (isPlayAllLoad) {
      playerController.stop();
      return;
    }
    const list = allBallEvents.map((item) => ({
      from: item.timestamp - STEP_DURATION,
      to: item.timestamp + STEP_DURATION,
      annotationId: item.annotation_id,
    })).sort((a, b) => (a.from <= b.from ? -1 : 1));
    playerController.playAllShots(list);
  }, [videoPlayerRef, isLoopMoment, allBallEvents, isPlayAllLoad]);

  return (
    <div className={classnames(styles.container, styles.report)}>
      <MatchReportContext.Provider value={contextValue}>
        <MatchReportHeader />
        <div className={styles.reportMainWrap}>
          {mainUIElementsOrder.map((itemType, index) => {
            if (itemType === 'VIDEO') {
              return (
                <div
                  key={`report_${itemType}`}
                  className={classnames(styles.reportVideoWrap, {
                    [styles.hidden]: !isShowVideo,
                  })}
                  draggable
                  onDragStart={(e) => handleDragStart(e, itemType)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video ref={videoPlayerRef} controls src={videoLink} />
                  <div className={styles.buttonsReplayContainer}>
                    <GTButton color="secondary" selected={isLoopMoment} onClick={onPlayLoopAction}><ReplayIcon /></GTButton>
                    <GTButton color="secondary" selected={isPlayAllLoad} onClick={onPlayAllEventsAction} style={{ marginLeft: '8px' }}>Play all shots</GTButton>
                  </div>
                </div>
              );
            }
            if (itemType === 'FILTERS') {
              return (
                <div
                  key={`report_${itemType}`}
                  className={classnames(styles.reportFiltersWrap, {
                    [styles.hidden]: !isShowFilters,
                  })}
                  draggable
                  onDragStart={(e) => handleDragStart(e, itemType)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  <MatchReportFilters />
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className={classnames(styles.courtWrap, {
          [styles.fullWidth]: !isShowFilters && !isShowVideo,
        })}
        >
          <MatchReportCourtProjection
            report={report}
            selectedSet={selectedSet}
            onEventSelected={onEventSelected}
            currentPlaybackEventId={currentPlaybackEventId}
          />
        </div>
        <MatchReportFooter
          onFiltersToggle={() => setIsShowFilters(!isShowFilters)}
          onVideoToggle={() => setIsShowVideo(!isShowVideo)}
          onSetSelected={(value) => { setSelectedSet(value) }}
          isFiltersShown={isShowFilters}
          isVideoShown={isShowVideo}
          setsCount={report?.setsCount ?? 0}
        />
      </MatchReportContext.Provider>
    </div>
  );
}

export default MatchReport;
