import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MatchAnnotation from './MatchAnnotation';
import MatchCourtProjection, { ProjectionItemType } from '../CourtProjection/MatchCourtProjection';
import styles from './MatchDetails.module.css';
import {
  selectAnnotations,
  selectProject,
} from '../../store/currentProject/slice';
import MatchDetailsHeader from './MatchDetailsHeader';
import MatchDetailsFooter from './MatchDetailsFooter';
import GTProject from './Types/GTProject';
import MatchDetailsContext from '../MatchCreator/MatchDetailsContext';
import AnnotationService from '../../utils/services/AnnotationService';

function MatchDetails() {
  const project = useSelector(selectProject);
  const annotations = useSelector(selectAnnotations);
  const [visInstance, setViaInstance] = useState<_via | null>(null);
  const [gtProject, setGtProject] = useState<GTProject | null>(null);

  useEffect(() => {
    if (project && annotations) {
      setGtProject({
        project,
        annotations,
      });
    }
  }, [project, annotations]);

  const onItemSelected = (_: ProjectionItemType, id?: string) => {
    AnnotationService.current.selectItem(id);
  };

  const contextValue = useMemo(() => ({ via: visInstance, setVia: setViaInstance }), [visInstance]);

  return (
    <MatchDetailsContext.Provider value={contextValue}>
      <div className={styles.container}>
        <MatchDetailsHeader />
        <div className={styles.editorWrap}>
          {gtProject ? <MatchAnnotation gtProject={gtProject} /> : <div />}
        </div>
        <div className={styles.courtWrap}>
          <MatchCourtProjection onItemSelected={onItemSelected} />
        </div>
        <MatchDetailsFooter />
      </div>
    </MatchDetailsContext.Provider>
  );
}

export default MatchDetails;
