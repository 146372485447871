import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './GTAlert.module.css';

export type GTAlertType = 'error' | 'info' | 'success' | 'warning';

type GTAlertProps = {
  text: string,
  id: string,
  type: GTAlertType
  closeAlert: (id: string) => unknown
};

function GTAlert({
  id, type, text, closeAlert,
}: GTAlertProps) {
  const [closeTimeout, setCloseTimeout] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!closeTimeout) {
      setCloseTimeout(setTimeout(() => {
        closeAlert(id);
      }, 5000));
    }

    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, [closeAlert, closeTimeout, id]);

  return (
    <Alert
      key={id}
      severity={type}
      onClose={() => closeAlert(id)}
      className={styles.alert}
      iconMapping={{
        success: <CheckCircleOutlineIcon fontSize="inherit" />,
      }}
    >
      {text}
    </Alert>
  );
}

export default GTAlert;
