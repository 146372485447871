import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './MatchDetailsFilter.module.css';
import AnnotationService from '../../../utils/services/AnnotationService';
import { MatchDetailsPlayerType } from './types/PlayerFiltersTypes';
import { selectProject } from '../../../store/currentProject/slice';

interface MatchDetailsPlayersFiltersProps {
  onLastOption: () => void
}

function MatchDetailsPlayersFilters({ onLastOption }: MatchDetailsPlayersFiltersProps) {
  const initialPlayerType = AnnotationService.current.selectedPlayerFilterData.playerType;

  const [isValueSetManually, setIsValueSetManually] = useState<boolean>(false);
  const project = useSelector(selectProject);
  const [playerValue, setPlayerValue] = useState<MatchDetailsPlayerType | null>(initialPlayerType ?? null);
  const playerOptions = useMemo(() => {
    const options: { label: string, value: MatchDetailsPlayerType }[] = [];
    if (project) {
      options.push({
        label: `${project.player1.firstName} ${project.player1.lastName}`,
        value: 'player_top',
      });
      options.push({
        label: `${project.player2.firstName} ${project.player2.lastName}`,
        value: 'player_bot',
      });
    }

    return options;
  }, [project]);

  const onValueOptionClick = useCallback((value: MatchDetailsPlayerType) => {
    setPlayerValue(value);
    setIsValueSetManually(true);
  }, []);

  useEffect(() => {
    if (playerValue && isValueSetManually) {
      AnnotationService.current.updateSelectedItemMetadata({ playerType: playerValue });
      onLastOption();
      setIsValueSetManually(false);
    }
  }, [isValueSetManually, onLastOption, playerValue]);

  return (
    <div className={styles.filterWrap}>
      <div className={styles.filterOptionsWrap}>
        {playerOptions.map((option) => (
          <button
            type="button"
            className={classnames(styles.filterOptionBtn, {
              [styles.active]: playerValue === option.value,
            })}
            onClick={() => onValueOptionClick(option.value)}
            key={`${option.label}-${String(option.value)}`}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MatchDetailsPlayersFilters;
