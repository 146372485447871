import { useCallback, useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import {
  Outlet, redirect, useNavigate,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { AUTH_BASE_ROUTE } from './routesConstants';
import { useAppDispatch } from '../store';
import { currentUserGetDetailsThunk } from '../store/currentUser/thunks';

function ProtectedRoute() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getUser = useCallback(async () => {
    try {
      setIsLoading(true);
      await getCurrentUser();
      await dispatch(currentUserGetDetailsThunk());
    } catch (e) {
      console.error(e);
      navigate(AUTH_BASE_ROUTE, { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    redirect(AUTH_BASE_ROUTE);
    getUser();
  }, [getUser]);

  if (isLoading) {
    return (
      <div style={{
        height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <CircularProgress size={36} />
      </div>
    );
  }

  return <Outlet />;
}

export default ProtectedRoute;
