import classnames from 'classnames';
import unreturnableBallSrc from '../MatchDetails/assets/unreturnable_ball_event.svg';
import forcingBallSrc from '../MatchDetails/assets/forcing_ball_event.svg';
import selectedUnreturnableBallSrc from '../MatchDetails/assets/selected_ball_event.svg';
import forcedServeBallSrc from '../MatchDetails/assets/forced_serve_ball_event.svg';
import unforcedBallSrc from '../MatchDetails/assets/unforced_ball_event.svg';
import otherBallSrc from '../MatchDetails/assets/other_ball_event.svg';

// import selectedUnreturnableBallSrc from '../MatchDetails/assets/selected_unreturnable_ball_event.svg';
import selectedForcingBallSrc from '../MatchDetails/assets/selected_forcing_ball_event.svg';
import selectedForcedServeBallSrc from '../MatchDetails/assets/selected_forced_serve_ball_event.svg';
import selectedUnforcedBallSrc from '../MatchDetails/assets/selected_unforced_ball_event.svg';
import selectedOtherBallSrc from '../MatchDetails/assets/selected_other_ball_event.svg';

import styles from './MatchCourtProjection.module.css';
import { ReportShot, ShotEventType } from '../../utils/report/ReportStructure';

type MatchReportCourtProjectionBallEventProps = {
  left: number,
  top: number,
  shot?: ReportShot,
  isSecondary?: boolean,
  selected?: boolean,
  onClick?: () => void
};


let BallEventPreview = {
  [ShotEventType.Forcing]: forcingBallSrc,
  [ShotEventType.UnforceError]: unforcedBallSrc,
  [ShotEventType.DoubleFault]: unforcedBallSrc,
  [ShotEventType.AceUnreturnable]: unreturnableBallSrc,
  [ShotEventType.WinnerUnreturnable]: unreturnableBallSrc,
  [ShotEventType.ForceError]: forcedServeBallSrc,
  [ShotEventType.Fault]: forcedServeBallSrc,
  [ShotEventType.Other]: otherBallSrc,
}

let BallEventPreviewSelected = {
  [ShotEventType.Forcing]: selectedForcingBallSrc,
  [ShotEventType.UnforceError]: selectedUnforcedBallSrc,
  [ShotEventType.DoubleFault]: selectedUnforcedBallSrc,
  [ShotEventType.AceUnreturnable]: selectedUnreturnableBallSrc,
  [ShotEventType.WinnerUnreturnable]: selectedUnreturnableBallSrc,
  [ShotEventType.ForceError]: selectedForcedServeBallSrc,
  [ShotEventType.Fault]: selectedForcedServeBallSrc,
  [ShotEventType.Other]: selectedOtherBallSrc,
}


function MatchReportCourtProjectionBallEvent({
  left, top, shot, isSecondary, onClick, selected,
}: MatchReportCourtProjectionBallEventProps) {

  if (!shot) return

  const type = shot._eventType ?? ShotEventType.Other
  let imageToUse = BallEventPreview[type] ?? otherBallSrc
  let imageToUseSelected = BallEventPreviewSelected[type] ?? selectedUnreturnableBallSrc

  return (
    <button className={styles.ballBtn} type="button" onClick={onClick} style={{ left: `${left}%`, top: `${top}%` }}>
      <img
        src={selected ? imageToUseSelected : imageToUse}
        alt="ball"
        data-id={shot.AppShotId}
        className={classnames(styles.ball, {
          [styles['--secondary']]: isSecondary,
        })}
      />
    </button>
  );
}

export default MatchReportCourtProjectionBallEvent;
