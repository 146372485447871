import React from 'react';
import IconBase from './IconBase';

type BallIconProps = React.SVGProps<SVGSVGElement>;

function BallIcon(props: BallIconProps) {
  return (
    <IconBase {...props} height={24}>
      <circle cx="12" cy="12" r="7.25" fill="none" stroke="currentColor" strokeWidth="1.5" />
    </IconBase>
  );
}

export default BallIcon;
