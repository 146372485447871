import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { confirmResetPassword } from 'aws-amplify/auth';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import GTButton from '../../components/GTButton/GTButton';
import GTModal from '../../components/GTModal/GTModal';
import { useAppDispatch } from '../../store';
import { selectCurrentUser } from '../../store/currentUser/slice';
import GTInput from '../../components/form/GTInput/GTInput';
import styles from './Settings.module.css';
import { passwordPattern } from '../../utils/regExtPatterns';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';

type SettingsPasswordResetModalProps = {
  open: boolean,
  onCancel: () => void
  onSuccess: () => void
};

type PasswordChangeInputs = {
  code: string
  newPassword: string
  confirmPassword: string
};

function SettingsPasswordResetModal({ open, onSuccess, onCancel }: SettingsPasswordResetModalProps) {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm<PasswordChangeInputs>();
  const currentUser = useSelector(selectCurrentUser);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState('');
  const dispatch = useAppDispatch();

  const isReadyToSubmit = !!(watch('code') && watch('newPassword') && watch('confirmPassword'));

  const handleRequestError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      default: {
        setRequestError(errorMessage);
      }
    }
  }, []);

  const onSubmit: SubmitHandler<PasswordChangeInputs> = async (data) => {
    if (!currentUser?.email) return;

    try {
      setRequestError('');
      setIsSubmitting(true);
      await confirmResetPassword({
        confirmationCode: data.code,
        newPassword: data.newPassword,
        username: currentUser.email,
      });
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Password changed successfully.',
          type: 'success',
        },
      }));
      reset();
      onSuccess();
    } catch (e: unknown) {
      handleRequestError((e as { message?: string }).message || 'An error has occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <GTModal
      open={open}
      title="Change Password"
      footer={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <GTButton disabled={isSubmitting} onClick={onCancel} color="secondary">Cancel</GTButton>
          <GTButton disabled={isSubmitting || !isReadyToSubmit} onClick={handleSubmit(onSubmit)} style={{ marginLeft: 8 }} color="primary">Change</GTButton>
        </div>
    )}
    >
      <>
        <p>
          Please check your email address and enter the verification code to reset your password
        </p>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 24, marginTop: 32,
        }}
        >
          <GTInput
            id="verificationCode"
            label="Verification code"
            placeholder="Enter verification code"
            error={!!errors.code}
            errorMessage={errors.code?.message}
            {...register('code', {
              required: 'This field is required',
            })}
          />
          <GTInput
            id="newPassword"
            label="New password"
            placeholder="Enter your password"
            error={!!errors.newPassword}
            errorMessage={errors.newPassword?.message}
            type={isShowNewPassword ? 'text' : 'password'}
            {...register('newPassword', {
              required: 'This field is required',
              pattern: { value: passwordPattern, message: 'Incorrect password format' },
            })}
            endAdornment={
          isShowNewPassword
            ? <VisibilityOffOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowNewPassword(false)} />
            : <VisibilityOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowNewPassword(true)} />
        }
          />
          <GTInput
            id="confirmPassword"
            error={!!errors.confirmPassword}
            errorMessage={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              required: 'This field is required',
              pattern: { value: passwordPattern, message: 'Incorrect password format' },
              validate: {
                equalsPassword: (value) => getValues('newPassword') === value || 'Passwords do not match. Try again.',
              },
            })}
            label="Confirm new password"
            placeholder="Confirm your password"
            type={isShowConfirmPassword ? 'text' : 'password'}
            endAdornment={
          isShowConfirmPassword
            ? <VisibilityOffOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowConfirmPassword(false)} />
            : <VisibilityOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowConfirmPassword(true)} />
        }
          />
          <p className={styles.hint}>
            Password must contain at least 8 characters, including
            <br />
            1 uppercase letter, 1 number, and 1 symbol (!, $, @, *, etc).
          </p>
          {requestError && <p className={styles.error}>{requestError}</p>}
        </div>
      </>
    </GTModal>
  );
}

export default SettingsPasswordResetModal;
