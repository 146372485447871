import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames';
import styles from './GTDatePicker.module.css';

type GTDatePickerProps = {
  label: string
  selected: string | undefined,
  onChange: (value: unknown) => unknown,
  onBlur?: () => unknown,
  id: string
  error? :boolean,
  errorMessage?: string,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GTDatePickerCustomInput = forwardRef<HTMLInputElement, any>((
  {
    value, onClick, id, error,
  },
  ref,
) => (
  <label htmlFor={id} className={styles.inputWrap}>
    <CalendarTodayOutlinedIcon className={styles.icon} />
    <input
      id={id}
      placeholder="mm/dd/yy"
      className={classnames(styles.input, { [styles.error]: !!error })}
      onClick={onClick}
      ref={ref}
      value={value}
      readOnly
    />
  </label>

));

function GTDatePicker({
  label, selected, onChange, onBlur, id, errorMessage, error,
}: GTDatePickerProps) {
  return (
    <div className={styles.wrap}>
      <label htmlFor={id} className={styles.label}>{label}</label>
      <DatePicker
        id={id}
        selected={selected ? new Date(selected) : undefined}
        onChange={onChange}
        onCalendarClose={onBlur}
        customInput={<GTDatePickerCustomInput id={id} error={error} />}
        maxDate={new Date()}
        showPopperArrow={false}
        calendarClassName={styles.calendar}
        popperPlacement="bottom-end"
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>

  );
}

export default GTDatePicker;
