import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  MatchDetailsBallFiltersBallActionValues,
  MatchDetailsBallFiltersHowLostValues,
  MatchDetailsBallFiltersHowWonValues,
  MatchDetailsBallFiltersModeValues,
  MatchDetailsBallFiltersShotFamilyValues,
  MatchDetailsBallFiltersShotTypeValues,
  MatchDetailsBallFiltersShotValues,
  MatchDetailsBallFiltersSideValues,
  MatchDetailsBallFiltersSituationValues,
  MatchDetailsBallFiltersSpinValues,
  MatchDetailsBallFiltersStepOption,
  MatchDetailsBallFiltersStepsKeys,
  MatchDetailsBallFiltersStepsNames,
  MatchDetailsBallFiltersWonOrLostValues,
} from './types/BallFiltersTypes';
import styles from './MatchDetailsFilter.module.css';
import GTIconButton from '../../../components/GTIconButton/GTIconButton';
import AnnotationService from '../../../utils/services/AnnotationService';

const steps = {
  [MatchDetailsBallFiltersStepsNames.ballAction]: [
    {
      label: 'Hit',
      value: MatchDetailsBallFiltersBallActionValues.Hit,
      breakOption: true,
    },
    {
      label: 'Bounce',
      value: MatchDetailsBallFiltersBallActionValues.Bounce,
    },
  ],
  [MatchDetailsBallFiltersStepsNames.wonorlost]: [
    {
      label: 'Win Shot',
      value: MatchDetailsBallFiltersWonOrLostValues.winshot,
    },
    {
      label: 'Lost Shot',
      value: MatchDetailsBallFiltersWonOrLostValues.lostshot,
    },
  ],
  [MatchDetailsBallFiltersStepsNames.shotfamily]: [
    {
      label: 'Serve',
      value: MatchDetailsBallFiltersShotFamilyValues.serve,
    },
    {
      label: 'Return-of-Serve',
      value: MatchDetailsBallFiltersShotFamilyValues.returnofsave,
    },
    {
      label: 'Groundstroke',
      value: MatchDetailsBallFiltersShotFamilyValues.groundstroke,
    },
    {
      label: 'Volley',
      value: MatchDetailsBallFiltersShotFamilyValues.volley,
    },
    {
      label: 'Overhead',
      value: MatchDetailsBallFiltersShotFamilyValues.overhead,
    },
  ],
  [MatchDetailsBallFiltersStepsNames.situation]: [
    {
      label: 'Deuce Court',
      value: MatchDetailsBallFiltersSituationValues.deuceCourt,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Ad Court',
      value: MatchDetailsBallFiltersSituationValues.adCourt,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Baseline',
      value: MatchDetailsBallFiltersSituationValues.baseline,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'At Net',
      value: MatchDetailsBallFiltersSituationValues.atnet,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Approaching',
      value: MatchDetailsBallFiltersSituationValues.approaching,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Backcourt',
      value: MatchDetailsBallFiltersSituationValues.backcourt,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Passing',
      value: MatchDetailsBallFiltersSituationValues.passing,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Before Bounce',
      value: MatchDetailsBallFiltersSituationValues.beforeBounce,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'After Bounce',
      value: MatchDetailsBallFiltersSituationValues.afterBounce,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
  ],
  [MatchDetailsBallFiltersStepsNames.side]: [
    {
      label: 'Forehand',
      value: MatchDetailsBallFiltersSideValues.forehand,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Backhand',
      value: MatchDetailsBallFiltersSideValues.backhand,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
  ],
  [MatchDetailsBallFiltersStepsNames.howwon]: [
    {
      label: 'Winner',
      value: MatchDetailsBallFiltersHowWonValues.winner,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.winshot],
      },
    },
    {
      label: 'Unreturnable',
      value: MatchDetailsBallFiltersHowWonValues.unreturnable,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.winshot],
      },
    },
    {
      label: 'Forcing Shot',
      value: MatchDetailsBallFiltersHowWonValues.forcingshot,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.winshot],
      },
    },
    {
      label: 'Opponent\'s Unforced Error',
      value: MatchDetailsBallFiltersHowWonValues.opponentsunforcederror,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.winshot],
      },
    },
  ],
  [MatchDetailsBallFiltersStepsNames.howlost]: [
    {
      label: 'Opponent\'s Winner',
      value: MatchDetailsBallFiltersHowLostValues.opponentswinner,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.lostshot],
      },
    },
    {
      label: 'Unreturnable',
      value: MatchDetailsBallFiltersHowLostValues.unreturnable,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.lostshot],
      },
    },
    {
      label: 'Forced Error',
      value: MatchDetailsBallFiltersHowLostValues.forcederror,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.lostshot],
      },
    },
    {
      label: 'Unforced Error',
      value: MatchDetailsBallFiltersHowLostValues.unforcederror,
      condition: {
        [MatchDetailsBallFiltersStepsNames.wonorlost]: [MatchDetailsBallFiltersWonOrLostValues.lostshot],
      },
    },
  ],
  [MatchDetailsBallFiltersStepsNames.shottype]: [
    {
      label: 'Flat',
      value: MatchDetailsBallFiltersShotTypeValues.s_flat,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Topspin',
      value: MatchDetailsBallFiltersShotTypeValues.s_topspin,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Slice',
      value: MatchDetailsBallFiltersShotTypeValues.s_slice,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Kick',
      value: MatchDetailsBallFiltersShotTypeValues.s_kick,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Slice Kick',
      value: MatchDetailsBallFiltersShotTypeValues.s_slicekick,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Sidespin',
      value: MatchDetailsBallFiltersShotTypeValues.s_sidespin,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.serve,
        ],
      },
    },
    {
      label: 'Drive',
      value: MatchDetailsBallFiltersShotTypeValues.rs_drive,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Arc',
      value: MatchDetailsBallFiltersShotTypeValues.rs_arc,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Loop',
      value: MatchDetailsBallFiltersShotTypeValues.rs_loop,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Lob',
      value: MatchDetailsBallFiltersShotTypeValues.rs_lob,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Angle',
      value: MatchDetailsBallFiltersShotTypeValues.rs_angle,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Up & Down',
      value: MatchDetailsBallFiltersShotTypeValues.rs_updown,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Dip Drive',
      value: MatchDetailsBallFiltersShotTypeValues.rs_dipdrive,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Bender',
      value: MatchDetailsBallFiltersShotTypeValues.rs_bender,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Drop Shot',
      value: MatchDetailsBallFiltersShotTypeValues.rs_dropshot,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Dipper',
      value: MatchDetailsBallFiltersShotTypeValues.rs_dipper,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
        ],
      },
    },
    {
      label: 'Drive',
      value: MatchDetailsBallFiltersShotTypeValues.gs_drive,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Arc',
      value: MatchDetailsBallFiltersShotTypeValues.gs_arc,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Loop',
      value: MatchDetailsBallFiltersShotTypeValues.gs_loop,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Lob',
      value: MatchDetailsBallFiltersShotTypeValues.gs_lob,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Angle',
      value: MatchDetailsBallFiltersShotTypeValues.gs_angle,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Up & Down',
      value: MatchDetailsBallFiltersShotTypeValues.gs_updown,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Dip Drive',
      value: MatchDetailsBallFiltersShotTypeValues.gs_dipdrive,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Bender',
      value: MatchDetailsBallFiltersShotTypeValues.gs_bender,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Drop Shot',
      value: MatchDetailsBallFiltersShotTypeValues.gs_dropshot,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Dipper',
      value: MatchDetailsBallFiltersShotTypeValues.gs_dipper,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
        ],
      },
    },
    {
      label: 'Standard',
      value: MatchDetailsBallFiltersShotTypeValues.v_standard,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Swing',
      value: MatchDetailsBallFiltersShotTypeValues.v_swing,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Half',
      value: MatchDetailsBallFiltersShotTypeValues.v_half,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Drop',
      value: MatchDetailsBallFiltersShotTypeValues.v_drop,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Lob',
      value: MatchDetailsBallFiltersShotTypeValues.v_lob,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.volley,
        ],
      },
    },
    {
      label: 'Power',
      value: MatchDetailsBallFiltersShotTypeValues.o_power,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'Placement',
      value: MatchDetailsBallFiltersShotTypeValues.o_placement,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
  ],
  [MatchDetailsBallFiltersStepsNames.spin]: [
    {
      label: 'Topspin',
      value: MatchDetailsBallFiltersSpinValues.topspin,
    },
    {
      label: 'Underspin',
      value: MatchDetailsBallFiltersSpinValues.underspin,
    },
    {
      label: 'Slice',
      value: MatchDetailsBallFiltersSpinValues.slice,
    },
    {
      label: 'Flat',
      value: MatchDetailsBallFiltersSpinValues.flat,
    },
  ],
  [MatchDetailsBallFiltersStepsNames.mode]: [
    {
      label: 'Defend',
      value: MatchDetailsBallFiltersModeValues.defend,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'Counterattack',
      value: MatchDetailsBallFiltersModeValues.counterattack,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'Rally',
      value: MatchDetailsBallFiltersModeValues.rally,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'Challenge',
      value: MatchDetailsBallFiltersModeValues.challenge,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
    {
      label: 'Attack',
      value: MatchDetailsBallFiltersModeValues.attack,
      condition: {
        [MatchDetailsBallFiltersStepsNames.shotfamily]: [
          MatchDetailsBallFiltersShotFamilyValues.returnofsave,
          MatchDetailsBallFiltersShotFamilyValues.groundstroke,
          MatchDetailsBallFiltersShotFamilyValues.volley,
          MatchDetailsBallFiltersShotFamilyValues.overhead,
        ],
      },
    },
  ],
};

const stepOrder: string[] = [
  MatchDetailsBallFiltersStepsNames.ballAction,
  MatchDetailsBallFiltersStepsNames.wonorlost,
  MatchDetailsBallFiltersStepsNames.shotfamily,
  MatchDetailsBallFiltersStepsNames.situation,
  MatchDetailsBallFiltersStepsNames.side,
  MatchDetailsBallFiltersStepsNames.howwon,
  MatchDetailsBallFiltersStepsNames.howlost,
  MatchDetailsBallFiltersStepsNames.shottype,
  MatchDetailsBallFiltersStepsNames.spin,
  MatchDetailsBallFiltersStepsNames.mode,
];

type StepsValues = {
  [K in string]?: MatchDetailsBallFiltersShotValues;
};

interface MatchDetailsBallFiltersProps {
  onLastOption: () => void
}

function MatchDetailsBallFilters({ onLastOption }: MatchDetailsBallFiltersProps) {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [isValueSetManually, setIsValueSetManually] = useState<boolean>(false);
  const [nextStepIndex, setNextStepIndex] = useState<number | null>(null);
  const [prevStepIndex, setPrevStepIndex] = useState<number | null>(null);

  const initialStepValues = {
    ...AnnotationService.current.selectedBallFilterData,
  };
  const [stepsValues, setStepsValues] = useState<StepsValues>(
    { ...initialStepValues },
  );

  const currentStep = useMemo(() => stepOrder[stepIndex], [stepIndex]);
  const currentStepValue = useMemo(() => stepsValues[currentStep], [currentStep, stepsValues]);
  const currentStepOptions: MatchDetailsBallFiltersStepOption[] = useMemo(
    () => steps[currentStep] as MatchDetailsBallFiltersStepOption[],
    [currentStep],
  );

  const currentStepOptionsToRender: MatchDetailsBallFiltersStepOption[] = useMemo(() => currentStepOptions.filter((option) => {
    if (!option.condition) {
      return true;
    }
    let isAvailable = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(option.condition)) {
      if (!stepsValues[key]) {
        isAvailable = false;
        return false;
      }
      if (!value.includes(stepsValues[key] as MatchDetailsBallFiltersShotValues)) {
        isAvailable = false;
        return false;
      }
    }

    return isAvailable;
  }), [currentStepOptions, stepsValues]);

  const ifStepHasAvailableOptions = useCallback((index: number) => {
    if (index >= stepOrder.length) return false;
    const nextStepKey = stepOrder[index] as MatchDetailsBallFiltersStepsKeys;
    const stepOptions = steps[nextStepKey];
    let isAvailable = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    stepOptions.forEach((option: MatchDetailsBallFiltersStepOption) => {
      if (!option.condition) {
        isAvailable = true;
        return;
      }
      const optionCondition = option.condition;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(optionCondition)) {
        if (value.includes(stepsValues[key] as MatchDetailsBallFiltersShotValues)) {
          isAvailable = true;
          break;
        }
      }
    });
    return isAvailable;
  }, [stepsValues]);

  useEffect(() => {
    let possiblePrevStepIndex = stepIndex - 1;
    if (possiblePrevStepIndex < 0) {
      setPrevStepIndex(null);
      return;
    }
    while (possiblePrevStepIndex >= 0) {
      const isAvailable = ifStepHasAvailableOptions(possiblePrevStepIndex);
      if (isAvailable) {
        setPrevStepIndex(possiblePrevStepIndex);
        break;
      } else {
        setPrevStepIndex(null);
        possiblePrevStepIndex -= 1;
      }
    }
  }, [stepsValues, stepIndex, ifStepHasAvailableOptions]);

  const isFirstStep = useMemo(() => prevStepIndex === null || prevStepIndex < 0, [prevStepIndex]);
  const isLastStep = useMemo(() => stepIndex === stepOrder.length - 1, [stepIndex]);

  const isCurrentStepSet = useMemo(() => stepsValues[currentStep], [currentStep, stepsValues]);

  const isCurrentValueBreak = useMemo(() => {
    if (!stepsValues[currentStep]) return false;

    const selectedValue = stepsValues[currentStep];
    const option = currentStepOptions.find((item) => item.value === selectedValue);
    if (!option) return false;
    return !!option.breakOption;
  }, [currentStep, currentStepOptions, stepsValues]);

  useEffect(() => {
    if (!currentStepValue) {
      return;
    }

    let possibleNextStepIndex = stepIndex + 1;
    if (possibleNextStepIndex >= stepOrder.length) {
      setNextStepIndex(null);
      return;
    }
    while (possibleNextStepIndex < stepOrder.length) {
      const isAvailable = ifStepHasAvailableOptions(possibleNextStepIndex);
      if (isAvailable) {
        setNextStepIndex(possibleNextStepIndex);
        return;
      }
      possibleNextStepIndex += 1;
    }
    setNextStepIndex(null);
  }, [currentStepValue, stepIndex]);

  const isNextStepAvailable = useMemo(
    () => !!(!isCurrentValueBreak && !isLastStep && isCurrentStepSet && nextStepIndex),
    [isCurrentStepSet, isCurrentValueBreak, isLastStep, nextStepIndex],
  );

  const setStepValue = useCallback((value: MatchDetailsBallFiltersShotValues | undefined) => {
    const newStepValues = { ...stepsValues };
    if (currentStepValue && currentStepValue !== value) {
      // reset further steps if already selected
      let indexToReset = stepIndex + 1;
      while (indexToReset <= stepOrder.length - 1) {
        newStepValues[stepOrder[indexToReset]] = undefined;
        indexToReset += 1;
      }
    }

    newStepValues[currentStep] = value;

    setStepsValues(newStepValues);
  }, [currentStep, stepIndex, stepsValues, currentStepValue]);

  const goToPrevStep = useCallback(() => {
    if (isFirstStep) return;

    setStepIndex(prevStepIndex || 0);
  }, [isFirstStep, prevStepIndex]);

  const goToNextStep = useCallback(() => {
    if (!isNextStepAvailable || !nextStepIndex || nextStepIndex === stepIndex) return;

    const newIndex = nextStepIndex;
    if (newIndex >= stepOrder.length) return;

    setStepIndex(newIndex);
    setIsValueSetManually(false);
  }, [isNextStepAvailable, nextStepIndex, stepIndex]);

  const onValueOptionClick = useCallback((value: MatchDetailsBallFiltersShotValues) => {
    setStepValue(value);
    setTimeout(() => {setIsValueSetManually(true);}, 0)
  }, [setStepValue]);

  useEffect(() => {
    if (currentStepValue && isValueSetManually) {
      AnnotationService.current.updateSelectedItemMetadata(stepsValues);
    }
  }, [stepsValues, isValueSetManually, currentStepValue]);

  useEffect(() => {
    if (isValueSetManually) {
      if (isNextStepAvailable) {
        goToNextStep();
      } else {
        setIsValueSetManually(false);
        onLastOption();
      }
    }
  }, [isNextStepAvailable, isValueSetManually, goToNextStep, onLastOption]);

  return (
    <div className={styles.filterWrap}>
      <GTIconButton onClick={goToPrevStep} disabled={isFirstStep} color="secondary">
        <ChevronLeftIcon />
      </GTIconButton>
      <div className={styles.filterOptionsWrap}>
        {currentStepOptionsToRender.map((option) => (
          <button
            type="button"
            className={classnames(styles.filterOptionBtn, {
              [styles.active]: stepsValues[currentStep] === option.value,
            })}
            onClick={() => onValueOptionClick(option.value)}
            key={`${currentStep}-${String(option.value)}`}
          >
            {option.label}
          </button>
        ))}
      </div>

      <GTIconButton onClick={goToNextStep} disabled={!isNextStepAvailable} color="secondary">
        <ChevronRightIcon />
      </GTIconButton>
    </div>
  );
}

export default MatchDetailsBallFilters;
