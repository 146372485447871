import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import styles from './GTSnackbarAlert.module.css';
import { AppAlertType } from '../../store/appAlerts/types';
import GTButton from '../GTButton/GTButton';
import DeleteIcon from '../MaterialSymbolsIcons/DeleteIcon';

type GTSnackbarAlertProps = {
  text: string,
  id: string,
  type: AppAlertType,
  closeAlert: (id: string) => unknown
};

function GTSnackbarAlert({
  text, id, type, closeAlert,
}: GTSnackbarAlertProps) {
  const [closeTimeout, setCloseTimeout] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!closeTimeout) {
      setCloseTimeout(setTimeout(() => {
        closeAlert(id);
      }, 5000));
    }

    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, [closeAlert, closeTimeout, id]);

  return (
    <Snackbar
      className={styles.snackbar}
      open
      message={(
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          {type === 'success' && <CheckCircleIcon style={{ fontSize: 18, color: 'var(--color-accent-green-20)' }} />}
          {type === 'delete' && <DeleteIcon style={{ fontSize: 18, color: 'var(--color-accent-red)' }} />}
          {type === 'error' && <ReportProblemOutlinedIcon style={{ fontSize: 18, color: 'var(--color-accent-red)' }} />}
          <p>{text}</p>
        </div>
)}
      action={(
        <GTButton onClick={() => closeAlert(id)} style={{ height: 24, width: 24 }} color="tertiary">
          <CloseIcon style={{ fontSize: 24 }} />
        </GTButton>
    )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
}

export default GTSnackbarAlert;
