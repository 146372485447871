import React from 'react';
import IconBase from './IconBase';

type UndoIconProps = React.SVGProps<SVGSVGElement>;

function UndoIcon(props: UndoIconProps) {
  return (
    <IconBase {...props} height={24}>
      <path
        d="M7.95193 18.4999C7.73911 18.4999 7.56091 18.4281 7.41733 18.2845C7.27374 18.1409 7.20195 17.9627 7.20195 17.7499C7.20195 17.5371 7.27374 17.3589 7.41733 17.2153C7.56091 17.0717 7.73911 16.9999 7.95193 16.9999H14.3789C15.4225 16.9999 16.3205 16.6554 17.0731 15.9663C17.8257 15.2772 18.202 14.4262 18.202 13.4134C18.202 12.4006 17.8257 11.5512 17.0731 10.8653C16.3205 10.1794 15.4225 9.83645 14.3789 9.83645H7.35765L9.61345 12.0922C9.75832 12.2371 9.83075 12.4127 9.83075 12.6191C9.83075 12.8256 9.75832 13.0012 9.61345 13.1461C9.46859 13.2909 9.29135 13.3618 9.08173 13.3586C8.87213 13.3554 8.6981 13.2845 8.55963 13.1461L5.13273 9.71915C5.03914 9.62556 4.97312 9.52684 4.93465 9.423C4.89619 9.31916 4.87695 9.20699 4.87695 9.08647C4.87695 8.96595 4.89619 8.85377 4.93465 8.74992C4.97312 8.64609 5.03914 8.54737 5.13273 8.45377L8.55963 5.02687C8.70451 4.882 8.88015 4.80957 9.08655 4.80957C9.29295 4.80957 9.46859 4.882 9.61345 5.02687C9.75832 5.17174 9.82915 5.34898 9.82595 5.5586C9.82275 5.7682 9.75192 5.94223 9.61345 6.0807L7.35765 8.3365H14.3789C15.8417 8.3365 17.0946 8.82463 18.1375 9.8009C19.1804 10.7772 19.7019 11.9813 19.7019 13.4134C19.7019 14.8454 19.1804 16.0512 18.1375 17.0307C17.0946 18.0102 15.8417 18.4999 14.3789 18.4999H7.95193Z"
        fill="currentColor"
      />
    </IconBase>
  );
}

export default UndoIcon;
