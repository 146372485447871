import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import classnames from 'classnames';
import ballSrc from '../MatchDetails/assets/ball_event.svg';
import unreturnableBallSrc from '../MatchDetails/assets/unreturnable_ball_event.svg';
import forcingBallSrc from '../MatchDetails/assets/forcing_ball_event.svg';
import forcedBallSrc from '../MatchDetails/assets/forced_ball_event.svg';
import forcedServeBallSrc from '../MatchDetails/assets/forced_serve_ball_event.svg';
import unforcedBallSrc from '../MatchDetails/assets/unforced_ball_event.svg';
import otherBallSrc from '../MatchDetails/assets/other_ball_event.svg';
import styles from './MatchCourtProjection.module.css';
import { selectAnnotations } from '../../store/currentProject/slice';
import { ViaStrutureParameter } from '../../utils/services/AnnotationService';
import {
  MatchDetailsBallFiltersBallActionValues,
  MatchDetailsBallFiltersHowLostValues,
  MatchDetailsBallFiltersHowWonValues,
  MatchDetailsBallFiltersShotFamilyValues,
  MatchDetailsBallFiltersShotValues,
  MatchDetailsBallFiltersStepsKeys,
} from '../MatchDetails/Filters/types/BallFiltersTypes';

type MatchCourtProjectionBallEventProps = {
  left: number,
  top: number,
  annotationId?: string,
  isSecondary?: boolean,
  onClick?: () => void
};

function MatchCourtProjectionBallEvent({
  left, top, annotationId, isSecondary, onClick,
}: MatchCourtProjectionBallEventProps) {
  const annotations = useSelector(selectAnnotations);
  const projectionMetadata = useMemo(() => annotations?.metadata as Record<string, unknown>, [annotations]);
  const ballMetadata = useMemo(() => {
    if (!projectionMetadata || !annotationId) return null;
    return projectionMetadata[annotationId] as Record<string, any>;
  }, [annotationId, projectionMetadata]);

  const ballData = useMemo(() => {
    if (!ballMetadata) return null;
    return ballMetadata.av[ViaStrutureParameter.filterMetadata] as Record<
    MatchDetailsBallFiltersStepsKeys, MatchDetailsBallFiltersShotValues>;
  }, [ballMetadata]);

  const imageToUse = useMemo(() => {
    if (!ballData || ballData.ballAction === MatchDetailsBallFiltersBallActionValues.Hit) return ballSrc;

    if (ballData.shotfamily) {
      switch (true) {
        case ballData.howwon === MatchDetailsBallFiltersHowWonValues.forcingshot: {
          return forcingBallSrc;
        }
        case ballData.howwon === MatchDetailsBallFiltersHowWonValues.unreturnable
        || ballData.howwon === MatchDetailsBallFiltersHowWonValues.winner: {
          return unreturnableBallSrc;
        }
        case ballData.howlost === MatchDetailsBallFiltersHowLostValues.forcederror: {
          if (ballData.shotfamily === MatchDetailsBallFiltersShotFamilyValues.serve) {
            return forcedServeBallSrc;
          }
          return forcedBallSrc;
        }
        case ballData.howlost === MatchDetailsBallFiltersHowLostValues.unforcederror: {
          return unforcedBallSrc;
        }
        default: return otherBallSrc;
      }
    }

    return ballSrc;
  }, [ballData]);

  return (
    <button className={styles.ballBtn} type="button" onClick={onClick} style={{ left: `${left}%`, top: `${top}%` }}>
      <img
        src={imageToUse}
        alt="ball"
        className={classnames(styles.ball, {
          [styles['--secondary']]: isSecondary,
        })}
      />
    </button>

  );
}

export default MatchCourtProjectionBallEvent;
