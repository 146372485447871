import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import projectsSlice from './projects/slice';
import currentProjectSlice from './currentProject/slice';
import currentUserSlice from './currentUser/slice';
import usersSlice from './users/slice';
import authAlertsSlice from './authAlerts/slice';
import appAlertsSlice from './appAlerts/slice';
import { isLocalEnv } from '../utils/envUtils';

export const store = configureStore({
  reducer: {
    projects: projectsSlice,
    currentProject: currentProjectSlice,
    currentUser: currentUserSlice,
    users: usersSlice,
    authAlerts: authAlertsSlice,
    appAlerts: appAlertsSlice,
  },
  devTools: isLocalEnv,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
