import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';
import styles from './GTAutocomplete.module.css';

export type GTAutocompleteOption = { title: string, value: string, group?: string };

type GTAutocompleteProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label: string
  options: GTAutocompleteOption[],
  value: string,
  onChange: (x: unknown) => unknown,
  onBlur?: () => unknown,
  id: string
  placeholder: string,
  error?: boolean,
  errorMessage?: string
};

function GTAutocomplete({
  label, id, options, value, onChange, onBlur, placeholder, error, errorMessage,
}: GTAutocompleteProps) {
  return (
    <div className={styles.wrap}>
      <label htmlFor={id} className={styles.label}>{label}</label>
      <Autocomplete
        freeSolo
        value={value || ''}
        onBlur={onBlur}
        onChange={(_, data) => onChange(typeof data === 'string' ? data : data.value)}
        onInputChange={(_, data) => onChange(data)}
        className={classnames(styles.autocomplete, {
          [styles.error]: !!error,
        })}
        slotProps={{
          paper: {
            className: styles.autocompletePopper,
          },
        }}
        id={id}
        forcePopupIcon
        popupIcon={<KeyboardArrowDownIcon />}
        disableClearable
        disablePortal
        options={options}
        isOptionEqualToValue={(option, valueToCheck) => option.value === valueToCheck.value}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
        groupBy={(option) => option.group || ''}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
}

export default GTAutocomplete;
