export const BASE_ROUTE = '/';
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_ACCOUNT_ROUTE = `${SETTINGS_ROUTE}/account`;
export const SETTINGS_USERS_ROUTE = `${SETTINGS_ROUTE}/users`;
export const MATCH_ROUTE = '/match';
export const PROJECT_ROUTE = '/project';
export const AUTH_BASE_ROUTE = '/auth';
export const AUTH_FORGOT_ROUTE = `${AUTH_BASE_ROUTE}/forgot`;
export const AUTH_INVITE_ROUTE = `${AUTH_BASE_ROUTE}/signup`;
export const AUTH_WELCOME_ROUTE = `${AUTH_BASE_ROUTE}/welcome`;
