import { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import classnames from 'classnames';
import styles from './MatchCourtProjection.module.css';
import GTIconButton from '../../components/GTIconButton/GTIconButton';
import GTSlider from '../../components/GTSlider/GTSlider';
import ZoomInIcon from '../../components/MaterialSymbolsIcons/ZoomInIcon';
import ZoomOutIcon from '../../components/MaterialSymbolsIcons/ZoomOutIcon';

type MatchCourtProjectionZoomControlProps = {
  zoom: number,
  setZoom: (value: number) => void;
};

function MatchCourtProjectionZoomControl({ zoom, setZoom }: MatchCourtProjectionZoomControlProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classnames(styles.zoomControl, { [styles.open]: isOpen })}>
      {!isOpen && (
      <GTIconButton onClick={() => setIsOpen(true)}>
        <ZoomInIcon style={{ fontSize: 24 }} />
      </GTIconButton>
      )}
      {isOpen && (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div className={styles.zoomSliderWrap}>
          <ZoomOutIcon style={{ fontSize: 24 }} />
          <GTSlider
            style={{ width: 120 }}
            aria-label="Zoom"
            min={1}
            max={2}
            step={0.01}
            value={zoom}
            onChange={(_, value) => setZoom(value as number)}
          />
          <ZoomInIcon style={{ fontSize: 24 }} />
        </div>
      </ClickAwayListener>
      )}
    </div>
  );
}

export default MatchCourtProjectionZoomControl;
