import { useFormContext, UseFormRegisterReturn, useWatch } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import prettyBytes from 'pretty-bytes';
import classnames from 'classnames';
import styles from './GTVideoInput.module.css';
import { MatchFormInputs } from '../../../features/MatchCreator/types';
import VideoIcon from '../../customIcons/VideoIcon';
import DeleteIcon from '../../MaterialSymbolsIcons/DeleteIcon';

type GTFileInputProps = {
  registerProps: UseFormRegisterReturn,
  error?: boolean,
  errorMessage?: string,
};

function GTVideoInput({ registerProps, error, errorMessage }: GTFileInputProps) {
  const { control, resetField, trigger } = useFormContext<MatchFormInputs>();
  const videoValue = useWatch({ control, name: 'videoFile' });
  const file = useMemo(() => (videoValue ? videoValue[0] : null), [videoValue]);

  const onFileClearClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    resetField('videoFile');
    trigger('videoFile');
  }, [resetField]);

  return (
    <>
      <div className={classnames(styles.wrap, { [styles.error]: !!error })}>
        <input type="file" accept="video/mp4" {...registerProps} />
        {!file && <p className={styles.placeholder}>Upload video by clicking or dragging the file here.</p>}
        {file && (
        <div className={styles.videoInfo}>
          <VideoIcon className={styles.videoIcon} />
          <div className={styles.videoInfoSubwrap}>
            <h4 className={styles.videoName}>{file.name}</h4>
            <p className={styles.videoSize}>{prettyBytes(file.size)}</p>
          </div>
          <button className={styles.clearBtn} aria-label="remove video" type="button" onClick={onFileClearClick}>
            <DeleteIcon style={{ fontSize: 18, color: 'var(--color-accent-red)' }} />
          </button>
        </div>
        )}
      </div>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
}

export default GTVideoInput;
