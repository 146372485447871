export interface EventPlaybackItem {
  from: number;
  to: number;
  annotationId?: string
}

class PlayerController {
  protected from: number = 0;

  protected to: number = 0;

  public loop: boolean = false;

  protected playerRef?: React.MutableRefObject<HTMLVideoElement | null>;

  protected intervalId?: any;

  protected isPlayingAllShots = false;

  setPlayer(player?: React.MutableRefObject<HTMLVideoElement | null>) {
    this.playerRef = player;
  }

  get video() {
    return this.playerRef?.current;
  }

  public onNextShot? : (annotationId?: string) => void;

  play(from: number, to: number, useLoop: boolean = false) {
    this.isPlayingAllShots = false;
    this.stop();
    if (!this.video) return;
    this.loop = useLoop;
    this._play(from, to, useLoop);
  }

  playAllShots(playbackEvents: EventPlaybackItem[]) {
    this.stop();
    if (!this.video) return;

    this.isPlayingAllShots = true;

    const index = 0;
    const element = playbackEvents[index];
    this.onNextShot && this.onNextShot(element.annotationId);

    this._play(element.from, element.to, false, () => {
      playbackEvents.shift();
      if (playbackEvents.length !== 0) {
        this.playAllShots(playbackEvents);
      } else this.isPlayingAllShots = false;
    });
  }

  protected _play(from: number, to: number, useLoop: boolean = false, onPlayEnded?: () => void) {
    this.stop();
    if (!this.video) return;
    this.loop = useLoop;
    const finalTime = Math.min((this.video.duration ?? to), to);
    const checkTime = () => {
      if (!this.video) return;
      // const isPaused = this.video.paused === true
      if (this.video.currentTime >= finalTime || this.video?.ended === true) {
        if (!this.loop) this.stop();
        onPlayEnded && onPlayEnded();
        if (this.loop) {
          this.video.currentTime = from;
          this.video.play();
        }
      }
    };
    if (this.video) {
      this.video.pause();
      this.video.currentTime = from;
    }
    this.video?.play();
    this.intervalId = setInterval(checkTime, 50);
  }

  stop() {
    this.video?.pause();
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }
}

export default PlayerController;
