import classnames from 'classnames';
import { ButtonBase } from '@mui/material';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import styles from './GTIconButton.module.css';

type GTIconButtonProps = ButtonBaseProps & {
  hasHover?: boolean
};

function GTIconButton({
  children, className, hasHover, ...props
}: GTIconButtonProps) {
  return (
    <ButtonBase
      className={classnames(
        styles.iconButton,
        {
          [styles.hover]: !!hasHover,
          [className as string]: !!className,
        },
      )}
      {...props}
    >
      {children}
    </ButtonBase>
  );
}

export default GTIconButton;
