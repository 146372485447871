import { useForm, SubmitHandler } from 'react-hook-form';
import { signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { CircularProgress } from '@mui/material';
import styles from './AuthForm.module.css';
import GTInput from '../../components/form/GTInput/GTInput';
import GTLink from '../../components/GTLink/GTLink';
import GTButton from '../../components/GTButton/GTButton';
import { emailPattern } from '../../utils/regExtPatterns';
import { AUTH_FORGOT_ROUTE, AUTH_WELCOME_ROUTE, BASE_ROUTE } from '../../router/routesConstants';

type SignInFormInputs = {
  email: string
  password: string
};

function SignInForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignInFormInputs>();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState('');
  const navigate = useNavigate();

  const isReadyToSubmit = !!(watch('email') && watch('password'));

  const handleRequestError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      case 'Incorrect username or password.':
      case 'User does not exist.': {
        setRequestError('Email or password incorrect. Please try again.');
        break;
      }
      default: {
        setRequestError(errorMessage);
      }
    }
  }, []);

  const onSubmit: SubmitHandler<SignInFormInputs> = async (data) => {
    try {
      setRequestError('');
      setIsSubmitting(true);
      const { nextStep } = await signIn({ username: data.email, password: data.password });
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate(AUTH_WELCOME_ROUTE, { replace: true });
      } else {
        navigate(BASE_ROUTE, { replace: true });
      }
    } catch (e: unknown) {
      handleRequestError((e as { message?: string }).message || 'An error has occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrap}>
      <h2 className={styles.title}>Sign In</h2>
      <GTInput
        className={styles.input}
        id="signInEmail"
        label="Email"
        placeholder="Enter your email"
        error={!!errors.email}
        errorMessage={errors.email?.message}
        {...register('email', {
          required: 'This field is required',
          pattern: { value: emailPattern, message: 'Incorrect email format' },
        })}
      />
      <GTInput
        className={styles.input}
        id="signInPassword"
        error={!!errors.password}
        errorMessage={errors.password?.message}
        {...register('password', { required: 'This field is required' })}
        label="Password"
        placeholder="Enter your password"
        type={isShowPassword ? 'text' : 'password'}
        endAdornment={
        isShowPassword
          ? <VisibilityOffOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowPassword(false)} />
          : <VisibilityOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowPassword(true)} />
      }
      />
      <GTLink style={{ marginRight: 'auto' }} to={AUTH_FORGOT_ROUTE}>Forgot password?</GTLink>
      {requestError && <p className={styles.error}>{requestError}</p>}
      <GTButton color="primary" style={{ marginTop: 40 }} disabled={!isReadyToSubmit || isSubmitting} type="submit">
        {isSubmitting ? <CircularProgress size={16} /> : 'Sign In'}
      </GTButton>
    </form>
  );
}

export default SignInForm;
