import { useFormContext } from 'react-hook-form';
import GTRadioSelect, { GTRadioSelectOption } from '../../components/form/GTRadioSelect/GTRadioSelect';
import { MatchFormInputs } from './types';
import {
  MatchCoverType,
  MatchElevationType,
  MatchTemperatureType, MatchTimeOfDayType,
  MatchVenueType,
  MatchWindType,
} from '../../store/projects/types';

const timeOfDayOptions: GTRadioSelectOption[] = [
  { title: 'Day', value: MatchTimeOfDayType.DAY },
  { title: 'Night', value: MatchTimeOfDayType.NIGHT },
];

const elevationOptions: GTRadioSelectOption[] = [
  { title: 'Normal', value: MatchElevationType.NORMAL },
  { title: 'High', value: MatchElevationType.HIGH },
];

const venueOptions: GTRadioSelectOption[] = [
  { title: 'Indoor', value: MatchVenueType.INDOOR },
  { title: 'Outdoor', value: MatchVenueType.OUTDOOR },
  { title: 'Mixed', value: MatchVenueType.MIXED },
];

const surfaceOptions: GTRadioSelectOption[] = [
  { title: 'Hard', value: MatchCoverType.HARD },
  { title: 'Red Clay', value: MatchCoverType.RED_CLAY },
  { title: 'Har-Tru', value: MatchCoverType.HAR_TRU },
  { title: 'Grass', value: MatchCoverType.GRASS },
  { title: 'Sythetic', value: MatchCoverType.SYTHETIC },
  { title: 'Carpet', value: MatchCoverType.CARPET },
  { title: 'Other', value: MatchCoverType.OTHER },
];

const windOptions: GTRadioSelectOption[] = [
  { title: 'None', value: MatchWindType.NONE },
  { title: 'Low', value: MatchWindType.LOW },
  { title: 'Moderate', value: MatchWindType.MODERATE },
  { title: 'High', value: MatchWindType.HIGH },
  { title: 'Extreme', value: MatchWindType.EXTREME },
];

const temperatureOptions: GTRadioSelectOption[] = [
  { title: 'Cold', value: MatchTemperatureType.COLD },
  { title: 'Cool', value: MatchTemperatureType.COOL },
  { title: 'Warm', value: MatchTemperatureType.WARM },
  { title: 'Hot', value: MatchTemperatureType.HOT },
  { title: 'Extreme', value: MatchTemperatureType.EXTREME },
];

function MatchCreatorStepConditions() {
  const { register } = useFormContext<MatchFormInputs>();

  return (
    <>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <GTRadioSelect registerProps={register('timeOfDay')} options={timeOfDayOptions} label="Time of the Day" />
        <GTRadioSelect registerProps={register('elevation')} options={elevationOptions} label="Elevation" />
      </div>
      <GTRadioSelect registerProps={register('venue')} options={venueOptions} label="Venue" />
      <GTRadioSelect registerProps={register('coverType')} options={surfaceOptions} label="Surface" />
      <GTRadioSelect registerProps={register('wind')} options={windOptions} label="Wind" />
      <GTRadioSelect registerProps={register('temperature')} options={temperatureOptions} label="Temperature" />
    </>
  );
}

export default MatchCreatorStepConditions;
