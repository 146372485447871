import {
  useCallback, useContext, useState,
} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import styles from './MatchDetails.module.css';
import GTButton from '../../components/GTButton/GTButton';
import GTIconButton from '../../components/GTIconButton/GTIconButton';
import EditIcon from '../../components/MaterialSymbolsIcons/EditIcon';
import { selectProject } from '../../store/currentProject/slice';
import MatchCreator from '../MatchCreator/MatchCreator';
import GTModal from '../../components/GTModal/GTModal';
import {
  applyProjectAnnotations,
  updateProjectAnnotationsAndProjection,
  uploadJsonResultsForProject,
} from '../../api/projects';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import { useAppDispatch } from '../../store';
import MatchDetailsContext from '../MatchCreator/MatchDetailsContext';
import { currentProjectGetDetailsThunk } from '../../store/currentProject/thunks';
import { useProjectionDataToSave } from './hooks/useProjectionDataToSave';
import { BASE_ROUTE } from '../../router/routesConstants';
import AnnotationService from '../../utils/services/AnnotationService';
import { ProjectDetails } from '../../store/projects/types';

function MatchDetailsHeader() {
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [isSavingConfirmModalOpen, setIsSavingConfirmModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCompletingConfirmModalOpen, setIsCompletingConfirmModalOpen] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const projectionData = useProjectionDataToSave();
  const dispatch = useAppDispatch();
  const context = useContext(MatchDetailsContext);

  const onBack = useCallback(() => {
    if (location.state?.from === 'list') {
      navigate(-1);
    } else {
      navigate(BASE_ROUTE);
    }
  }, [location.state, navigate]);

  const saveProject = useCallback(async () => {
    setIsSavingConfirmModalOpen(false);
    if (!context.via || !project) return;
    setIsSaving(true);
    try {
      // eslint-disable-next-line no-underscore-dangle
      const result = await context.via.d._GT_get_project_json();
      const projectionBlob = new Blob([JSON.stringify(projectionData)], { type: 'text/json' });
      const annotationsPromise = uploadJsonResultsForProject(
        project.projectId,
        new File([result.data], result.filename, { type: 'text/json' }),
      );
      const projectionPromise = uploadJsonResultsForProject(
        project.projectId,
        new File([projectionBlob], `court_projection_${new Date().getTime()}.json`, { type: 'text/json' }),
      );

      const annotationsItem = await annotationsPromise;
      const projectionItem = await projectionPromise;

      await updateProjectAnnotationsAndProjection(
        project.projectId,
        annotationsItem.key,
        projectionItem.key,
      );
    } catch (e) {
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'An error has occurred. Please try again.',
          type: 'error',
        },
      }));
    } finally {
      setIsSaving(false);
    }
  }, [projectionData, context.via, dispatch, project]);

  const completeProject = useCallback(async () => {
    setIsCompletingConfirmModalOpen(false);
    if (!project) return;

    setIsCompleting(true);
    try {
      await applyProjectAnnotations(project.projectId);
      onBack();
    } catch (e) {
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'An error has occurred. Please try again.',
          type: 'error',
        },
      }));
    } finally {
      setIsCompleting(false);
    }
  }, [dispatch, onBack, project]);

  const onEditDoneCallback = useCallback(async () => {
    if (project) {
      const projectDetails = await dispatch(currentProjectGetDetailsThunk(project.projectId));
      const detailsPayload = projectDetails.payload as ProjectDetails;
      AnnotationService.current.updatePlayerNames(
        `${detailsPayload.player1.firstName} ${detailsPayload.player1.lastName}`,
        `${detailsPayload.player2.firstName} ${detailsPayload.player2.lastName}`,
      );
    }
  }, [dispatch, project]);

  return (
    <nav className={styles.header}>
      <GTButton onClick={onBack} color="secondary" style={{ marginRight: 'auto' }}>
        <KeyboardArrowLeftIcon style={{ marginRight: 8 }} />
        Back
      </GTButton>
      {project && (
      <div className={styles.headerInfo}>
        <div className={styles.headerInfoSubwrap}>
          <h4 className={styles.headerInfoPlayers}>
            {project?.player1?.firstName}
            {' '}
            {project?.player1?.lastName}
            {' '}
            •
            {' '}
            {project?.player2?.firstName}
            {' '}
            {project?.player2?.lastName}
          </h4>
          <p className={styles.headerInfoTournament}>
            {project?.tournamentName}
          </p>
        </div>
        <hr />
        <GTIconButton hasHover onClick={() => setIsCreatorOpen(true)}>
          <EditIcon />
        </GTIconButton>
        <MatchCreator open={isCreatorOpen} onOpenChange={setIsCreatorOpen} project={project} onEditDoneCallback={onEditDoneCallback} />
      </div>
      )}
      <GTButton onClick={() => setIsSavingConfirmModalOpen(true)} color="secondary" style={{ marginLeft: 'auto' }}>Save</GTButton>
      <GTButton onClick={() => setIsCompletingConfirmModalOpen(true)} color="primary">Complete</GTButton>

      <GTModal
        open={isSavingConfirmModalOpen}
        title="Save changes"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton onClick={() => setIsSavingConfirmModalOpen(false)} color="secondary">Cancel</GTButton>
            <GTButton onClick={saveProject} style={{ marginLeft: 8 }} color="primary">Save</GTButton>
          </div>
        )}
      >
        <p>
          Are you sure you want to save match? It will be saved
          <br />
          with your changes and you can back to editing.
        </p>
      </GTModal>

      <GTModal
        open={isCompletingConfirmModalOpen}
        title="Complete match"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton onClick={() => setIsCompletingConfirmModalOpen(false)} color="secondary">Cancel</GTButton>
            <GTButton onClick={completeProject} style={{ marginLeft: 8 }} color="primary">Complete</GTButton>
          </div>
        )}
      >
        <p>
          Are you sure you want to finish editing this match?
          Annotations will no longer be available for editing
          after completion. You will only be able to work with
          completed report.
        </p>
      </GTModal>

      <GTModal open={isSaving || isCompleting}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center' }}>
            {isSaving && 'Saving changes. Please wait and don’t close the tab.'}
            {isCompleting && 'Completing the match. Please wait and don’t close the tab.'}
          </p>
          <CircularProgress style={{ margin: '24px auto 0' }} size={40} />
        </div>
      </GTModal>
    </nav>
  );
}

export default MatchDetailsHeader;
