import { useMemo } from 'react';
import classnames from 'classnames';
import styles from './UserAvatar.module.css';

type AnotherUserAvatarProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  name: string,
  avatarSrc?: string
};

function AnotherUserAvatar({
  avatarSrc, name, className, ...props
}: AnotherUserAvatarProps) {
  const avatarPath = avatarSrc ? `https://d3kn021kxuyfai.cloudfront.net${avatarSrc}` : '';

  const userInitials = useMemo(() => {
    const nameSplit = name.split(' ');

    return `${nameSplit[0].charAt(0)}${nameSplit[1]?.charAt(0) || ''}`;
  }, [name]);

  return (
    <div className={classnames(styles.avatar, className)} {...props}>
      {avatarPath ? <img src={avatarPath} alt="" /> : userInitials}
    </div>
  );
}

export default AnotherUserAvatar;
