import { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import GTModal from '../../components/GTModal/GTModal';
import GTButton from '../../components/GTButton/GTButton';
import { editProject, EditProjectPayload } from '../../api/projects';
import { MatchFormInputs } from './types';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import { useAppDispatch } from '../../store';

type MatchCreatorEditingProcessProps = {
  open: boolean,
  onDone: () => Promise<void>,
  onDiscard: () => void,
  onError: () => void,
  projectId: string,
};

function MatchCreatorEditingProcess({
  open, onDone, onDiscard, onError, projectId,
}: MatchCreatorEditingProcessProps) {
  const dispatch = useAppDispatch();
  const [isProcessModalOpened, setIsProcessModalOpened] = useState(open);
  const [isMatchEditingModalOpened, setIsMatchEditingModalOpened] = useState(false);

  useEffect(() => {
    setIsProcessModalOpened(open);
  }, [open]);

  const { getValues } = useFormContext<MatchFormInputs>();

  const resetState = useCallback(() => {
    setIsMatchEditingModalOpened(false);
  }, []);

  const onProcess = useCallback(async () => {
    if (!projectId) return;

    try {
      setIsProcessModalOpened(false);
      setIsMatchEditingModalOpened(true);
      const formValues = getValues();
      const projectDataPayload: EditProjectPayload = {
        tournamentName: formValues.tournamentName,
        tournamentDate: formValues.tournamentDate,
        location: formValues.location,
        measurementSystem: formValues.measurementSystem || undefined,
        roundOfPlay: formValues.roundOfPlay || undefined,
        matchType: formValues.matchType,
        scoringSystem: formValues.scoringSystem || undefined,
        timeOfDay: formValues.timeOfDay,
        elevation: formValues.elevation,
        venue: formValues.venue,
        coverType: formValues.coverType,
        wind: formValues.wind,
        temperature: formValues.temperature,
        player1: {
          firstName: formValues.player1FirstName,
          lastName: formValues.player1LastName,
          gender: formValues.player1Gender,
          dominantHand: formValues.player1DominantHand,
          ageGroup: formValues.player1AgeGroup,
          skillLevel: formValues.player1SkillLevel,
        },
        player2: {
          firstName: formValues.player2FirstName,
          lastName: formValues.player2LastName,
          gender: formValues.player2Gender,
          dominantHand: formValues.player2DominantHand,
          ageGroup: formValues.player2AgeGroup,
          skillLevel: formValues.player2SkillLevel,
        },
      };
      await editProject(projectId, projectDataPayload);
      await onDone();
    } catch (e) {
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Editing failed. Please check the project info.',
          type: 'error',
        },
      }));
      onError();
    } finally {
      resetState();
    }
  }, [dispatch, getValues, onDone, onError, projectId, resetState]);

  return (
    <>
      <GTModal
        open={isProcessModalOpened}
        title="Save changes"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton onClick={onDiscard} color="secondary">Cancel</GTButton>
            <GTButton onClick={onProcess} style={{ marginLeft: 8 }} color="primary">Save</GTButton>
          </div>
     )}
      >
        <p>Are you sure you want to save changes?</p>
      </GTModal>

      <GTModal open={isMatchEditingModalOpened}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center' }}>
            Saving changes. Please wait and don’t close the tab.
          </p>
          <CircularProgress style={{ margin: '24px auto 0' }} size={40} />
        </div>
      </GTModal>
    </>
  );
}

export default MatchCreatorEditingProcess;
