import { createContext } from 'react';
import { ProjectDetails } from '../../store/projects/types';

export interface ProjectsListContextType {
  onMatchCreatorOpenChange: ((value: boolean, project?: ProjectDetails) => void) | null
  onProjectHistoryOpenChange: ((value: boolean, project?: ProjectDetails) => void) | null
  getProjects: (() => Promise<void>) | null
}

const ProjectsListContext = createContext<ProjectsListContextType>({
  onMatchCreatorOpenChange: null,
  onProjectHistoryOpenChange: null,
  getProjects: null,
});

export default ProjectsListContext;
