import { createSlice } from '@reduxjs/toolkit';
import { currentUserGetDetailsThunk } from './thunks';
import { User } from '../users/types';

const initialState: { user: User | null, pending: boolean } = {
  user: null,
  pending: false,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(currentUserGetDetailsThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(currentUserGetDetailsThunk.rejected, (state) => ({
      ...state,
      pending: false,
    }));
    builder.addCase(currentUserGetDetailsThunk.fulfilled, (state, payload) => ({
      ...state,
      pending: false,
      user: { ...payload.payload },
    }));
  },
  selectors: {
    selectCurrentUser: (state) => state.user,
    selectCurrentUserApiPending: (state) => state.pending,
  },
});

export const { selectCurrentUser, selectCurrentUserApiPending } = currentUserSlice.selectors;

export const currentProjectSliceActions = currentUserSlice.actions;

export default currentUserSlice.reducer;
