
export interface ReportShot {

  CustomAnalytics?: {
    Category?: string
  }

  ServeCount?: number;

  _eventType?: ShotEventType
  _point?: ReportPoint;
  _isDoubleFault?: boolean;
  _positionX: number;
  _positionY: number;
  ShotId: number;
  AppShotId: string;
  AppPointId: string;
  PointId: number;
  OrderInt: number;
  DateCreated: string;
  Type: ShotType;
  XPos: number;
  YPos: number;
  IsFinal: boolean;
  MentalState?: string;
  PhysicalState?: string;
  FinalShotFamily?: string;
  FinalSituation?: string;
  FinalShotSide?: string;
  FinalMode?: string;
  FinalSpin?: string;
  FinalShotType?: string;
  PointWon?: boolean;
  HowPointWon?: HowPointWon;
  HowPointLost?: string;
  VideoTimestamp?: number;
  IsFlagged?: boolean;
  IsFavorite?: boolean;
}

export interface ReportPoint {
  PointId: number;
  AppPointId: string;
  AppGameId: string;
  GameId: number;
  OrderInt: number;
  DateCreated: string;
  PlayerWon: boolean;
  IsServer: boolean;
  ServeSide: string;
  ServeCount: number;
  StartXPos1: number;
  StartYPos1?: number;
  StartXPos2?: number;
  StartYPos2?: number;
  PlayerWonString: string;
  Shots: ReportShot[];
}

export interface ReportGame {
  GameId: number;
  AppGameId: string;
  AppSetId: string;
  SetId: number;
  OrderInt: number;
  DateCreated: string;
  PlayerWon: boolean;
  ServingString: string;
  PlayerWonString: string;
  Points: ReportPoint[];
}

export interface ReportSet {
  SetId: number;
  AppSetId: string;
  MatchId: number;
  OrderInt: number;
  DateCreated: string;
  PlayerWon?: boolean;
  GamesPerSet: number;
  MaxGamesPerSet: number;
  GameMargin: number;
  PointsPerGame: number;
  PointMargin: number;
  Tiebreak: boolean;
  TiebreakPoints: number;
  TiebreakMargin: number;
  PlayerWonString?: string;
  Games: ReportGame[];
}

export interface ReportMatch {
  MatchId: number;
  AppMatchId: string;
  UserId: number;
  IsActive: boolean;
  PlayerFirstName: string;
  PlayerLastName: string;
  PlayerGender: string;
  PlayerHand: string;
  PlayerAge: string;
  PlayerSkill: string;
  OpponentFirstName: string;
  OpponentLastName: string;
  OpponentGender: string;
  OpponentHand: string;
  OpponentAge: string;
  OpponentSkill: string;
  ScoringPreset: string;
  MatchType: string;
  TournamentName: string;
  TournamentNameOther: string;
  RoundOfPlay: string;
  CourtLogo: string;
  MatchCountry: string;
  MatchState: string;
  MatchCity: string;
  Measurement: string;
  MatchDate: string;
  TimeOfDay: string;
  Venue: string;
  Lighting: string;
  Surface: string;
  Wind: string;
  Temperature: string;
  Notes: string;
  PlayerServedFirst: number;
  MatchStatus: string;
  VideoId: string;
  VideoStartTimestamp: number;
  DateCreated: string;
  HasVideo: boolean;
  PlayerFullName: string;
  OpponentFullName: string;
  MatchDateFormatted: string;
  DateCreatedFormatted: string;
  Sets: ReportSet[];
}

export enum HowPointWon {
  Unreturnable = "unreturnable",
  ForcingShot = "forcingshot",
  OpponentsUnforcedError = "opponentsunforcederror",
  Winner = "winner",
  ForecedError = 'forcederror'
}

export enum ShotType {
  Serve = "serve",
  InPlay = "inplay",
  ReturnOfServe = "returnofserve",

  // ????
  Fault = "fault",
  Won = "won",
  Lost = "lost"
}

export enum ShotEventType {
  WinnerUnreturnable,
  AceUnreturnable,
  Forcing,
  ForceError,
  UnforceError,
  Other,
  Fault,
  DoubleFault
}