import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Button, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HistoryIcon from '@mui/icons-material/History';
import { useAppDispatch } from '../../../store';
import DeleteIcon from '../../../components/MaterialSymbolsIcons/DeleteIcon';
import GTModal from '../../../components/GTModal/GTModal';
import GTButton from '../../../components/GTButton/GTButton';
import { appAlertsSliceActions } from '../../../store/appAlerts/slice';
import { deleteProject, startProcessingTheProject } from '../../../api/projects';
import { ProjectDetails } from '../../../store/projects/types';
import ProjectsListContext from '../ProjectsListContext';
import EditIcon from '../../../components/MaterialSymbolsIcons/EditIcon';

function ProjectMenu({ projectId, project, verticalIcon }: { projectId: string, project?: ProjectDetails, verticalIcon?: boolean }) {
  const dispatch = useAppDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isReprocessModalOpen, setIsReprocessModalOpen] = useState(false);
  const [isReprocessInitiating, setIsReprocessInitiating] = useState(false);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);
  const context = useContext(ProjectsListContext);

  const isCanBeReprocessed = useMemo(() => project?.status === 'FAILED' || project?.status === 'READY_FOR_REVIEW', [project]);

  const handleBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback((event: React.MouseEvent) => {
    event?.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const onDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      await deleteProject(projectId);
      if (context.getProjects) {
        await context.getProjects();
      }
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Match deleted successfully',
          type: 'delete',
        },
      }));
    } finally {
      setIsDeleting(false);
    }
  }, [context, dispatch, projectId]);

  const onEdit = useCallback(async () => {
    if (!project) return;
    setMenuAnchorEl(null);
    context.onMatchCreatorOpenChange?.(true, project);
  }, [context, project]);

  const onHistory = useCallback(async () => {
    if (!project) return;
    setMenuAnchorEl(null);
    context.onProjectHistoryOpenChange?.(true, project);
  }, [context, project]);

  const onReprocess = useCallback(async () => {
    if (!project) return;

    setIsReprocessInitiating(true);
    try {
      await startProcessingTheProject(projectId, project.sourceVideoPath, true);
      if (context.getProjects) {
        await context.getProjects();
      }
      setIsReprocessModalOpen(false);
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Match pushed to reprocessing successfully',
          type: 'success',
        },
      }));
    } finally {
      setIsReprocessInitiating(false);
    }
  }, [project, projectId, context, dispatch]);

  return (
    <>
      <Button
        onClick={handleBtnClick}
        style={{
          color: 'inherit', height: 40, width: 40, minWidth: 40, padding: 0,
        }}
      >
        {verticalIcon ? <MoreVertIcon fontSize="medium" /> : <MoreHorizIcon fontSize="medium" />}
      </Button>
      <Menu
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {isCanBeReprocessed && (
          <MenuItem
            sx={{ fontWeight: 500, width: 220 }}
            onClick={() => setIsReprocessModalOpen(true)}
          >
            <AutorenewIcon style={{ marginRight: 8, fontSize: 22 }} />
            Reprocess
          </MenuItem>
        )}

        {project && (
          <MenuItem
            sx={{ fontWeight: 500, width: 220 }}
            onClick={onEdit}
          >
            <EditIcon style={{ marginRight: 8, fontSize: 16 }} />
            Edit match details
          </MenuItem>
        )}

        {project && (
          <MenuItem
            sx={{ fontWeight: 500, width: 220 }}
            onClick={onHistory}
          >
            <HistoryIcon style={{
              width: 24, height: 24, marginRight: 8, fontSize: 16,
            }}
            />
            Version History
          </MenuItem>
        )}

        <MenuItem
          sx={{ color: 'var(--color-accent-red)', fontWeight: 500, width: 220 }}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <DeleteIcon style={{ marginRight: 8, fontSize: 16 }} />
          Delete Match
        </MenuItem>

        <GTModal
          open={isDeleteModalOpen}
          title="Delete match"
          footer={(
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GTButton onClick={() => setIsDeleteModalOpen(false)} color="secondary">Cancel</GTButton>
              <GTButton pending={isDeleting} disabled={isDeleting} onClick={onDelete} style={{ marginLeft: 8 }} color="warning">Delete</GTButton>
            </div>
          )}
        >
          <p>Are you sure you want to delete this match?</p>
        </GTModal>

        <GTModal
          open={isReprocessModalOpen}
          title="Reprocess match"
          footer={(
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GTButton disabled={isReprocessInitiating} onClick={() => setIsReprocessModalOpen(false)} color="secondary">Cancel</GTButton>
              <GTButton disabled={isReprocessInitiating} onClick={onReprocess} style={{ marginLeft: 8 }} color="primary">Reprocess</GTButton>
            </div>
          )}
        >
          <p>Are you sure you want to reprocess the match?</p>
        </GTModal>
      </Menu>
    </>
  );
}

export default ProjectMenu;
