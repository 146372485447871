import {
  useCallback, useEffect, useState,
} from 'react';
import { UploadDataOutput } from 'aws-amplify/storage';
import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import prettyBytes from 'pretty-bytes';
import GTModal from '../../components/GTModal/GTModal';
import GTButton from '../../components/GTButton/GTButton';
import {
  startProcessingTheProject,
  createProjectV2,
  CreateProjectV2Payload,
  uploadVideoForProjectV2,
} from '../../api/projects';
import { MatchFormInputs } from './types';
import styles from './MatchCreator.module.css';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import { useAppDispatch } from '../../store';

type MatchCreatorProcessingProps = {
  open: boolean,
  onDone: () => void,
  onDiscard: () => void,
  onError: () => void,
};

function MatchCreatorProcessing({
  open, onDone, onDiscard, onError,
}: MatchCreatorProcessingProps) {
  const dispatch = useAppDispatch();
  const [isProcessModalOpened, setIsProcessModalOpened] = useState(open);
  const [isFileUploadModalOpened, setIsFileUploadModalOpened] = useState(false);
  const [isMatchCreationModalOpened, setIsMatchCreationModalOpened] = useState(false);
  const [isUploadDiscardModalOpened, setIsUploadDiscardModalOpened] = useState(false);
  const [videoUploadInfo, setVideoUploadInfo] = useState({
    name: '',
    size: 0,
    progress: 0,
  });
  const [uploadJob, setUploadJob] = useState<UploadDataOutput | null>(null);

  useEffect(() => {
    setIsProcessModalOpened(open);
  }, [open]);

  const { getValues } = useFormContext<MatchFormInputs>();

  const resetState = useCallback(() => {
    setIsFileUploadModalOpened(false);
    setIsMatchCreationModalOpened(false);
    setIsUploadDiscardModalOpened(false);
    setVideoUploadInfo({
      name: '',
      size: 0,
      progress: 0,
    });
    setUploadJob(null);
  }, []);

  const openUploadDiscardModal = useCallback(() => {
    if (uploadJob) {
      uploadJob.pause();
    }
    setIsUploadDiscardModalOpened(true);
  }, [uploadJob]);

  const onContinueUpload = useCallback(() => {
    if (uploadJob) {
      uploadJob.resume();
    }
    setIsUploadDiscardModalOpened(false);
  }, [uploadJob]);

  const onUploadDiscard = useCallback(() => {
    if (uploadJob) {
      uploadJob.cancel();
    }
    resetState();
    onDiscard();
  }, [onDiscard, resetState, uploadJob]);

  const processVideo = useCallback(async () => {
    const formValues = getValues();
    let sourceVideoPath: string;
    if (formValues.videoSource === 'Link') {
      sourceVideoPath = formValues.videoLink;
    } else {
      const fileList = formValues.videoFile as FileList;
      setVideoUploadInfo({
        ...videoUploadInfo,
        name: fileList[0].name,
        size: fileList[0].size,
      });

      const filePath = `tmp/${new Date().getTime()}/${fileList[0].name}`;
      const videoUploadTask = uploadVideoForProjectV2(fileList[0], filePath, (e) => setVideoUploadInfo({
        name: fileList[0].name,
        size: fileList[0].size,
        progress: Math.round(e.transferredBytes / (e.totalBytes || 1)) * 100,
      }));
      setUploadJob(videoUploadTask);
      await videoUploadTask.result;
      setUploadJob(null);
      sourceVideoPath = `/public/${filePath}`;
    }

    return sourceVideoPath;
  }, [getValues, videoUploadInfo]);

  const onProcess = useCallback(async () => {
    try {
      setIsProcessModalOpened(false);
      setIsFileUploadModalOpened(true);
      const sourceVideoPath = await processVideo();
      setIsFileUploadModalOpened(false);
      setIsMatchCreationModalOpened(true);
      const formValues = getValues();
      const projectDataPayload: CreateProjectV2Payload = {
        tournamentName: formValues.tournamentName,
        tournamentDate: formValues.tournamentDate,
        location: formValues.location,
        measurementSystem: formValues.measurementSystem || undefined,
        roundOfPlay: formValues.roundOfPlay || undefined,
        matchType: formValues.matchType,
        sourceVideoPath,
        scoringSystem: formValues.scoringSystem || undefined,
        timeOfDay: formValues.timeOfDay,
        elevation: formValues.elevation,
        venue: formValues.venue,
        coverType: formValues.coverType,
        wind: formValues.wind,
        temperature: formValues.temperature,
        player1: {
          firstName: formValues.player1FirstName,
          lastName: formValues.player1LastName,
          gender: formValues.player1Gender,
          dominantHand: formValues.player1DominantHand,
          ageGroup: formValues.player1AgeGroup,
          skillLevel: formValues.player1SkillLevel,
        },
        player2: {
          firstName: formValues.player2FirstName,
          lastName: formValues.player2LastName,
          gender: formValues.player2Gender,
          dominantHand: formValues.player2DominantHand,
          ageGroup: formValues.player2AgeGroup,
          skillLevel: formValues.player2SkillLevel,
        },
      };
      const projectInfo = await createProjectV2(projectDataPayload);
      await startProcessingTheProject(projectInfo.data.projectId, projectInfo.data.sourceVideoPath, false);
      onDone();
    } catch (e) {
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Processing failed. Please check if the uploaded video is valid.',
          type: 'error',
        },
      }));
      onError();
    } finally {
      resetState();
    }
  }, [dispatch, getValues, onDone, processVideo, resetState, onError]);

  return (
    <>
      <GTModal
        open={isProcessModalOpened}
        title="Save and process match"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton onClick={onDiscard} color="secondary">Discard</GTButton>
            <GTButton onClick={onProcess} style={{ marginLeft: 8 }} color="primary">Save and Process</GTButton>
          </div>
     )}
      >
        <p>
          Are you sure you want to process this match?
          <br />
          Processing will take some time. You will be notified
          {' '}
          <br />
          by email once the match is ready for review.
        </p>
      </GTModal>

      <GTModal
        open={isFileUploadModalOpened}
        onClose={openUploadDiscardModal}
        title="Uploading video file"
      >
        <>
          <p>
            You've uploaded the video file in the first step.
            It may take some time to prepare before processing.
            Please don’t close the browser until the loading is complete.
          </p>
          <h4 className={styles.uploadInfoName}>{videoUploadInfo.name}</h4>
          <div className={styles.uploadInfoParams}>
            <span>{prettyBytes(videoUploadInfo.size)}</span>
            <span>
              {videoUploadInfo.progress}
              %
            </span>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progressBarFill} style={{ width: `${videoUploadInfo.progress}%` }} />
          </div>
        </>
      </GTModal>

      <GTModal
        open={isUploadDiscardModalOpened}
        title="Discard video file uploading"
        footer={(
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GTButton onClick={onContinueUpload} color="secondary">Cancel</GTButton>
            <GTButton onClick={onUploadDiscard} style={{ marginLeft: 8 }} color="warning">Discard</GTButton>
          </div>
        )}
      >
        <p>
          You are going to discard video file uploading.
          The match will not proceed to processing.
          You can back to editing details.
          Are you sure you want to discard video file uploading?
        </p>
      </GTModal>

      <GTModal open={isMatchCreationModalOpened}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center' }}>
            Video file uploaded. Creating the project for
            {' '}
            <br />
            processing. Please wait and don’t close the tab.
          </p>
          <CircularProgress style={{ margin: '24px auto 0' }} size={40} />
        </div>
      </GTModal>
    </>
  );
}

export default MatchCreatorProcessing;
