import { useCallback, useEffect, useState } from 'react';
import { confirmSignIn } from 'aws-amplify/auth';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GTButton from '../../components/GTButton/GTButton';
import { passwordPattern } from '../../utils/regExtPatterns';
import GTInput from '../../components/form/GTInput/GTInput';
import styles from './AuthForm.module.css';
import { authAlertsSliceActions } from '../../store/authAlerts/slice';
import { AUTH_BASE_ROUTE, BASE_ROUTE } from '../../router/routesConstants';

type PasswordChangeInputs = {
  newPassword: string
  confirmPassword: string
};

function InvitationPasswordForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<PasswordChangeInputs>();
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isReadyToSubmit = !!(watch('newPassword') && watch('confirmPassword'));

  useEffect(() => {
    if (!location.state?.email) {
      navigate(AUTH_BASE_ROUTE);
    }
  }, [location.state?.email, navigate]);

  const handleRequestError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      default: {
        setRequestError(errorMessage);
      }
    }
  }, []);

  const onSubmit: SubmitHandler<PasswordChangeInputs> = async (data) => {
    try {
      setRequestError('');
      setIsSubmitting(true);
      await confirmSignIn({ challengeResponse: data.newPassword });
      dispatch(authAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'Password successfully changed. You can sign in with a new password.',
          type: 'success',
        },
      }));
      navigate(BASE_ROUTE);
    } catch (e: unknown) {
      handleRequestError((e as { message?: string }).message || 'An error has occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrap}>
      <h2 className={styles.title} style={{ marginBottom: 16 }}>Welcome to GottaTennis!</h2>
      <p className={styles.text} style={{ marginBottom: 40 }}>
        You have been invited to
        {' '}
        {location.state?.email || 'email@example.com'}
        {' '}
        email
        address. Please set a password to access the app.
      </p>
      <GTInput
        className={styles.input}
        id="newPassword"
        label="New password"
        placeholder="Enter your password"
        error={!!errors.newPassword}
        errorMessage={errors.newPassword?.message}
        type={isShowNewPassword ? 'text' : 'password'}
        {...register('newPassword', {
          required: 'This field is required',
          pattern: { value: passwordPattern, message: 'Incorrect password format' },
        })}
        endAdornment={
          isShowNewPassword
            ? <VisibilityOffOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowNewPassword(false)} />
            : <VisibilityOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowNewPassword(true)} />
        }
      />
      <GTInput
        className={styles.input}
        id="confirmPassword"
        error={!!errors.confirmPassword}
        errorMessage={errors.confirmPassword?.message}
        {...register('confirmPassword', {
          required: 'This field is required',
          pattern: { value: passwordPattern, message: 'Incorrect password format' },
          validate: {
            equalsPassword: (value) => getValues('newPassword') === value || 'Passwords do not match. Try again.',
          },
        })}
        label="Confirm new password"
        placeholder="Confirm your password"
        type={isShowConfirmPassword ? 'text' : 'password'}
        endAdornment={
          isShowConfirmPassword
            ? <VisibilityOffOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowConfirmPassword(false)} />
            : <VisibilityOutlined style={{ cursor: 'pointer', color: '#75758A' }} onClick={() => setIsShowConfirmPassword(true)} />
        }
      />
      <p className={styles.hint}>
        Password must contain at least 8 characters, including
        <br />
        1 uppercase letter, 1 number, and 1 symbol (!, $, @, *, etc).
      </p>

      {requestError && <p className={styles.error}>{requestError}</p>}
      <GTButton color="primary" style={{ marginTop: 40 }} disabled={!isReadyToSubmit || isSubmitting} type="submit">
        {isSubmitting ? <CircularProgress size={16} /> : 'Continue'}
      </GTButton>
    </form>
  );
}

export default InvitationPasswordForm;
