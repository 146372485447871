export const MatchDetailsBallFiltersStepsNames = {
  ballAction: 'ballAction',
  wonorlost: 'wonorlost',
  shotfamily: 'shotfamily',
  situation: 'situation',
  side: 'side',
  howwon: 'howwon',
  howlost: 'howlost',
  shottype: 'shottype',
  spin: 'spin',
  mode: 'mode',
};

export type MatchDetailsBallFiltersStepsKeys = keyof typeof MatchDetailsBallFiltersStepsNames;

export const MatchDetailsBallFiltersBallActionValues = {
  Hit: 'hit',
  Bounce: 'bounce',
};

export const MatchDetailsBallFiltersWonOrLostValues = {
  winshot: 'winshot',
  lostshot: 'lostshot',
};

export const MatchDetailsBallFiltersShotFamilyValues = {
  serve: 'serve',
  returnofsave: 'returnofsave',
  groundstroke: 'groundstroke',
  volley: 'volley',
  overhead: 'overhead',
};

export const MatchDetailsBallFiltersSituationValues = {
  deuceCourt: 'deuceCourt',
  adCourt: 'adCourt',
  baseline: 'baseline',
  atnet: 'atnet',
  approaching: 'approaching',
  backcourt: 'backcourt',
  passing: 'passing',
  beforeBounce: 'beforeBounce',
  afterBounce: 'afterBounce',
};

export const MatchDetailsBallFiltersSideValues = {
  forehand: 'forehand',
  backhand: 'backhand',
};

export const MatchDetailsBallFiltersHowWonValues = {
  winner: 'winner',
  unreturnable: 'unreturnable',
  forcingshot: 'forcingshot',
  opponentsunforcederror: 'opponentsunforcederror',
};

export const MatchDetailsBallFiltersHowLostValues = {
  opponentswinner: 'opponentswinner',
  unreturnable: 'unreturnable',
  forcederror: 'forcederror',
  unforcederror: 'unforcederror',
};

export const MatchDetailsBallFiltersShotTypeValues = {
  s_flat: 's_flat',
  s_topspin: 's_topspin',
  s_slice: 's_slice',
  s_kick: 's_kick',
  s_slicekick: 's_slicekick',
  s_sidespin: 's_sidespin',
  rs_drive: 'rs_drive',
  rs_arc: 'rs_arc',
  rs_loop: 'rs_loop',
  rs_lob: 'rs_lob',
  rs_angle: 'rs_angle',
  rs_updown: 'rs_updown',
  rs_dipdrive: 'rs_dipdrive',
  rs_bender: 'rs_bender',
  rs_dropshot: 'rs_dropshot',
  rs_dipper: 'rs_dipper',
  gs_drive: 'gs_drive',
  gs_arc: 'gs_arc',
  gs_loop: 'gs_loop',
  gs_lob: 'gs_lob',
  gs_angle: 'gs_angle',
  gs_updown: 'gs_updown',
  gs_dipdrive: 'gs_dipdrive',
  gs_bender: 'gs_bender',
  gs_dropshot: 'gs_dropshot',
  gs_dipper: 'gs_dipper',
  v_standard: 'v_standard',
  v_swing: 'v_swing',
  v_half: 'v_half',
  v_drop: 'v_drop',
  v_lob: 'v_lob',
  o_power: 'o_power',
  o_placement: 'o_placement',
};

export const MatchDetailsBallFiltersSpinValues = {
  topspin: 'topspin',
  underspin: 'underspin',
  slice: 'slice',
  flat: 'flat',
};

export const MatchDetailsBallFiltersModeValues = {
  defend: 'defend',
  counterattack: 'counterattack',
  rally: 'rally',
  challenge: 'challenge',
  attack: 'attack',
};

export type MatchDetailsBallFiltersShotValues =
  keyof typeof MatchDetailsBallFiltersWonOrLostValues
  | keyof typeof MatchDetailsBallFiltersBallActionValues
  | keyof typeof MatchDetailsBallFiltersShotFamilyValues
  | keyof typeof MatchDetailsBallFiltersSituationValues
  | keyof typeof MatchDetailsBallFiltersSideValues
  | keyof typeof MatchDetailsBallFiltersHowWonValues
  | keyof typeof MatchDetailsBallFiltersHowLostValues
  | keyof typeof MatchDetailsBallFiltersShotTypeValues
  | keyof typeof MatchDetailsBallFiltersSpinValues
  | keyof typeof MatchDetailsBallFiltersModeValues;

type MatchDetailsBallFiltersStepOptionConditionBase<
  K extends string,
  V extends MatchDetailsBallFiltersShotValues> = Partial<Record<K, V[]>>;

export type MatchDetailsBallFiltersStepOptionCondition = MatchDetailsBallFiltersStepOptionConditionBase<
MatchDetailsBallFiltersStepsKeys,
MatchDetailsBallFiltersShotValues
>;

export type MatchDetailsBallFiltersStepOption = {
  label: string,
  value: MatchDetailsBallFiltersShotValues
  condition?: MatchDetailsBallFiltersStepOptionCondition
  breakOption?: boolean
};
