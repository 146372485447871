import { useCallback, useMemo, useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordChangeForm from './ForgotPasswordChangeForm';
import ForgotPasswordContext, { ForgotPasswordFormContextType } from './ForgotPasswordContext';

type ForgotPasswordSteps = 'email' | 'password';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [flowStep, setFlowStep] = useState<ForgotPasswordSteps>('email');

  const contextValue = useMemo<ForgotPasswordFormContextType>(() => ({
    username,
  }), [username]);

  const updateUsername = useCallback((value: string) => {
    setUsername(value);
    setFlowStep('password');
  }, []);

  return (
    <ForgotPasswordContext.Provider value={contextValue}>
      {flowStep === 'email' && <ForgotPasswordForm updateUsername={updateUsername} />}
      {flowStep === 'password' && <ForgotPasswordChangeForm />}
    </ForgotPasswordContext.Provider>
  );
}

export default ForgotPassword;
