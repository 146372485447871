import { useForm, SubmitHandler } from 'react-hook-form';
import { resetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { CircularProgress } from '@mui/material';
import styles from '../AuthForm.module.css';
import GTInput from '../../../components/form/GTInput/GTInput';
import GTButton from '../../../components/GTButton/GTButton';
import { emailPattern } from '../../../utils/regExtPatterns';

type SignInFormInputs = {
  email: string
};

type ForgotPasswordFormProps = {
  updateUsername: (username: string) => unknown
};

function ForgotPasswordForm({ updateUsername }: ForgotPasswordFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<SignInFormInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const isReadyToSubmit = !!watch('email');

  const handleRequestError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      default: {
        setRequestError(errorMessage);
      }
    }
  }, []);

  const onSubmit: SubmitHandler<SignInFormInputs> = async (data) => {
    try {
      setRequestError('');
      setIsSubmitting(true);
      await resetPassword({ username: data.email });
      setIsSuccess(true);
    } catch (e) {
      handleRequestError((e as { message?: string }).message || 'An error has occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onBack = useCallback(() => navigate('/auth'), [navigate]);

  const onNext = useCallback(() => updateUsername(getValues('email')), [getValues, updateUsername]);

  if (isSuccess) {
    return (
      <div className={styles.wrap}>
        <h2 className={styles.title} style={{ marginBottom: 16 }}>Check your email</h2>
        <p className={styles.text} style={{ marginBottom: 40 }}>
          Please check your email address and follow the
          {' '}
          <br />
          instructions to reset your password. An email with
          {' '}
          <br />
          instructions has been sent to
          {' '}
          {getValues('email')}
        </p>
        <GTButton color="secondary" onClick={onNext} type="button">
          Continue
        </GTButton>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrap}>
      <h2 className={styles.title} style={{ marginBottom: 16 }}>Forgot password?</h2>
      <p className={styles.text} style={{ marginBottom: 40 }}>
        Enter your email, and we will send you a link to reset
        <br />
        your current password and set a new one.
      </p>
      <GTInput
        className={styles.input}
        id="signInEmail"
        label="Email"
        placeholder="Enter your email"
        error={!!errors.email}
        errorMessage={errors.email?.message}
        {...register('email', {
          required: 'This field is required',
          pattern: { value: emailPattern, message: 'Incorrect email format' },
        })}
      />
      {requestError && <p className={styles.error}>{requestError}</p>}
      <GTButton color="primary" style={{ marginTop: 40 }} type="submit" disabled={!isReadyToSubmit || isSubmitting}>
        {isSubmitting ? <CircularProgress size={16} /> : 'Continue'}
      </GTButton>
      <GTButton color="secondary" onClick={onBack} style={{ marginTop: 16 }} type="button">
        Back
      </GTButton>
    </form>
  );
}

export default ForgotPasswordForm;
