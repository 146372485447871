import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Button, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HistoryIcon from '@mui/icons-material/History';
import { User } from '../../store/users/types';
import SettingsUserEditModal from './SettingsUserEditModal';
import { deactivateUser } from '../../api/user';
import { useAppDispatch } from '../../store';
import { appAlertsSliceActions } from '../../store/appAlerts/slice';
import EditIcon from '../../components/MaterialSymbolsIcons/EditIcon';
import DeleteIcon from '../../components/MaterialSymbolsIcons/DeleteIcon';
import GTModal from '../../components/GTModal/GTModal';
import GTButton from '../../components/GTButton/GTButton';
import { currentProjectSliceActions } from '../../store/users/slice';
import SettingsUserContext from './context';

function UsersTableMenu({ user }: { user: User }) {
  const dispatch = useAppDispatch();
  const context = useContext(SettingsUserContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeactivateModalOpened, setIsDeactivateModalOpened] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  const isCanBeReinvited = useMemo(() => !user.active, [user]);
  const isActive = useMemo(() => user.active, [user]);

  const handleBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleActivityClick = useCallback(() => {
    setMenuAnchorEl(null);
    context.onOpenActivityLog(user);
  }, [context, user]);

  const handleMenuClose = useCallback((event: React.MouseEvent) => {
    event?.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const onDeactivate = useCallback(async () => {
    setIsPending(true);
    try {
      await deactivateUser(user.id);
      dispatch(currentProjectSliceActions.removeUser({ id: user.id }));
      dispatch(appAlertsSliceActions.addAlert({
        alert: {
          id: Date.now().toString(36),
          text: 'The user deactivated successfully',
          type: 'delete',
        },
      }));
    } finally {
      setIsPending(false);
    }
  }, [dispatch, user.id]);

  return (
    <>
      <Button
        onClick={handleBtnClick}
        style={{
          color: 'inherit', height: 40, width: 40, minWidth: 40, padding: 0,
        }}
      >
        <MoreHorizIcon fontSize="medium" />
      </Button>
      <Menu
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {isCanBeReinvited && (
          <MenuItem
            sx={{ fontWeight: 500, width: 220 }}
          >
            <AutorenewIcon style={{ marginRight: 8, fontSize: 22 }} />
            Reinvite
          </MenuItem>
        )}

        {isActive && (
        <MenuItem
          sx={{ fontWeight: 500, width: 220 }}
          onClick={() => setIsEditModalOpened(true)}
        >
          <EditIcon style={{ marginRight: 8, fontSize: 16 }} />
          Edit
        </MenuItem>

        )}

        {isActive && (
          <MenuItem
            sx={{ fontWeight: 500, width: 220 }}
            onClick={handleActivityClick}
          >
            <HistoryIcon style={{
              width: 24, height: 24, marginRight: 8, fontSize: 16,
            }}
            />
            Activity log
          </MenuItem>
        )}

        {isActive && (
        <MenuItem
          sx={{ color: 'var(--color-accent-red)', fontWeight: 500, width: 220 }}
          onClick={() => setIsDeactivateModalOpened(true)}
        >
          <DeleteIcon style={{ marginRight: 8, fontSize: 16 }} />
          Deactivate
        </MenuItem>
        )}

        <GTModal
          open={isDeactivateModalOpened}
          title="Deactivate user"
          footer={(
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GTButton disabled={isPending} onClick={() => setIsDeactivateModalOpened(false)} color="secondary">Cancel</GTButton>
              <GTButton disabled={isPending} style={{ marginLeft: 8 }} color="warning" onClick={onDeactivate}>Deactivate</GTButton>
            </div>
          )}
        >
          <>
            Are you sure you want to deactivate
            {' '}
            {user.firstName}
            {' '}
            {user.lastNAme}
            ?
            {' '}
            <br />
            This user no longer will have access to the platform
            {' '}
            <br />
            and projects. Created projects will be saved.
          </>
        </GTModal>

        <SettingsUserEditModal
          user={user}
          open={isEditModalOpened}
          onSuccess={() => setIsEditModalOpened(false)}
          onCancel={() => setIsEditModalOpened(false)}
        />
      </Menu>
    </>
  );
}

export default UsersTableMenu;
