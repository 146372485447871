import React from 'react';

type CheckIconProps = React.SVGProps<SVGSVGElement>;

function CheckIcon(props: CheckIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="wght300">
        <path
          id="check_small"
          d="M6.90708 10.0774L11.7655 5.21903C11.9115 5.07301 12.0808 5 12.2732 5C12.4657 5 12.635 5.07301 12.781 5.21903C12.927 5.36504 13 5.53429 13 5.72677C13 5.91925 12.927 6.0885 12.781 6.23451L7.40487 11.6106C7.25885 11.7566 7.0896 11.8296 6.89712 11.8296C6.70465 11.8296 6.5354 11.7566 6.38938 11.6106L4.21903 9.44027C4.07301 9.29425 4 9.125 4 8.93252C4 8.74004 4.07301 8.5708 4.21903 8.42478C4.36504 8.27876 4.53429 8.20575 4.72677 8.20575C4.91925 8.20575 5.0885 8.27876 5.23451 8.42478L6.90708 10.0774Z"
          fill="#0000B0"
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
