import React from 'react';
import IconBase from './IconBase';

type GalleryIconProps = React.SVGProps<SVGSVGElement>;

function GalleryIcon(props: GalleryIconProps) {
  return (
    <IconBase {...props} height={24}>
      <path
        d="M5.00001 11C4.59104 11 4.23881 10.8523 3.94331 10.5568C3.64779 10.2612 3.50003 9.909 3.50003 9.50003V5.00001C3.50003 4.59104 3.64779 4.23881 3.94331 3.94331C4.23881 3.64779 4.59104 3.50003 5.00001 3.50003H9.50003C9.909 3.50003 10.2612 3.64779 10.5568 3.94331C10.8523 4.23881 11 4.59104 11 5.00001V9.50003C11 9.909 10.8523 10.2612 10.5568 10.5568C10.2612 10.8523 9.909 11 9.50003 11H5.00001ZM5.00001 20.5C4.59104 20.5 4.23881 20.3522 3.94331 20.0567C3.64779 19.7612 3.50003 19.409 3.50003 19V14.5C3.50003 14.091 3.64779 13.7388 3.94331 13.4433C4.23881 13.1478 4.59104 13 5.00001 13H9.50003C9.909 13 10.2612 13.1478 10.5568 13.4433C10.8523 13.7388 11 14.091 11 14.5V19C11 19.409 10.8523 19.7612 10.5568 20.0567C10.2612 20.3522 9.909 20.5 9.50003 20.5H5.00001ZM14.5 11C14.091 11 13.7388 10.8523 13.4433 10.5568C13.1478 10.2612 13 9.909 13 9.50003V5.00001C13 4.59104 13.1478 4.23881 13.4433 3.94331C13.7388 3.64779 14.091 3.50003 14.5 3.50003H19C19.409 3.50003 19.7612 3.64779 20.0567 3.94331C20.3522 4.23881 20.5 4.59104 20.5 5.00001V9.50003C20.5 9.909 20.3522 10.2612 20.0567 10.5568C19.7612 10.8523 19.409 11 19 11H14.5ZM14.5 20.5C14.091 20.5 13.7388 20.3522 13.4433 20.0567C13.1478 19.7612 13 19.409 13 19V14.5C13 14.091 13.1478 13.7388 13.4433 13.4433C13.7388 13.1478 14.091 13 14.5 13H19C19.409 13 19.7612 13.1478 20.0567 13.4433C20.3522 13.7388 20.5 14.091 20.5 14.5V19C20.5 19.409 20.3522 19.7612 20.0567 20.0567C19.7612 20.3522 19.409 20.5 19 20.5H14.5ZM5.00001 9.50003H9.50003V5.00001H5.00001V9.50003ZM14.5 9.50003H19V5.00001H14.5V9.50003ZM14.5 19H19V14.5H14.5V19ZM5.00001 19H9.50003V14.5H5.00001V19Z"
        fill="currentColor"
      />
    </IconBase>
  );
}

export default GalleryIcon;
