import React from 'react';
import IconBase from './IconBase';

type CourtIconProps = React.SVGProps<SVGSVGElement>;

function CourtIcon(props: CourtIconProps) {
  return (
    <IconBase {...props} fill="none" height={24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 21C3.44772 21 3 20.5523 3 20V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V20C21 20.5523 20.5523 21 20 21H4ZM6.375 19.7H4.3V3.3H6.375V19.7ZM7.575 16.0002L7.575 7.00019H11.4V16.0002H7.575ZM12.6 16.0002H16.425V7.00019H12.6V16.0002ZM7.575 5.80019H16.425V3.3H7.575V5.80019ZM17.625 3.3H19.7V19.7H17.625V3.3ZM7.575 17.2002H16.425V19.7H7.575V17.2002Z"
        fill="currentColor"
      />
    </IconBase>
  );
}

export default CourtIcon;
