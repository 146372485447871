import React from 'react';

type MSIconBaseProps = React.SVGProps<SVGSVGElement> & {
  height?: number
};

function IconBase({ children, height = 24, ...rest }: MSIconBaseProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${height} ${height}`}
      width={height}
      height={height}
      fill="currentColor"
      role="img"
      {...rest}
    >
      {children}
    </svg>
  );
}

export default IconBase;
