import classnames from 'classnames';
import {
  Navigate, NavLink, Outlet, useLocation,
} from 'react-router-dom';
import styles from './Settings.module.css';
import { SETTINGS_ACCOUNT_ROUTE, SETTINGS_ROUTE, SETTINGS_USERS_ROUTE } from '../../router/routesConstants';

function SettingsMain() {
  const location = useLocation();

  if (location.pathname === SETTINGS_ROUTE) {
    return <Navigate to={`${SETTINGS_ACCOUNT_ROUTE}`} replace />;
  }

  return (
    <div className={styles.container}>
      <div className={classnames(styles.box, styles.navBox)}>
        <NavLink
          to={SETTINGS_ACCOUNT_ROUTE}
          className={({ isActive }) => classnames(styles.navLink, {
            [styles.active]: isActive,
          })}
        >
          My account
        </NavLink>
        <NavLink
          to={SETTINGS_USERS_ROUTE}
          className={({ isActive }) => classnames(styles.navLink, {
            [styles.active]: isActive,
          })}
        >
          Users
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export default SettingsMain;
