import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import styles from './ProjectsLists.module.css';
import { MATCH_ROUTE } from '../../router/routesConstants';
import exampleImgUrl from './assets/example.png';
import { selectProjects } from '../../store/projects/slice';
import { ProjectDetails } from '../../store/projects/types';
import ProjectsListStatusChip from './components/ProjectsListStatusChip';
import ProjectMenu from './components/ProjectMenu';

function ProjectsListTable() {
  const projects = useSelector(selectProjects);
  // const projectsPending = useSelector(selectProjectsApiPending);

  const newProjects: ProjectDetails[] = useMemo(
    () => projects.filter(
      (item) => !!(item as ProjectDetails).player1,
    ) as ProjectDetails[],
    [projects],
  );

  return (
    <div className={styles.tableWrap}>
      <Table className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            <TableCell>Match</TableCell>
            <TableCell sx={{ width: '20%' }}>Status</TableCell>
            <TableCell sx={{ width: '12%' }} align="right">
              Match Date
            </TableCell>
            <TableCell sx={{ width: '12%' }} align="right">
              Date Created
            </TableCell>
            <TableCell sx={{ width: '12%' }} align="right">
              Last Edited
            </TableCell>
            <TableCell sx={{ width: '60px' }} />
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {newProjects.map((item) => (
            <TableRow className={styles.tableRow} key={item.projectId}>
              <TableCell>
                <Link to={`${MATCH_ROUTE}/${item.projectId}`} state={{ from: 'list' }}>
                  <div className={styles.matchTitleContent}>
                    <img src={exampleImgUrl} alt="" />
                    <div className={styles.matchTitleInfo}>
                      <h4>
                        {item.player1.firstName}
                        {' '}
                        {item.player1.lastName}
                        {' '}
                        •
                        {' '}
                        {item.player2.firstName}
                        {' '}
                        {item.player2.lastName}
                      </h4>
                      <p>{item.tournamentName}</p>
                    </div>
                  </div>
                </Link>
              </TableCell>
              <TableCell>
                <ProjectsListStatusChip
                  projectStatus={item.status}
                  processingProgress={item.processingProgress}
                />
              </TableCell>
              <TableCell style={{ maxWidth: 100 }} align="right">
                {new Date(item.tournamentDate).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </TableCell>
              <TableCell align="right">
                {new Date(item.createdAt).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </TableCell>
              <TableCell align="right">
                {item.updatedAt
                  ? new Date(item.updatedAt).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })
                  : ''}
              </TableCell>
              <TableCell align="center">
                <ProjectMenu projectId={item.projectId} project={item} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default ProjectsListTable;
