import { Input, InputLabel } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';
import { InputProps } from '@mui/material/Input/Input';
import classnames from 'classnames';
import styles from './GTInput.module.css';

type GTInputProps = InputProps & {
  label?: string
  style?: CSSProperties,
  errorMessage?: string,
};

const GTInput = forwardRef<HTMLDivElement, GTInputProps>(({
  label, className, style, errorMessage, ...props
}, ref) => (
  <div
    style={{ ...style }}
    className={classnames(styles.wrap, { [className as string]: !!className })}
  >
    {label && <InputLabel classes={{ root: styles.label }} htmlFor={props.id}>{label}</InputLabel>}
    <Input classes={{ root: styles.input }} disableUnderline {...props} ref={ref} />
    <div className={styles.bottom}>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  </div>
));

export default GTInput;
