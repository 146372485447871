import { Button, Drawer, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import {
  useCallback, useEffect, useState,
} from 'react';
import classnames from 'classnames';
import styles from './UserActivity.module.css';
import { User, UserRolesLabels } from '../../store/users/types';
import { getUserActivityHistory, UserActivityItem } from '../../api/history';
import AnotherUserAvatar from '../../components/UserAvatar/AnotherUserAvatar';

type UserActivityProps = {
  open: boolean
  user: User;
  onOpenChange: (value: boolean) => void
};

function UserActivity({ open, user, onOpenChange }: UserActivityProps) {
  const [historyEvents, setHistoryEvents] = useState<UserActivityItem[]>([]);
  const [isPending, setIsPending] = useState(false);

  const getHistory = useCallback(async () => {
    setIsPending(true);
    try {
      const result = await getUserActivityHistory(user.id);
      setHistoryEvents(result);
    } finally {
      setIsPending(false);
    }
  }, [user.id]);

  const getDateString = useCallback((date: string) => {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    const dateObj = dayjs(date);
    const dateDiff = dateObj.diff(new Date(), 'days');
    if (dateDiff === 0) {
      return dateObj.fromNow();
    }

    return `${dateObj.format('MMM D')} at ${dateObj.format('LT')}`;
  }, []);

  useEffect(() => {
    if (open) {
      getHistory();
    } else {
      setHistoryEvents([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={() => onOpenChange(false)}>
      <div className={styles.content}>
        <div className={styles.head}>
          Activity log
          <Button className={styles.closeBtn} onClick={() => onOpenChange(false)}>
            <CloseIcon />
          </Button>
        </div>
        <div className={styles.info}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
            <AnotherUserAvatar
              style={{
                height: 24, width: 24, fontSize: 10, marginRight: 8,
              }}
              avatarSrc={user.avatarPath}
              name={user.firstName ? `${user.firstName} ${user.lastNAme}` : user.email}
            />
            {user.firstName ? `${user.firstName} ${user.lastNAme}` : user.email}
            <p style={{ marginLeft: 'auto' }}>{UserRolesLabels[user.role]}</p>
          </div>
          {historyEvents[0] && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
            <HistoryIcon style={{
              width: 24, height: 24, marginRight: 8, fontSize: 16,
            }}
            />
            Last activity
            <p style={{ marginLeft: 'auto' }}>{getDateString(historyEvents[0].createdAt)}</p>
          </div>
          )}
        </div>
        <div className={classnames(styles.historyTimeline, 'customScrollbar')}>
          {isPending && <Skeleton variant="rounded" width="100%" height={400} />}
          {!isPending && historyEvents.map((item) => (
            <div className={styles.historyTimelineItem} key={item.createdAt}>
              <h5>{getDateString(item.createdAt)}</h5>
              <h5>{item.eventName}</h5>
              <div className={styles.eventProjectInfo}>
                <img width={93} height={56} style={{ borderRadius: 4 }} alt="" src={`https://d3kn021kxuyfai.cloudfront.net${item.projectPreviewURL}`} />
                <div>
                  <h4>
                    {item.playerName1}
                    {' '}
                    •
                    {' '}
                    {item.playerName2}
                  </h4>
                  <p>{item.projectSubtitle}</p>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </Drawer>
  );
}

export default UserActivity;
