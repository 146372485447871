import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { createTheme, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { store } from './store';
import Router from './router';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession } from 'aws-amplify/auth';


Amplify.configure({
  API: {
    REST: {
      gtProjects: {
        endpoint: import.meta.env.VITE_REST_API_URL,
      },
    },
  },
  Auth: {
    Cognito: {
      identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
    },
  },
  Storage: {
    S3: {
      region: import.meta.env.VITE_S3_REGION,
      bucket: import.meta.env.VITE_S3_BUCKET,
    },
  },
},
{
  API: {
    REST: {
      headers: async (_) => {
        let headers = {}
        try {
          const session = await fetchAuthSession()
          const idToken = session.tokens?.idToken?.toString()
          if (idToken) {
            headers = {
              ['x-id-token']: idToken
            }
          }
        } catch (e) {
          console.log(e)
        }
        return headers
      },
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: '\'IBM Plex Sans\', sans-serif',
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#0000B0',
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
);
