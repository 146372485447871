import classnames from 'classnames';
import { ButtonBase, CircularProgress } from '@mui/material';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import styles from './GTButton.module.css';

type GTButtonProps = ButtonBaseProps & {
  pending?: boolean
  selected?: boolean
};

function GTButton({
  children, pending, selected, className, ...props
}: GTButtonProps) {
  return (
    <ButtonBase className={classnames(styles.button, { [className as string]: !!className }, selected && styles.selectedState)} {...props}>
      {children}
      {pending && <CircularProgress size={16} style={{ marginLeft: 4 }} />}
    </ButtonBase>
  );
}

export default GTButton;
