import { Slider, SliderProps } from '@mui/material';
import classnames from 'classnames';
import styles from './GTSlider.module.css';

type GTSliderProps = SliderProps;

function GTSlider({ className, ...props }: GTSliderProps) {
  return <Slider className={classnames(styles.slider, className)} {...props} />;
}

export default GTSlider;
