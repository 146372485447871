import { createContext } from 'react';

interface MatchDetailsContextType {
  via: _via | null,
  setVia: ((instance: _via) => void) | null;
}

const MatchDetailsContext = createContext<MatchDetailsContextType>({
  via: null,
  setVia: null,
});

export default MatchDetailsContext;
