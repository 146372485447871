import { HowPointWon, ReportMatch, ReportPoint, ReportShot, ShotEventType, ShotType } from "./ReportStructure";

export class ReportService {

  static reportData?: ReportMatch

  async preloadReport(path: string) {
    if (this.reportData) return
    try {
      const response = await fetch(
        `https://d3kn021kxuyfai.cloudfront.net${path}`,
        {
          method: 'GET',
        },
      );
      this.reportData = (await response.json()) as ReportMatch;
    } catch (e) {
      console.log(`Error fetching report.`, e)
    }
  }

  get reportData() {
    return ReportService.reportData
  }


  set reportData(value) {
    ReportService.reportData = value
  }

  get isLoaded() {
    return this.reportData !== undefined
  }

  get setsCount() {
    return this?.reportData?.Sets.length ?? 0
  }

  // 0 = all sets
  getPointsInSet(setIndex: number = 0): ReportPoint[] {

    if (!this.reportData) return []

    const result: ReportPoint[] = []

    if (setIndex === 0) {
      this.reportData?.Sets.forEach((value) => {
        value.Games.forEach(game => {
          game.Points.forEach(point => {
            result.push(point)
          })
        })
      })

      return result
    }

    this.reportData.Sets[setIndex - 1].Games.forEach(game => {  
      game.Points.forEach(point => {
        result.push(point)
      })
    })

    return result
  }

  getShotsInSet(setIndex: number = 0): ReportShot[] {
    const points = this.getPointsInSet(setIndex)

    const result: ReportShot[] = []

    points.forEach(point => {
      let prevShot: ReportShot | undefined = undefined
      point.Shots.forEach(shot => {
        // let isServe = shot.Type === ShotType.Serve || shot.Type === ShotType.Fault
        let isDoubleFault = prevShot?.Type === ShotType.Fault && shot.Type === ShotType.Fault


        result.push({
          ...shot,
          _eventType: this.getShotEventType(shot, isDoubleFault),
          _point: {...point},
          _isDoubleFault: isDoubleFault,
          // _positionX: (point.StartXPos1 ?? 0),
          // _positionY: (point.StartYPos1 ?? 0) 
          _positionX: shot.XPos,
          _positionY: shot.YPos / 1.98//) : shot.YPos 
        })
        prevShot = shot
      })
    })

    return result
  }


  protected getShotEventType(shot: ReportShot, isDoubleFault: boolean = false): ShotEventType {
    if (isDoubleFault) 
      return ShotEventType.DoubleFault

    if (shot.Type === ShotType.Fault)
      return ShotEventType.Fault

    // console.log(shot.HowPointWon)

    switch (shot.HowPointWon) {
      case HowPointWon.ForecedError:
        return ShotEventType.ForceError
      case HowPointWon.ForcingShot:
        return ShotEventType.Forcing
      case HowPointWon.OpponentsUnforcedError:
        return ShotEventType.UnforceError
      case HowPointWon.Winner:
      case HowPointWon.Unreturnable:
        return ShotEventType.AceUnreturnable
    }

    return ShotEventType.Other
  }

  zoneRegionMap = {
    ["zone_1"]: {y1: -100, y2: 65.7},
    ["zone_2"]: {y1: 65.7, y2: 88.5},
    ["zone_3"]: {y1: 88.5, y2: 100.5},
    ["zone_4"]: {y1: 100.5, y2: 112.5},
    ["zone_5"]: {y1: 112.5, y2: 300},
  }

  zoneMap = {
    ["zone_C_left"]: { x1: -100, x2: 12.1 },
    ["zone_B_left"]: { x1: 12.1, x2: 31.5 },
    ["zone_A_left"]: { x1: 31.5, x2: 49.5 },
    ["zone_A_right"]: { x1: 49.5, x2: 69 },
    ["zone_B_right"]: { x1: 69, x2: 87.5 },
    ["zone_C_right"]: { x1: 87.5, x2: 200 },
  }

  /// Filters

  filterByZone(shots: ReportShot[], zone: string) {
    const range = (this.zoneMap as any)[zone]
    return shots.filter(shot => {
      return shot._positionX > range.x1 && shot._positionX < range.x2
    })
  }

  filterByZoneRegion(shots: ReportShot[], zone: string) {
    const range = (this.zoneRegionMap as any)[zone]
    return shots.filter(shot => {
      return shot._positionY > range.y1 && shot._positionY < range.y2
    })
  }

  filterByServe(shots: ReportShot[], serve: string) {
    const count = serve.startsWith('1st') ? 1 : 2
    return shots.filter(shot => {
      return (shot._point?.ServeCount ?? 1) === count
    })
  }


  filterByType(shots: ReportShot[], type: ShotType): ReportShot[] {

    if (type === ShotType.InPlay) {
      return shots.filter(shot => {
        return shot.CustomAnalytics?.Category === "In Play"
      })
    }

    if (type === ShotType.Serve) {
      return shots.filter(shot => {
        return shot.CustomAnalytics?.Category === "Serving"
      })
    }

    
    if (type === ShotType.ReturnOfServe) {
      return shots.filter(shot => {
        return shot.CustomAnalytics?.Category === "Returning"
      })
    }

    return shots
  }

  filterByShotEventType(shots: ReportShot[], shotEventType: ShotEventType): ReportShot[]  {
    return shots.filter(shot => {
      return shot._eventType === shotEventType
    })
  }

  filterCommon(shots: ReportShot[], filter: ReportFilter ): ReportShot[]  {
    return shots.filter(shot => {
      let result = true

      if (filter?.PlayerWon !== undefined) {
        result = result && shot._point?.PlayerWon === filter?.PlayerWon
      }

      if (filter.ServeSide && filter.ServeSide.length > 0)
        result = result && shot._point?.ServeSide !== undefined && shot._point?.ServeSide === filter.ServeSide

      if (filter.FinalShotSide && filter.FinalShotSide.length > 0)
        result = result && shot?.FinalShotSide === filter.FinalShotSide

      if (filter.FinalSpin && filter.FinalSpin.length > 0)
        result = result && shot?.FinalSpin === filter.FinalSpin

      if (filter.FinalMode && filter.FinalMode.length > 0)
        result = result && shot?.FinalMode === filter.FinalMode

      return result
    })
  }
}

export interface ReportFilter {
  ServeSide?: string
  PlayerWon?: boolean
  FinalShotSide?: string
  FinalSpin?: string
  ShotType?: string
  FinalMode?: string
}