/* eslint-disable react/no-unstable-nested-components */
import { forwardRef } from 'react';
import { MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectProps } from '@mui/material/Select/Select';
import classnames from 'classnames';
import styles from './GTSelect.module.css';

export type GTSelectOption<T = string, V = string> = { title: T, value: V };

type GTSelectProps = Omit<SelectProps, 'variant'> & {
  options: GTSelectOption[],
  error?: boolean,
  errorMessage?: string,
  minimal?: boolean,
};

const GTSelect = forwardRef<HTMLInputElement, GTSelectProps>(({
  label, options, placeholder, id, errorMessage, error, minimal, style, ...rest
}, ref) => (
  <div className={styles.wrap} style={style}>
    {label && <label className={styles.label} htmlFor={id}>{label}</label>}
    <div className={styles.subwrap}>
      <Select
        className={classnames(styles.select, {
          [styles.error]: !!errorMessage,
          [styles.minimal]: !!minimal,
        })}
        ref={ref}
        inputProps={{
          id,
          placeholder,
        }}
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
        displayEmpty
        renderValue={(value) => (value
          ? options.find((item) => item.value === value)?.title
          : <span className={styles.placeholder}>{placeholder}</span>)}
        {...rest}
      >
        {options.map((item) => (
          <MenuItem
            className={styles.selectOption}
            value={item.value}
            key={`${item.value}-${item.title}`}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </div>
    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
  </div>
));

export default GTSelect;
