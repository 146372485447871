import {
  FormControl, MenuItem, Select, SelectProps,
} from '@mui/material';
import { CSSProperties, forwardRef } from 'react';
import styles from './GTSelect.module.css';
import CheckIcon from '../MaterialSymbolsIcons/CheckIcon';

type GTSelectItem = {
  title: string;
  value: string;
};

type GTSelectSection = {
  title: string;
  items: GTSelectItem[];
};

type GTSelectProps = Omit<SelectProps, 'variant'> & {
  style?: CSSProperties;
  errorMessage?: string;
  sections: GTSelectSection[];
};

const isSelected = (selected: string[], value: string) => selected.includes(value);

const GTSelect = forwardRef<HTMLDivElement, GTSelectProps>(
  ({
    className, style, errorMessage, sections, ...props
  }, ref) => (
    <div
      style={{ ...style }}
      className={`${styles.wrap}${className ? ` ${className}` : ''}`}
    >
      <FormControl sx={{ width: 152 }}>
        <Select
          className={styles.select}
          multiple
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            PaperProps: {
              className: styles.selectPaper,
            },
          }}
          {...props}
          ref={ref}
        >
          {sections.map((section, index) => {
            const menuItems = section.items.map((item) => {
              const selected = isSelected(props.value as string[], item.value);
              return (
                <MenuItem
                  className={`${styles.selectItem} ${
                    selected && styles.selectItemSelected
                  }`}
                  key={item.value}
                  value={item.value}
                >
                  {item.title}
                  {selected && <CheckIcon />}
                </MenuItem>
              );
            });

            const isFirst = index === 0;

            const headItem = (
              <MenuItem className={isFirst ? styles.selectTitle : styles.selectTitleLast} disabled value="">
                <em>{section.title}</em>
              </MenuItem>
            );

            return [headItem, ...menuItems];
          })}
        </Select>
      </FormControl>
    </div>
  ),
);

export default GTSelect;
