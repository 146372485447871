import PersonIcon from '@mui/icons-material/PersonOutlined';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import styles from './MatchDetails.module.css';
import GTButton from '../../components/GTButton/GTButton';
import UndoIcon from '../../components/customIcons/UndoIcon';
import BallIcon from '../../components/customIcons/BallIcon';
import CourtIcon from '../../components/customIcons/CourtIcon';
import ScoreIcon from '../../components/customIcons/ScoreIcon';
import DeleteIcon from '../../components/MaterialSymbolsIcons/DeleteIcon';
import { extractFramesData } from '../../api/projects';
import {
  currentProjectSliceActions,
  selectSelectedFrameState,
  selectProject,
} from '../../store/currentProject/slice';

import GTModal from '../../components/GTModal/GTModal';
import MatchDetailsContext from '../MatchCreator/MatchDetailsContext';
import MatchDetailsBallFilters from './Filters/MatchDetailsBallFilters';
import AnnotationService, { AnnotationType } from '../../utils/services/AnnotationService';
import MatchDetailsPlayersFilters from './Filters/MatchDetailsPlayerFilters';
import MatchDetailsEventSetFilter from './Filters/MatchDetailsEventSetFilter';
import { useAppDispatch } from '../../store';

enum ActionType {
  Ball,
  Court,
  Player,
  Scoreboard,
}

function MatchDetailsFooter() {
  const dispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);
  // used to set key to Undo button to rerender it and apply state changes
  const [isUndoing, setIsUndoing] = useState(false);
  const context = useContext(MatchDetailsContext);
  const [isShowBallFilters, setIsShowBallFilters] = useState(false);
  const [isShowPlayerFilters, setIsShowPlayerFilters] = useState(false);

  const [isShowGameSetFilter, setIsShowGameSetFilter] = useState(false);
  const [isShowGameEventFilter, setIsShowGameEventFilter] = useState(false);

  const [isShowAddGame, setIsShowAddGame] = useState(false);
  const [isAddEventDisabled, setIsAddEventDisabled] = useState(false);
  const [isShowAddSets, setIsShowAddSets] = useState(false);
  const [isShowDeleteBtn, setIsShowDeleteBtn] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [isAddingCourt, setIsAddingCourt] = useState(false);
  const [isAddingBall, setIsAddingBall] = useState(false);
  const [isAddingPlayer, setIsAddingPlayer] = useState(false);
  const [isAddingScoreboard, setIsAddingScoreboard] = useState(false);

  const resetStateToDefault = () => {
    setIsAddingCourt(false);
    setIsAddingBall(false);
    setIsAddingPlayer(false);
    setIsAddingScoreboard(false);
  };

  useEffect(() => {
    AnnotationService.current.onAnnotationItemSelected = (item) => {
      setIsShowAddGame(false);
      setIsShowAddSets(false);

      if (!item) {
        setIsShowDeleteBtn(false);
        setIsShowBallFilters(false);
        setIsShowPlayerFilters(false);
        setIsShowGameSetFilter(false);
        setIsShowGameEventFilter(false);
        return;
      }

      setIsShowDeleteBtn(true);
      setSelectedItemId(item?.id || null);

      setIsShowBallFilters(false);
      setIsShowPlayerFilters(false);
      setIsShowGameSetFilter(false);
      setIsShowGameEventFilter(false);

      if (item.type === AnnotationType.Ball) {
        setIsShowBallFilters(true);
      } else if (item.type === AnnotationType.PlayerDown || item.type === AnnotationType.PlayerUp) {
        setIsShowPlayerFilters(true);
      } if (item.type === AnnotationType.Event) {
        if (item.metadata.timelineIndex === '1') {
          setIsShowGameSetFilter(true);
        } else {
          setIsShowGameEventFilter(true);
        }
      }
    };

    AnnotationService.current.onTimelineSelected = (id, mindex) => {
      setIsAddEventDisabled(false);

      AnnotationService.current.onAnnotationItemSelected && AnnotationService.current.onAnnotationItemSelected(undefined);

      setIsShowAddGame(false);
      setIsShowAddSets(false);
      setIsShowDeleteBtn(true);

      if (mindex !== -1) return;

      if (id === 0) { setIsShowAddGame(true); } else if (id === 1) setIsShowAddSets(true);
      else {
        setIsShowDeleteBtn(false);
      }
    };

    AnnotationService.current.onItemAddAction = () => {
      resetStateToDefault();
    };
  }, []);

  const isFiltersShown = useMemo(
    () => isShowGameSetFilter || isShowBallFilters || isShowPlayerFilters,
    [isShowGameSetFilter, isShowBallFilters, isShowPlayerFilters],
  );

  const setupConfig = useSelector(selectSelectedFrameState);

  const isAvailableForDeleting = setupConfig.hasBall || setupConfig.hasCourt || setupConfig.hasPlayers || setupConfig.hasScoreboard;

  const onDeleteAction = () => {
    AnnotationService.current.removeSelectedItem();
    resetStateToDefault();
  };

  const onAddGameEvent = useCallback(async () => {
    setIsAddEventDisabled(true);
    resetStateToDefault();
    AnnotationService.current.addGameEvent();
  }, [project, context.via]);

  const onAddSetEvent = useCallback(async () => {
    setIsAddEventDisabled(true);
    resetStateToDefault();
    AnnotationService.current.addSetEvent();
  }, [project, context.via]);

  const addAnnotation = useCallback((action: ActionType) => {
    resetStateToDefault();
    AnnotationService.current.cancelAddCourt();
    switch (action) {
      case ActionType.Ball:
        AnnotationService.current.addBall();
        setIsAddingBall(true);
        break;

      case ActionType.Court:
        setIsAddingCourt(AnnotationService.current.addCourt());
        break;

      case ActionType.Player:
        setIsAddingPlayer(AnnotationService.current.addPlayer());
        break;

      case ActionType.Scoreboard:
        AnnotationService.current.addScoreboard();
        setIsAddingScoreboard(true);
        break;

      default:
        break;
    }
  }, [project, context.via]);

  const extractFrameData = useCallback(async () => {
    resetStateToDefault();
    const videoContainer = document.getElementById(
      'file_content',
    ) as HTMLVideoElement;
    const timestamp = videoContainer?.currentTime ?? 0;

    if (!project || !context.via) return;

    const viaEditor = context.via;

    setIsLoadingFrame(true);

    const videoPath = project.sourceVideoPath.startsWith('http')
      ? project.sourceVideoPath
      : `https://d3kn021kxuyfai.cloudfront.net${project.sourceVideoPath}`;

    const result = await extractFramesData(project.projectId, videoPath, timestamp);

    if (result.vimData) {
      const vimData = JSON.parse(result.vimData);
      const keys = Object.keys(vimData);
      keys.forEach((key) => {
        const data = vimData[key];
        viaEditor.d.metadata_add(data.vid, data.z, data.xy, data.av);
      });
    }

    setIsLoadingFrame(false);
  }, [project, context.via]);

  const onDeleteAll = useCallback(async () => {
    AnnotationService.current.clearCurrentFrame();
  }, [project, context.via]);

  const onUndo = useCallback(() => {
    setIsUndoing(true);
    resetStateToDefault();
    AnnotationService.current.restorePreviousMetadata();
    dispatch(currentProjectSliceActions.undoLastUpdates());
    setTimeout(() => setIsUndoing(false), 0);
  }, [dispatch]);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerButtons}>
        {!isFiltersShown && (
        <>
          <GTButton
            key={isUndoing ? 'undoing' : null}
            disabled={!AnnotationService.current.previousMetadata}
            onClick={onUndo}
            className={styles.undoBtn}
            color="secondary"
          >
            <UndoIcon style={{ marginRight: 8 }} />
            Undo
          </GTButton>

          {isShowGameEventFilter && <div />}

          {(!isShowAddGame && !isShowAddSets && !isShowGameEventFilter) && (
          <div className={styles.footerButtonsSubwrap}>
            <GTButton color="secondary" onClick={() => { addAnnotation(ActionType.Player); }} disabled={setupConfig.hasPlayers} selected={isAddingPlayer}>
              <PersonIcon />
              <span style={{ marginLeft: 8 }} className="hideTablet">Add player</span>
            </GTButton>
            <GTButton color="secondary" onClick={() => { addAnnotation(ActionType.Ball); }} disabled={setupConfig.hasBall} selected={isAddingBall}>
              <BallIcon />
              <span style={{ marginLeft: 8 }} className="hideTablet">Add ball</span>
            </GTButton>
            <GTButton color="secondary" className="hideTablet" onClick={() => { addAnnotation(ActionType.Court); }} disabled={setupConfig.hasCourt} selected={isAddingCourt}>
              <CourtIcon />
              <span style={{ marginLeft: 8 }} className="hideTablet">Add court</span>
            </GTButton>
            <GTButton color="secondary" onClick={() => { addAnnotation(ActionType.Scoreboard); }} disabled={setupConfig.hasScoreboard} selected={isAddingScoreboard}>
              <ScoreIcon />
              <span style={{ marginLeft: 8 }} className="hideTablet">Add scoreboard</span>
            </GTButton>
          </div>
          )}
        </>
        )}
        {!isShowDeleteBtn
        && (
        <div className={styles.btnsContainer}>
          <GTButton onClick={extractFrameData} color="secondary" style={{ marginLeft: 'auto' }}>Extract frame</GTButton>
          <GTButton disabled={!isAvailableForDeleting} className={styles.deleteAllBtn} color="warning" onClick={onDeleteAll}><DeleteIcon /></GTButton>
        </div>
        )}
        {isShowBallFilters && <MatchDetailsBallFilters onLastOption={() => setIsShowBallFilters(false)} />}
        {isShowPlayerFilters && <MatchDetailsPlayersFilters key={selectedItemId} onLastOption={() => setIsShowPlayerFilters(false)} />}
        {isShowGameSetFilter && <MatchDetailsEventSetFilter onLastOption={() => {}} />}

        <div className={styles.leftButtonsContainer}>
          {isShowAddGame && <GTButton disabled={isAddEventDisabled} className={styles.addEventButton} onClick={onAddGameEvent} color="secondary" style={{ marginLeft: 'auto' }}>Add Game</GTButton>}
          {isShowAddSets && <GTButton disabled={isAddEventDisabled} className={styles.addEventButton} onClick={onAddSetEvent} color="secondary" style={{ marginLeft: 'auto' }}>Add Set</GTButton>}
          {isShowDeleteBtn && <GTButton className={styles.deleteBtn} color="warning" onClick={onDeleteAction}><DeleteIcon /></GTButton>}
        </div>
      </div>
      <GTModal open={isLoadingFrame}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center' }}>
            Extracting frame data. Please wait and don’t close the tab
          </p>
          <CircularProgress style={{ margin: '24px auto 0' }} size={40} />
        </div>
      </GTModal>
    </footer>
  );
}

export default MatchDetailsFooter;
