import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProject, getProjectAnnotations, getProjectProjection } from '../../api/projects';
import { ProjectDetails, ProjectionJson } from '../projects/types';

export const currentProjectGetDetailsThunk = createAsyncThunk<ProjectDetails, string>(
  'currentProject/getDetails',
  async (projectId) => getProject(projectId),
);

export const currentProjectGetAnnotationThunk = createAsyncThunk<Record<never, never>, string>(
  'currentProject/getAnnotations',
  async (resultDataPath) => getProjectAnnotations(resultDataPath),
);

export const currentProjectGetProjectionThunk = createAsyncThunk<ProjectionJson, string>(
  'currentProject/getProjection',
  async (courtDataPath) => getProjectProjection(courtDataPath),
);
