import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProjectsFilter,
  startProcessingTheProject, createProject, deleteProject, getProjects,
} from '../../api/projects';
import { ProjectDetails, ProjectDetailsOld } from './types';

export const projectsGetThunk = createAsyncThunk<(
ProjectDetailsOld | ProjectDetails)[], ProjectsFilter | undefined>(
  'projects/getThunk',
  async (filter) => await getProjects(filter) as (
    ProjectDetailsOld | ProjectDetails)[],
  );

export const projectsPostThunk = createAsyncThunk<{ data: { projectId: string } }, string>(
  'projects/postThunk',
  async (projectName) => createProject(projectName),
);

export const projectsSubmitForProcessingThunk = createAsyncThunk<unknown,
{ projectId: string,
  sourceVideoPath: string,
  executeProcessing: boolean }>(
  'projects/postThunk',
  async ({
    projectId,
    sourceVideoPath, executeProcessing,
  }) => startProcessingTheProject(projectId, sourceVideoPath, executeProcessing),
);

export const projectsDeleteThunk = createAsyncThunk<string, string>(
  'projects/postThunk',
  async (projectId) => {
    await deleteProject(projectId);
    return projectId;
  },
);
