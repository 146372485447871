import classnames from 'classnames';
import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import styles from './MatchCourtProjection.module.css';
import netSrc from '../MatchDetails/assets/net.png';
import useResizeObserver from '../../utils/hooks/useResizeObserver';
import MatchCourtProjectionZoomControl from './MatchCourtProjectionZoomControl';
import MatchReportCourtProjectionBallEvent from './MatchReportCourtProjectionBallEvent';
import ProjectionLayersMenu from './ProjecitonLayersMenu';
import ProjectionReportContext, { ProjectionReportContextType, ProjectionReportLayersState } from './context';
import MatchReportContext from '../MatchReport/MatchReportFilters/context';
import { ReportService } from '../../utils/report/ReportService';
import { ReportShot, ShotType } from '../../utils/report/ReportStructure';
import { MatchReportFiltersCategories } from '../MatchReport/MatchReportFilters/types';
import { toReportMappingFilterType } from '../../utils/report/ReportMapping';

const BASE_PADDING = 16;
const BASE_PROJECTION_HEIGHT = 850;

type MatchReportCourtProjectionProps = {
  report?: ReportService
  selectedSet: number
  currentPlaybackEventId?: string
  onEventSelected?: (shot: ReportShot, index: number) => void
};

const getPercentage = (n1: number, n2: number) => Math.round((n1 / n2) * 100);

const isOverlapping = (overlay: HTMLElement, ballImg: HTMLElement) => {
  // Get the bounding rectangles of both elements
  const rect1 = overlay.getBoundingClientRect();
  const rect2 = ballImg.getBoundingClientRect();

  const absCenterX = rect2.left + rect2.width / 2;
  const absCenterY = rect2.top + rect2.height / 2;

  // Check for overlap on all sides
  return (
    absCenterY >= rect1.top
    && absCenterX <= rect1.right
    && absCenterY <= rect1.bottom
    && absCenterX >= rect1.left
  );
};

function isPointInDiamond(
  pointX: number,
  pointY: number,
  topPoint: { x: number, y: number },
  leftPoint: { x: number, y: number },
  rightPoint: { x: number, y: number },
  bottomPoint: { x: number, y: number },
) {
  // 1. Check if the point is within the bounding rectangle of the diamond (efficiency)
  if (pointX < leftPoint.x || pointX > rightPoint.x || pointY < topPoint.y || pointY > bottomPoint.y) {
    return false; // Point outside the rectangle, definitely outside the diamond
  }

  // 2. Divide the diamond into two triangles (top and bottom) based on a diagonal
  const isTopTriangle = pointY <= topPoint.y + (bottomPoint.y - topPoint.y) / 2;

  // 3. Check if the point is on the right side of the appropriate diagonal
  const diagonalSlope = (bottomPoint.y - leftPoint.y) / (bottomPoint.x - leftPoint.x);

  if (isTopTriangle) {
    for (let i = diagonalSlope; i <= leftPoint.y - topPoint.y; i += diagonalSlope) {
      const slopPointX = leftPoint.x + i;
      const slopPointY = leftPoint.y - i;
      const slop2PointX = rightPoint.x - i;

      if (pointY >= slopPointY && pointX >= slopPointX && pointX <= slop2PointX) {
        return true;
      }
    }
  } else {
    for (let i = diagonalSlope; i <= bottomPoint.y - leftPoint.y; i += diagonalSlope) {
      const slopPointX = leftPoint.x + i;
      const slopPointY = leftPoint.y + i;
      const slop2PointX = rightPoint.x - i;

      if (pointY <= slopPointY && pointX >= slopPointX && pointX <= slop2PointX) {
        return true;
      }
    }
  }

  return false;
}

const isOverlappingDiamond = (overlay: HTMLElement, ballImg: HTMLElement) => {
  // Get bounding rectangles of both elements
  const overlayRect = overlay.getBoundingClientRect();
  const ballRect = ballImg.getBoundingClientRect();

  // Calculate center coordinates of the static element
  const centerX = overlayRect.left + overlayRect.width / 2;
  const centerY = overlayRect.top + overlayRect.height / 2;

  // Define diamond points
  const topPoint = { x: centerX, y: centerY - overlayRect.height / 2 };
  const leftPoint = { x: centerX - overlayRect.width / 2, y: centerY };
  const rightPoint = { x: centerX + overlayRect.width / 2, y: centerY };
  const bottomPoint = { x: centerX, y: centerY + overlayRect.height / 2 };

  const pointX = ballRect.left + ballRect.width / 2;
  const pointY = ballRect.top + ballRect.height / 2;

  return isPointInDiamond(pointX, pointY, topPoint, leftPoint, rightPoint, bottomPoint);
};

const defaultLayersState: ProjectionReportLayersState = {
  runawaysOverlay: false,
  servePlacement: false,
  serveOverlay: false,
  directions: false,
  diamondOverlay: false,
  pro: true,
  adv: false,
  int: false,
};

function MatchReportCourtProjection({
  onEventSelected, currentPlaybackEventId, report, selectedSet,
}: MatchReportCourtProjectionProps) {
  const context = useContext(MatchReportContext);
  const [zoom, setZoom] = useState(1);
  const [isShowAllBallEvents] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [courtWidth, setCourtWidth] = useState(0);
  const [layers, setLayers] = useState<ProjectionReportLayersState>(defaultLayersState);
  const [allBallImgs, setAllBallImgs] = useState<HTMLImageElement[]>([]);
  const [sideABallImgs, setSideABallImgs] = useState<HTMLImageElement[]>([]);
  const [sideBBallImgs, setSideBBallImgs] = useState<HTMLImageElement[]>([]);
  const [serveOverlayTotal, setServeOverlayTotal] = useState(0);
  const [diamondOverlayTotal, setDiamondOverlayTotal] = useState(0);
  const [runawaysOverlayData, setRunawaysOverlayData] = useState({
    c1: 0,
    b1: 0,
    a1: 0,
    a2: 0,
    b2: 0,
    c2: 0,
  });
  const [servePlacementData, setServePlacementData] = useState({
    c1: 0,
    b1: 0,
    a1: 0,
    a2: 0,
    b2: 0,
    c2: 0,
  });
  const forecourtAdRef = useRef<HTMLDivElement>(null);
  const forecourtDeuceRef = useRef<HTMLDivElement>(null);
  const backcourtRef = useRef<HTMLDivElement>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(currentPlaybackEventId);

  const selectedShotType = useMemo(() => {
    if (!context) return ShotType.Serve;
    switch (context.filtersMode) {
      case MatchReportFiltersCategories.RETURNING: return ShotType.ReturnOfServe;
      case MatchReportFiltersCategories.IN_PLAY: return ShotType.InPlay;
      default: return ShotType.Serve;
    }
  }, [context]);

  const selectedFilters = useMemo(() => {
    if (!context) return null;
    switch (context.filtersMode) {
      case MatchReportFiltersCategories.RETURNING: return context.filtersReturning;
      case MatchReportFiltersCategories.IN_PLAY: return context.filtersInPlay;
      default: return context.filtersServing;
    }
  }, [context]);

  const selectedEventId = currentPlaybackEventId ?? selectedItemId;

  const onSelectItem = useCallback((shot: ReportShot, index: number) => {
    if (onEventSelected) {
      onEventSelected(shot, index);
    }
    setSelectedItemId(shot.AppShotId);
  }, [onEventSelected]);

  // Apply filter

  const zoneSide = (selectedFilters as any).zoneSide
  const zoneRegion = (selectedFilters as any).zone
  const serveType = (selectedFilters as any).serveType

  const allShotEvents = useMemo(() => {
    let allShotEventsArr: ReportShot[] = [];
    if (report && selectedFilters) {
      console.log(selectedFilters)
      allShotEventsArr = report?.getShotsInSet(selectedSet) ?? [];
      allShotEventsArr = report?.filterByType(allShotEventsArr, selectedShotType);
      const shotKind = selectedFilters?.shotKind;
      if (shotKind !== undefined && shotKind.length > 0) {
        const map = toReportMappingFilterType[context.filtersMode] as any;
        allShotEventsArr = report.filterByShotEventType(allShotEventsArr, map[shotKind]);
      }

      if (zoneSide && zoneSide.length > 0) {
        allShotEventsArr = report.filterByZone(allShotEventsArr, zoneSide)
      }

      if (zoneRegion && zoneRegion.length > 0) {
        allShotEventsArr = report.filterByZoneRegion(allShotEventsArr, zoneRegion)
      }

      if (serveType && serveType.length > 0) {
        allShotEventsArr = report.filterByServe(allShotEventsArr, serveType)
      }

      allShotEventsArr = report.filterCommon(allShotEventsArr, {
        ServeSide: selectedFilters?.courtType?.toLowerCase(),
        FinalShotSide: (selectedFilters as any)?.hand?.toLowerCase(),
        PlayerWon: selectedFilters!.point!.length > 0 ? selectedFilters?.point === 'point_won' : undefined,
        FinalSpin: selectedFilters?.shotType?.toLowerCase()?.replace('s_', '')?.replace('rs_', ''),
        FinalMode: (selectedFilters as any)?.mode?.toLowerCase()
      })
    }
    return allShotEventsArr;
  }, [context, report, selectedSet]);

  const zoomedWidth = useMemo(() => Math.floor(containerWidth * zoom), [containerWidth, zoom]);
  const zoomedHeight = useMemo(() => Math.floor(Math.max(containerHeight, BASE_PROJECTION_HEIGHT) * zoom), [containerHeight, zoom]);

  const updateContainerWidth = useCallback((target: HTMLDivElement) => {
    setContainerWidth(target.offsetWidth);
    setContainerHeight(target.offsetHeight);
  }, []);

  const updateCourtWidth = useCallback((target: HTMLDivElement) => {
    setCourtWidth(target.offsetWidth);
  }, []);

  const containerRef = useResizeObserver<HTMLDivElement>(updateContainerWidth);
  const courtRef = useResizeObserver<HTMLDivElement>(updateCourtWidth);

  const onOverSubContainerHeightChange = useCallback(() => {
    if (!containerRef.current) return;
    if (containerRef.current.scrollHeight > containerRef.current.offsetHeight) {
      const scrollHeightDiff = containerRef.current.scrollHeight - containerRef.current.offsetHeight;
      const scrollWidthDiff = containerRef.current.scrollWidth - containerRef.current.offsetWidth;

      containerRef.current.scrollTo({
        top: scrollHeightDiff / 2,
        left: scrollWidthDiff / 2,
      });
    }
  }, [containerRef]);
  const overSubContainerRef = useResizeObserver<HTMLDivElement>(onOverSubContainerHeightChange);

  const zoomOffset = useMemo(() => {
    const addedSpace = (zoomedWidth - containerWidth) / 1.15;
    const distanceToCourt = (zoomedWidth - courtWidth) / 2;
    const minimaOffset = distanceToCourt - BASE_PADDING * 2;
    return Math.min(addedSpace + BASE_PADDING, minimaOffset);
  }, [zoomedWidth, containerWidth, courtWidth]);

  useEffect(() => {
    setLayers(defaultLayersState);
  }, [context.filtersMode]);

  const contextValue = useMemo<ProjectionReportContextType>(() => ({
    layers,
    setLayers: (value) => setLayers({ ...layers, ...value }),
  }), [layers]);

  useEffect(() => {
    const allImgs = [...document.querySelectorAll('[class^="_ball_"]')] as HTMLImageElement[];
    const sideAImgs = [...allImgs].filter((item) => {
      if (!item.parentElement || !item.parentElement.style.top) return false;
      return parseFloat(item.parentElement.style.top) < 50;
    });
    const sideBImgs = [...allImgs].filter((item) => {
      if (!item.parentElement || !item.parentElement.style.top) return false;
      return parseFloat(item.parentElement.style.top) >= 50;
    });
    setAllBallImgs(allImgs);
    setSideABallImgs(sideAImgs);
    setSideBBallImgs(sideBImgs);
  }, [context, layers]);

  const getServeOverlayData = useCallback(() => {
    const servingOverlay1 = document.getElementById('servingOverlay1');
    const servingOverlay2 = document.getElementById('servingOverlay2');
    if (!servingOverlay1 || !servingOverlay2) return;
    if (!forecourtDeuceRef.current && !forecourtDeuceRef.current) return;
    let total = 0;
    sideABallImgs.forEach((ball) => {
      if (isOverlapping((forecourtDeuceRef.current as HTMLDivElement), ball)
        || isOverlapping((forecourtAdRef.current as HTMLDivElement), ball)) {
        total += 1;
      }
      if (isOverlapping(servingOverlay1, ball)
        || isOverlapping(servingOverlay2, ball)) {
        total -= 1;
      }
    });
    setServeOverlayTotal(total);
  }, [sideABallImgs]);

  const getDiamondOverlayData = useCallback(() => {
    const diamondOverlay = document.getElementById('diamondOverlay');
    if (!diamondOverlay) return;
    let total = 0;

    sideABallImgs.forEach((item) => {
      if (isOverlappingDiamond(diamondOverlay, item)) {
        total += 1;
      }
    });

    setDiamondOverlayTotal(total);
  }, [sideABallImgs]);

  const calculateRunawayTotal = useCallback((runawayId: string, edge?: 'left' | 'right') => {
    const runaway = document.getElementById(runawayId);
    let total = 0;
    if (runaway) {
      sideBBallImgs.forEach((item) => {
        if (isOverlapping(runaway, item)
          || (edge === 'left' && parseFloat(item.parentElement?.style?.left as string) <= 0)
          || (edge === 'right' && parseFloat(item.parentElement?.style?.left as string) >= 100)
        ) {
          total += 1;
        }
      });
    }
    return total;
  }, [sideBBallImgs]);

  const calculateServeZoneTotal = useCallback((runawayId: string) => {
    const zone = document.getElementById(runawayId);
    let total = 0;
    if (zone) {
      sideABallImgs.forEach((item) => {
        if (isOverlapping(zone, item)) {
          total += 1;
        }
      });
    }
    return total;
  }, [sideABallImgs]);

  const getRunawaysOverlayData = useCallback(() => {
    const values = {
      c1: 0,
      b1: 0,
      a1: 0,
      a2: 0,
      b2: 0,
      c2: 0,
    };

    values.c1 = calculateRunawayTotal('runawaysOverlayC1', 'left');
    values.b1 = calculateRunawayTotal('runawaysOverlayB1');
    values.a1 = calculateRunawayTotal('runawaysOverlayA1');
    values.a2 = calculateRunawayTotal('runawaysOverlayA2');
    values.b2 = calculateRunawayTotal('runawaysOverlayB2');
    values.c2 = calculateRunawayTotal('runawaysOverlayC2', 'right');

    setRunawaysOverlayData(values);
  }, [calculateRunawayTotal]);

  const getServePlacementData = useCallback(() => {
    const values = {
      c1: 0,
      b1: 0,
      a1: 0,
      a2: 0,
      b2: 0,
      c2: 0,
    };

    values.c1 = calculateServeZoneTotal('servePlacementZoneC1');
    values.b1 = calculateServeZoneTotal('servePlacementZoneB2');
    values.a1 = calculateServeZoneTotal('servePlacementZoneA1');
    values.a2 = calculateServeZoneTotal('servePlacementZoneA2');
    values.b2 = calculateServeZoneTotal('servePlacementZoneB2');
    values.c2 = calculateServeZoneTotal('servePlacementZoneC2');

    setServePlacementData(values);
  }, [calculateServeZoneTotal]);

  useEffect(() => {
    if (layers.serveOverlay) {
      getServeOverlayData();
    }
    if (layers.runawaysOverlay) {
      getRunawaysOverlayData();
    }
    if (layers.servePlacement) {
      getServePlacementData();
    }
    if (layers.diamondOverlay) {
      getDiamondOverlayData();
    }
  }, [layers, allBallImgs, getServeOverlayData, getRunawaysOverlayData, getServePlacementData, getDiamondOverlayData]);

  return (
    <ProjectionReportContext.Provider value={contextValue}>
      <div className={styles.projectionOuterWrap}>
        <div className={styles.projectionControls}>
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <MatchCourtProjectionZoomControl zoom={zoom} setZoom={setZoom} />
          <div className={classnames(
            styles.overlayControl,
            { [styles.open]: isShowAllBallEvents },
          )}
          >
            <ProjectionLayersMenu />
          </div>
        </div>
        <div ref={containerRef} className={classnames(styles.container, 'hideScrollbar')}>
          <div
            ref={overSubContainerRef}
            className={styles.overSubContainer}
            style={{ width: zoomedWidth, height: zoomedHeight }}
          >
            <div className={styles.subContainer} style={{ transform: `scale(${zoom})` }}>
              <div className={styles.zones}>
                <div className={styles.zone5} />
                <div className={styles.zone4} />
                <div className={styles.zone3} />
                <div className={styles.zone2} />
                <div className={styles.zone1} />
                <div className={classnames(styles.zone1, styles.numberedZone)}>
                  <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                    <span>1</span>
                  </div>
                </div>
                <div className={classnames(styles.zone2, styles.numberedZone)}>
                  <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                    <span>2</span>
                  </div>
                </div>
                <div className={classnames(styles.zone3, styles.numberedZone)}>
                  <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                    <span>3</span>
                  </div>
                </div>
                <div className={classnames(styles.zone4, styles.numberedZone)}>
                  <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                    <span>4</span>
                  </div>
                </div>
                <div className={classnames(styles.zone5, styles.numberedZone)}>
                  <div style={{ left: zoomOffset }} className={styles.zoneInfo}>
                    <span>5</span>
                  </div>
                </div>
              </div>
              <div ref={courtRef} className={styles.court}>
                <div className={styles.courtInner}>
                  <div className={styles.courtSideA}>
                    <div className={styles.courtAlleyDeuce} />
                    <div ref={backcourtRef} className={styles.courtBackcourt} />
                    <div ref={forecourtDeuceRef} className={styles.courtForecourtDeuce}>
                      {layers.serveOverlay && (
                        <div
                          id="servingOverlay1"
                          className={
                            classnames(styles.servingOverlay, {
                              [styles.pro]: layers.pro,
                              [styles.adv]: layers.adv,
                              [styles.int]: layers.int,
                            })
                          }
                        />
                      )}
                    </div>
                    <div ref={forecourtAdRef} className={styles.courtForecourtAd}>
                      {layers.serveOverlay && (
                        <div
                          id="servingOverlay2"
                          className={
                            classnames(styles.servingOverlay, {
                              [styles.pro]: layers.pro,
                              [styles.adv]: layers.adv,
                              [styles.int]: layers.int,
                            })
                          }
                        />
                      )}
                    </div>
                    <div className={styles.courtAlleyAd} />
                    {layers.servePlacement && (
                    <div className={styles.servePlacement}>
                      <div className={styles.servePlacementLabels}>
                        <span className={styles.servePlacementLabel}>Count</span>
                        <span className={styles.servePlacementLabel}>Count %</span>
                        <span className={styles.servePlacementLabel}>Won %</span>
                      </div>
                      <div id="servePlacementZoneC1" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.c1}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.c1, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                      <div id="servePlacementZoneB1" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.b1}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.b1, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                      <div id="servePlacementZoneA1" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.a1}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.a1, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                      <div id="servePlacementZoneA2" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.a2}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.a2, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                      <div id="servePlacementZoneB2" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.b2}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.b2, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                      <div id="servePlacementZoneC2" className={styles.servePlacementZone}>
                        <span className={styles.servePlacementValue}>{servePlacementData.c2}</span>
                        <span className={styles.servePlacementValue}>
                          {getPercentage(servePlacementData.c2, sideABallImgs.length)}
                          %
                        </span>
                        <span className={styles.servePlacementValue}>1%</span>
                      </div>
                    </div>
                    )}
                    {layers.diamondOverlay && (
                    <div
                      id="diamondOverlay"
                      className={classnames(styles.diamondOverlay, {
                        [styles.pro]: layers.pro,
                        [styles.adv]: layers.adv,
                        [styles.int]: layers.int,
                      })}
                    />
                    )}
                  </div>
                  <div className={styles.courtSideB}>
                    <div className={styles.courtAlleyDeuce} />
                    <div className={styles.courtForecourtDeuce} />
                    <div className={styles.courtForecourtAd} />
                    <div className={styles.courtBackcourt} />
                    <div className={styles.courtAlleyAd} />
                    {layers.runawaysOverlay && (
                    <div className={styles.runawaysOverlay}>
                      <div id="runawaysOverlayC1" className={styles.runawaysOverlayArea}>
                        <h5>C</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.c1, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                      <div id="runawaysOverlayB1" className={styles.runawaysOverlayArea}>
                        <h5>B</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.b1, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                      <div id="runawaysOverlayA1" className={styles.runawaysOverlayArea}>
                        <h5>A</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.a1, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                      <div id="runawaysOverlayA2" className={styles.runawaysOverlayArea}>
                        <h5>A</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.a2, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                      <div id="runawaysOverlayB2" className={styles.runawaysOverlayArea}>
                        <h5>B</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.b2, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                      <div id="runawaysOverlayC2" className={styles.runawaysOverlayArea}>
                        <h5>C</h5>
                        <p>
                          {getPercentage(runawaysOverlayData.c2, sideBBallImgs.length)}
                          %
                        </p>
                      </div>
                    </div>
                    )}
                  </div>
                  {isShowAllBallEvents && allShotEvents.map((shot, index) => (
                    shot && (
                      <MatchReportCourtProjectionBallEvent
                        onClick={() => {
                          onSelectItem(shot, index);
                        }}
                        key={`ball_${index}`}
                        left={shot._positionX}
                        top={shot._positionY}
                        shot={shot}
                        selected={shot.AppShotId !== undefined && shot.AppShotId === selectedEventId}
                      />
                    )
                  ))}
                </div>
              </div>
              <img src={netSrc} alt="" className={styles.net} />
            </div>
          </div>
        </div>
        {(layers.serveOverlay || layers.diamondOverlay) && (
        <div className={styles.overlayStats}>
          {layers.serveOverlay && <h5>Serve Overlay</h5>}
          {layers.diamondOverlay && <h5>Diamond Overlay</h5>}
          {layers.serveOverlay && (
          <p>
            {getPercentage(serveOverlayTotal, allShotEvents.length)}
            % –
            {' '}
            {serveOverlayTotal}
            {' '}
            of
            {' '}
            {allShotEvents.length}
            {' '}
            outside red box and in
          </p>
          )}
          {layers.diamondOverlay && (
          <p>
            {100 - getPercentage(diamondOverlayTotal, sideABallImgs.length)}
            % –
            {' '}
            {sideABallImgs.length - diamondOverlayTotal}
            {' '}
            of
            {' '}
            {sideABallImgs.length}
            {' '}
            outside red diamond and in
          </p>
          )}
        </div>
        )}
      </div>
    </ProjectionReportContext.Provider>
  );
}

export default MatchReportCourtProjection;
