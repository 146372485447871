import React from 'react';
import IconBase from './IconBase';

type VideoIconProps = React.SVGProps<SVGSVGElement>;

function VideoIcon(props: VideoIconProps) {
  return (
    <IconBase {...props} height={40}>
      <path
        d="M17.9907 25.6087L25.2502 20.9453C25.6071 20.7187 25.7855 20.4041 25.7855 20.0014C25.7855 19.5986 25.6071 19.2829 25.2502 19.0543L17.9907 14.3909C17.6168 14.1345 17.2332 14.1139 16.84 14.3293C16.4469 14.5446 16.2503 14.8803 16.2503 15.3364V24.6632C16.2503 25.1193 16.4469 25.455 16.84 25.6703C17.2332 25.8857 17.6168 25.8651 17.9907 25.6087ZM20.0031 35.8331C17.8132 35.8331 15.7548 35.4175 13.8279 34.5864C11.901 33.7553 10.2249 32.6274 8.79949 31.2027C7.3741 29.7779 6.24567 28.1025 5.4142 26.1765C4.58273 24.2504 4.16699 22.1925 4.16699 20.0026C4.16699 17.8127 4.58255 15.7543 5.41366 13.8274C6.24477 11.9005 7.37269 10.2244 8.79741 8.79901C10.2222 7.37362 11.8976 6.24519 13.8236 5.41371C15.7496 4.58224 17.8076 4.1665 19.9975 4.1665C22.1874 4.1665 24.2458 4.58206 26.1727 5.41317C28.0996 6.24428 29.7757 7.3722 31.2011 8.79692C32.6265 10.2217 33.7549 11.8971 34.5864 13.8231C35.4178 15.7492 35.8336 17.8071 35.8336 19.997C35.8336 22.1869 35.418 24.2453 34.5869 26.1722C33.7558 28.0991 32.6279 29.7752 31.2032 31.2006C29.7784 32.626 28.103 33.7544 26.177 34.5859C24.2509 35.4174 22.193 35.8331 20.0031 35.8331ZM20.0003 33.3331C23.7225 33.3331 26.8753 32.0415 29.4586 29.4581C32.042 26.8748 33.3336 23.722 33.3336 19.9998C33.3336 16.2776 32.042 13.1248 29.4586 10.5415C26.8753 7.95813 23.7225 6.66646 20.0003 6.66646C16.2781 6.66646 13.1253 7.95813 10.542 10.5415C7.95862 13.1248 6.66695 16.2776 6.66695 19.9998C6.66695 23.722 7.95862 26.8748 10.542 29.4581C13.1253 32.0415 16.2781 33.3331 20.0003 33.3331Z"
      />
    </IconBase>
  );
}

export default VideoIcon;
