import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import classnames from 'classnames';
import styles from './MatchReport.module.css';
import GTButton from '../../components/GTButton/GTButton';
import { useCallback, useState } from 'react';

interface MatchReportFooterProps {
  onVideoToggle: () => void,
  onFiltersToggle: () => void,
  onSetSelected: (selectedSet: number) => void,
  isVideoShown: boolean,
  isFiltersShown: boolean,
  setsCount: number,
}

function MatchReportFooter({
  onFiltersToggle, onVideoToggle, onSetSelected,  isVideoShown, isFiltersShown, setsCount,
}: MatchReportFooterProps) {
  let setsList = [
    {
      label: 'All',
      value: 0
    }
  ]
  const [selectedSet, setSelectedSet] = useState(setsList[0].value)
  for (let index = 1; index <= setsCount; index++) {
    setsList.push({
      label: `Set ${index}`,
      value: index
    })
  }
  const onSelectSetCallback = useCallback((value: number) => {
    setSelectedSet(value)
    onSetSelected(value)
  }, [])

  return (
    <footer className={styles.footer}>
      <div className={styles.footerButtons}>
        <GTButton
          className={classnames(styles.reportFooterBtn, { [styles.active]: isFiltersShown })}
          onClick={onFiltersToggle}
          style={{ marginRight: 'auto' }}
          color="secondary"
        >
          <FilterAltOutlinedIcon fontSize="medium" />
        </GTButton>
        <div className={styles.footerButtonsSubwrap}>
          { setsList.length > 1 && 
          <div className={styles.filterOptionsWrap}>
            {setsList.map((option, index) => (
              <button
                type="button"
                className={classnames(styles.filterOptionBtn, {
                  [styles.active]: option.value === selectedSet,
                })}
                onClick={() => {onSelectSetCallback(option.value)}}
                key={`${index}-${String(option.value)}`}
              >
                {option.label}
              </button>
            ))}
          </div>
          }
        </div>
        <GTButton
          className={classnames(styles.reportFooterBtn, { [styles.active]: isVideoShown })}
          onClick={onVideoToggle}
          style={{ marginLeft: 'auto' }}
          color="secondary"
        >
          <VideocamOutlinedIcon fontSize="medium" />
        </GTButton>
      </div>
    </footer>
  );
}

export default MatchReportFooter;
