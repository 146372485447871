import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import App from '../layouts/App/App';
import VideoAnnotator from '../features/VideoAnnotator';
import ProtectedRoute from './ProtectedRoute';
import AuthLayout from '../layouts/Auth/AuthLayout';
import SignInForm from '../features/Auth/SignInForm';
import ForgotPassword from '../features/Auth/ForgotPassword/ForgotPassword';
import InvitationPasswordForm from '../features/Auth/InvitationPasswordForm';
import InvitationSignUp from '../features/Auth/InvitationSignUp';
import {
  AUTH_BASE_ROUTE,
  AUTH_FORGOT_ROUTE,
  AUTH_INVITE_ROUTE,
  AUTH_WELCOME_ROUTE, BASE_ROUTE,
  MATCH_ROUTE,
  PROJECT_ROUTE, SETTINGS_ACCOUNT_ROUTE, SETTINGS_ROUTE, SETTINGS_USERS_ROUTE,
} from './routesConstants';
import ProjectsList from '../features/ProjectsList/ProjectsList';
import MatchMain from '../features/MatchMain/MatchMain';
import SettingsMain from '../features/Settings/SettingsMain';
import SettingsAccount from '../features/Settings/SettingsAccount';
import SettingsUsers from '../features/Settings/SettingsUsers';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${AUTH_BASE_ROUTE}`} element={<AuthLayout />}>
          <Route path={`${AUTH_BASE_ROUTE}`} element={<SignInForm />} />
          <Route path={`${AUTH_FORGOT_ROUTE}`} element={<ForgotPassword />} />
          <Route path={`${AUTH_WELCOME_ROUTE}`} element={<InvitationPasswordForm />} />
          <Route path={`${AUTH_INVITE_ROUTE}`} element={<InvitationSignUp />} />
        </Route>
        <Route path={`${BASE_ROUTE}`} element={<ProtectedRoute />}>
          <Route path={`${BASE_ROUTE}`} element={<App />}>
            <Route path={`${BASE_ROUTE}`} element={<ProjectsList />} />
            <Route path={`${SETTINGS_ROUTE}`} element={<SettingsMain />}>
              <Route path={`${SETTINGS_ACCOUNT_ROUTE}`} element={<SettingsAccount />} />
              <Route path={`${SETTINGS_USERS_ROUTE}`} element={<SettingsUsers />} />
            </Route>
          </Route>
          <Route path={`${PROJECT_ROUTE}/:projectId`} element={<VideoAnnotator />} />
          <Route path={`${MATCH_ROUTE}/:projectId`} element={<MatchMain />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
