import { createContext } from 'react';

export interface ProjectionReportLayersState {
  runawaysOverlay: boolean,
  servePlacement: boolean,
  serveOverlay: boolean,
  directions: boolean,
  diamondOverlay: boolean,
  pro: boolean,
  adv: boolean,
  int: boolean
}

export interface ProjectionReportContextType {
  layers: ProjectionReportLayersState
  setLayers: (value: Partial<ProjectionReportLayersState>) => void
}

const ProjectionReportContext = createContext<ProjectionReportContextType>({
  layers: {
    runawaysOverlay: false,
    servePlacement: false,
    serveOverlay: false,
    directions: false,
    diamondOverlay: false,
    pro: true,
    adv: false,
    int: false,
  },
  setLayers: (value) => console.log(value),
});

export default ProjectionReportContext;
