import { useCallback, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './MatchReport.module.css';
import GTButton from '../../components/GTButton/GTButton';
import GTIconButton from '../../components/GTIconButton/GTIconButton';
import EditIcon from '../../components/MaterialSymbolsIcons/EditIcon';
import { selectProject } from '../../store/currentProject/slice';
import MatchCreator from '../MatchCreator/MatchCreator';
import { currentProjectGetDetailsThunk } from '../../store/currentProject/thunks';
import { useAppDispatch } from '../../store';
import { BASE_ROUTE } from '../../router/routesConstants';

function MatchReportHeader() {
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const project = useSelector(selectProject);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onBack = useCallback(() => {
    if (location.state?.from === 'list') {
      navigate(-1);
    } else {
      navigate(BASE_ROUTE);
    }
  }, [location.state, navigate]);

  const onEditDoneCallback = useCallback(async () => {
    if (project) {
      await dispatch(currentProjectGetDetailsThunk(project.projectId));
    }
  }, [dispatch, project]);

  return (
    <nav className={styles.header}>
      <GTButton onClick={onBack} color="secondary" style={{ marginRight: 'auto' }}>
        <KeyboardArrowLeftIcon style={{ marginRight: 8 }} />
        Back
      </GTButton>
      {project && (
      <div className={styles.headerInfo}>
        <div className={styles.headerInfoSubwrap}>
          <h4 className={styles.headerInfoPlayers}>
            {project?.player1?.firstName}
            {' '}
            {project?.player1?.lastName}
            {' '}
            •
            {' '}
            {project?.player2?.firstName}
            {' '}
            {project?.player2?.lastName}
          </h4>
          <p className={styles.headerInfoTournament}>
            {project?.tournamentName}
          </p>
        </div>
        <hr />
        <GTIconButton hasHover onClick={() => setIsCreatorOpen(true)}>
          <EditIcon />
        </GTIconButton>
        <MatchCreator open={isCreatorOpen} onOpenChange={setIsCreatorOpen} project={project} onEditDoneCallback={onEditDoneCallback} />
      </div>
      )}
      <GTButton disabled style={{ marginLeft: 'auto' }} color="primary">Export</GTButton>
    </nav>
  );
}

export default MatchReportHeader;
