import { MatchReportFiltersCategories, MatchReportFiltersShotKind } from "../../features/MatchReport/MatchReportFilters/types"
import { ShotEventType } from "./ReportStructure"

const toReportMappingServeFilter = {
  [MatchReportFiltersShotKind.UNRETURNABLE]: ShotEventType.AceUnreturnable,
  [MatchReportFiltersShotKind.FORCING]: ShotEventType.Forcing,
  [MatchReportFiltersShotKind.FORCED_ERROR]: ShotEventType.Fault,
  [MatchReportFiltersShotKind.UNFORCED_ERROR]: ShotEventType.DoubleFault,
  [MatchReportFiltersShotKind.OTHER]: ShotEventType.Other,
}

const toReportMappingReturnungFilter = {
  [MatchReportFiltersShotKind.UNRETURNABLE]: ShotEventType.AceUnreturnable,
  [MatchReportFiltersShotKind.FORCING]: ShotEventType.Forcing,
  [MatchReportFiltersShotKind.FORCED_ERROR]: ShotEventType.ForceError,
  [MatchReportFiltersShotKind.UNFORCED_ERROR]: ShotEventType.UnforceError,
  [MatchReportFiltersShotKind.OTHER]: ShotEventType.Other,
}

const toReportMappingInPlayFilter = {
  [MatchReportFiltersShotKind.UNRETURNABLE]: ShotEventType.AceUnreturnable,
  [MatchReportFiltersShotKind.FORCING]: ShotEventType.Forcing,
  [MatchReportFiltersShotKind.FORCED_ERROR]: ShotEventType.ForceError,
  [MatchReportFiltersShotKind.UNFORCED_ERROR]: ShotEventType.UnforceError,
  [MatchReportFiltersShotKind.OTHER]: ShotEventType.Other,
}

export const toReportMappingFilterType = {
  [MatchReportFiltersCategories.SERVING]: toReportMappingServeFilter,
  [MatchReportFiltersCategories.RETURNING]: toReportMappingReturnungFilter,
  [MatchReportFiltersCategories.IN_PLAY]: toReportMappingInPlayFilter,
}