export enum MatchReportFiltersCategories {
  SERVING,
  RETURNING,
  IN_PLAY,
}

export enum MatchReportFiltersShotKind {
  UNRETURNABLE = 'unreturnable',
  FORCING = 'forcing',
  FORCED_ERROR = 'forced_error',
  UNFORCED_ERROR = 'unforced_error',
  OTHER = 'other',
}
export interface MatchReportServingFiltersFormFields {
  shotKind?: string,
  serveType?: string
  courtType?: string,
  shotType?: string,
  gamePoint?: string,
  winner?: string,
  score?: string,
  point?: string,
}

export interface MatchReportServingReturningFormFields {
  shotKind?: string,
  serveType?: string
  courtType?: string,
  hand?: string,
  situation?: string,
  shotType?: string,
  mode?: string,
  zone?: string,
  zoneSide?: string,
  gamePoint?: string,
  line?: string,
  winner?: string,
  score?: string,
  point?: string,
}

export interface MatchReportServingInPlayFormFields {
  shotKind?: string,
  serveType?: string
  courtType?: string,
  hand?: string,
  shotType?: string,
  mode?: string,
  zone?: string,
  zoneSide?: string,
  gamePoint?: string,
  line?: string,
  winner?: string,
  score?: string,
  point?: string,
}
