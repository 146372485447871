import { useFormContext } from 'react-hook-form';
import GTRadioSelect, { GTRadioSelectOption } from '../../components/form/GTRadioSelect/GTRadioSelect';
import { MatchFormInputs } from './types';
import GTInput from '../../components/form/GTInput/GTInput';
import {
  MatchPlayerAgeGroup,
  MatchPlayerDominantHand,
  MatchPlayerGender,
  MatchPlayerSkillLevel,
} from '../../store/projects/types';

const genderOptions: GTRadioSelectOption[] = [
  { title: 'Male', value: MatchPlayerGender.MALE },
  { title: 'Female', value: MatchPlayerGender.FEMALE },
];

const dominantHandOptions: GTRadioSelectOption[] = [
  { title: 'Left', value: MatchPlayerDominantHand.LEFT },
  { title: 'Right', value: MatchPlayerDominantHand.RIGHT },
];

const ageGroupOptions: GTRadioSelectOption[] = [
  { title: '3-7', value: MatchPlayerAgeGroup.GROUP_3_7 },
  { title: '8-13', value: MatchPlayerAgeGroup.GROUP_8_13 },
  { title: '14-19', value: MatchPlayerAgeGroup.GROUP_14_19 },
  { title: '20-35', value: MatchPlayerAgeGroup.GROUP_20_35 },
  { title: '36-55', value: MatchPlayerAgeGroup.GROUP_36_55 },
  { title: '56+', value: MatchPlayerAgeGroup.GROUP_55Plus },
];

const skillLevelOptions: GTRadioSelectOption[] = [
  { title: 'Beginner', value: MatchPlayerSkillLevel.BEGINNER },
  { title: 'Intermediate', value: MatchPlayerSkillLevel.INTERMEDIATE },
  { title: 'Advanced', value: MatchPlayerSkillLevel.ADVANCED },
  { title: 'Elite', value: MatchPlayerSkillLevel.ELITE },
  { title: 'Pro', value: MatchPlayerSkillLevel.PRO },
];

function MatchCreatorStepPlayers() {
  const { register, formState: { errors } } = useFormContext<MatchFormInputs>();

  return (
    <>
      <h2>Player 1</h2>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <GTInput
          id="player1FirstName"
          label="First Name"
          placeholder="Enter first name"
          error={!!errors.player1FirstName}
          errorMessage={errors.player1FirstName?.message}
          {...register('player1FirstName', {
            required: {
              value: true,
              message: 'Required',
            },
          })}
        />
        <GTInput
          id="player1LastName"
          label="Last Name"
          placeholder="Enter last name"
          error={!!errors.player1LastName}
          errorMessage={errors.player1LastName?.message}
          {...register('player1LastName', {
            required: {
              value: true,
              message: 'Required',
            },
          })}
        />
      </div>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <GTRadioSelect registerProps={register('player1Gender')} options={genderOptions} label="Gender" />
        <GTRadioSelect registerProps={register('player1DominantHand')} options={dominantHandOptions} label="Dominant Hand" />
      </div>
      <GTRadioSelect registerProps={register('player1AgeGroup')} options={ageGroupOptions} label="Age Group" />
      <GTRadioSelect registerProps={register('player1SkillLevel')} options={skillLevelOptions} label="Skill Level" />

      <h2>Player 2</h2>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <GTInput
          id="player2FirstName"
          label="First Name"
          placeholder="Enter first name"
          error={!!errors.player2FirstName}
          errorMessage={errors.player2FirstName?.message}
          {...register('player2FirstName', {
            required: {
              value: true,
              message: 'Required',
            },
          })}
        />
        <GTInput
          id="player2LastName"
          label="Last Name"
          placeholder="Enter last name"
          error={!!errors.player2LastName}
          errorMessage={errors.player2LastName?.message}
          {...register('player2LastName', {
            required: {
              value: true,
              message: 'Required',
            },
          })}
        />
      </div>
      <div style={{
        display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 16,
      }}
      >
        <GTRadioSelect registerProps={register('player2Gender')} options={genderOptions} label="Gender" />
        <GTRadioSelect
          registerProps={register('player2DominantHand')}
          options={dominantHandOptions}
          label="Dominant Hand"
        />
      </div>
      <GTRadioSelect registerProps={register('player2AgeGroup')} options={ageGroupOptions} label="Age Group" />
      <GTRadioSelect registerProps={register('player2SkillLevel')} options={skillLevelOptions} label="Skill Level" />
    </>
  );
}

export default MatchCreatorStepPlayers;
