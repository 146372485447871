import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectCurrentUser } from '../../store/currentUser/slice';
import styles from './UserAvatar.module.css';

type UserAvatarProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  avatarSrc?: string
};

function UserAvatar({ avatarSrc, className, ...props }: UserAvatarProps) {
  const user = useSelector(selectCurrentUser);
  const avatarPath = avatarSrc || (user?.avatarPath ? `https://d3kn021kxuyfai.cloudfront.net${user.avatarPath}` : '');

  const userInitials = useMemo(() => {
    if (!user) return '';

    return `${user.firstName?.charAt(0) || user.email?.charAt(0) || ''}${user.lastNAme?.charAt(0) || ''}`;
  }, [user]);

  if (!user) return null;

  return (
    <div className={classnames(styles.avatar, className)} {...props}>
      {avatarPath ? <img src={avatarPath} alt="" /> : userInitials}
    </div>
  );
}

export default UserAvatar;
