import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersGetThunk } from './thunks';
import { User } from './types';

const initialState: { users: User[], pending: boolean } = {
  users: [],
  pending: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    updateUser: (state, action: PayloadAction<{ data: Partial<User>, id: string }>) => {
      const indexToUpdate = state.users.findIndex((item) => item.id === action.payload.id);
      if (indexToUpdate === -1) {
        return state;
      }

      let userToUpdate = { ...state.users[indexToUpdate] };
      userToUpdate = { ...userToUpdate, ...action.payload.data };

      const newUsers = [...state.users];
      newUsers[indexToUpdate] = userToUpdate;

      return { ...state, users: newUsers };
    },
    removeUser: (state, action: PayloadAction<{ id: string }>) => (
      { ...state, users: state.users.filter((item) => item.id !== action.payload.id) }),
  },
  extraReducers: (builder) => {
    builder.addCase(usersGetThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(usersGetThunk.rejected, (state) => ({
      ...state,
      pending: false,
    }));
    builder.addCase(usersGetThunk.fulfilled, (state, payload) => ({
      ...state,
      pending: false,
      users: payload.payload,
    }));
  },
  selectors: {
    selectUsers: (state) => state.users,
    selectUsersApiPending: (state) => state.pending,
  },
});

export const { selectUsers, selectUsersApiPending } = usersSlice.selectors;

export const currentProjectSliceActions = usersSlice.actions;

export default usersSlice.reducer;
