import styles from './MatchCourtProjection.module.css';
import playerSrc from '../MatchDetails/assets/player_rectangle.svg';
import playerTopSrc from '../MatchDetails/assets/player_top.svg';

type MatchCourtProjectionPlayerProps = {
  left: number,
  top: number
  isTopPlayer?: boolean,
  onClick?: () => void
};

function MatchCourtProjectionPlayer({
  left, top, isTopPlayer, onClick,
}: MatchCourtProjectionPlayerProps) {
  return (
    <img
      onClick={onClick}
      src={isTopPlayer === true ? playerTopSrc : playerSrc}
      alt="player"
      className={styles.player}
      style={{ left: `${left}%`, top: `${top}%` }}
    />
  );
}

export default MatchCourtProjectionPlayer;
