import { UseFormRegisterReturn } from 'react-hook-form';
import classnames from 'classnames';
import styles from './GTRadioSelect.module.css';

export type GTRadioSelectOption = {
  title: string,
  value: string,
};

type GTRadioSelectProps = {
  label?: string
  options: GTRadioSelectOption[],
  registerProps: UseFormRegisterReturn,
  condense?: boolean
};

function GTRadioSelect({
  label, options, registerProps, condense,
}: GTRadioSelectProps) {
  return (
    <fieldset className={styles.wrap}>
      {label && <legend className={styles.legend}>{label}</legend>}
      <div className={classnames(styles.options, {
        [styles.condense]: condense,
      })}
      >
        {options.map((item) => (
          <label htmlFor={`${registerProps.name}-${item.value}`} key={`${registerProps.name}-${item.value}`} className={styles.radioLabel}>
            <input id={`${registerProps.name}-${item.value}`} type="radio" value={item.value} {...registerProps} />
            <div>{item.title}</div>
          </label>
        ))}
      </div>
    </fieldset>
  );
}

export default GTRadioSelect;
