import { createSlice } from '@reduxjs/toolkit';
import { ProjectDetails, ProjectDetailsOld } from './types';
import { projectsGetThunk } from './thunks';

const initialState: { projects: (ProjectDetailsOld | ProjectDetails)[], pending: boolean, } = {
  projects: [],
  pending: false,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(projectsGetThunk.pending, (state) => ({
      ...state,
      pending: true,
    }));
    builder.addCase(projectsGetThunk.fulfilled, (state, { payload }) => ({
      ...state,
      pending: false,
      projects: [
        ...payload,
      ],
    }));
  },
  selectors: {
    selectProjects: (state) => state.projects,
    selectProjectById: (state, projectId: string): ProjectDetailsOld | ProjectDetails | null => state.projects.find(
      (project) => project.projectId === projectId,
    ) || null,
    selectProjectsApiPending: (state) => state.pending,
  },
});

export const {
  selectProjects,
  selectProjectById,
  selectProjectsApiPending,
} = projectsSlice.selectors;

export default projectsSlice.reducer;
